import { Component, OnInit } from '@angular/core';
import { TimerService } from 'src/shared-services/timer.service';
import { SharedService } from 'src/shared-services/shared.service';
import { TrackService } from 'src/shared-services/track.service';

@Component({
  selector: 'app-ccss-operation',
  templateUrl: './ccss-operation.component.html',
  styleUrls: ['./ccss-operation.component.scss']
})

export class CcssOperationComponent implements OnInit {
  showGarage = true;
  showTrackList = 'garage';
  showLoader = true;
  constructor(
    private sharedService: SharedService,
    private trackService: TrackService
  ) { }

  ngOnInit() {
    this.trackService.canShowTrackList()
      .subscribe(res => {
        this.showTrackList = res;
        this.showGarage = !res;
        /* istanbul ignore else */
        if (this.showGarage) {
          this.trackService.loadTrackList();
        }
      });
    this.sharedService.canShowLoader()
      .subscribe(res => {
        this.showLoader = res;
      });
  }
}
