import { Injectable } from '@angular/core';
import { CognitoCallback, CognitoService, LoggedInCallback } from './cognito.service';
import { AuthenticationDetails, CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk/global';
import * as STS from 'aws-sdk/clients/sts';
import { environment } from '../../environments/environment';
import { CommsService } from 'src/shared-services/comms.service';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  constructor(
    public cognitoUtil: CognitoService,
    private commsService: CommsService
  ) { }

  private onLoginSuccess = (callback: CognitoCallback, session: CognitoUserSession) => {

    AWS.config.credentials = this.cognitoUtil.buildCognitoCreds(session.getIdToken().getJwtToken());

    // So, when CognitoIdentity authenticates a user, it doesn't actually hand us the IdentityID,
    // used by many of our other handlers. This is handled by some sly underhanded calls to AWS Cognito
    // API's by the SDK itself, automatically when the first AWS SDK request is made that requires our
    // security credentials. The identity is then injected directly into the credentials object.
    // If the first SDK call we make wants to use our IdentityID, we have a
    // chicken and egg problem on our hands. We resolve this problem by "priming" the AWS SDK by calling a
    // very innocuous API call that forces this behavior.
    const clientParams: any = {};
    if (environment.sts_endpoint) {
      clientParams.endpoint = environment.sts_endpoint;
    }
    const sts = new STS(clientParams);
    sts.getCallerIdentity((err, data) => {
      callback.cognitoCallback(null, session);
    });
  }

  private onLoginError = (callback: CognitoCallback, err) => {
    callback.cognitoCallback(err.message, null);
  }

  authenticate(username: string, password: string, callback: CognitoCallback) {
    const authenticationData = {
      Username: username,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userData = {
      Username: username,
      Pool: this.cognitoUtil.getUserPool()
    };
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      newPasswordRequired: (userAttributes, requiredAttributes) => callback.cognitoCallback(`User needs to set password.`, null),
      onSuccess: (result: any) => {
        localStorage.setItem('token', result.accessToken.jwtToken);
        this.onLoginSuccess(callback, result);
      },
      onFailure: err => this.onLoginError(callback, err),
      mfaRequired: (challengeName, challengeParameters) => {
        callback.handleMFAStep(challengeName, challengeParameters, (confirmationCode: string) => {
          cognitoUser.sendMFACode(confirmationCode, {
            onSuccess: result => this.onLoginSuccess(callback, result),
            onFailure: err => this.onLoginError(callback, err)
          });
        });
      }
    });
  }

  authenticateSupervisor(username: string, password: string, callback: CognitoCallback) {
    const authenticationData = { Username: username, Password: password };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userData = { Username: username, Pool: this.cognitoUtil.getUserPool() };
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      newPasswordRequired: (userAttributes, requiredAttributes) => callback.cognitoCallback(`User needs to set password.`, null),
      onSuccess: (result: any) => {
        this.onLoginSuccess(callback, result);
      },
      onFailure: err => this.onLoginError(callback, err),
      mfaRequired: (challengeName, challengeParameters) => {
        callback.handleMFAStep(challengeName, challengeParameters, (confirmationCode: string) => {
          cognitoUser.sendMFACode(confirmationCode, {
            onSuccess: result => this.onLoginSuccess(callback, result),
            onFailure: err => this.onLoginError(callback, err)
          });
        });
      }
    });
  }

  forgotPassword(username: string, callback: CognitoCallback) {
    const userData = {
      Username: username,
      Pool: this.cognitoUtil.getUserPool()
    };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.forgotPassword({
      onSuccess: () => { },
      onFailure: (err) => {
        callback.cognitoCallback(err.message, null);
      },
      inputVerificationCode() {
        callback.cognitoCallback(null, null);
      }
    });
  }

  confirmNewPassword(email: string, verificationCode: string, password: string, callback: CognitoCallback) {
    const userData = {
      Username: email,
      Pool: this.cognitoUtil.getUserPool()
    };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmPassword(verificationCode, password, {
      onSuccess: () => {
        callback.cognitoCallback(null, null);
      },
      onFailure: (err) => {
        callback.cognitoCallback(err.message, null);
      }
    });
  }

  newPassword(email: string, password: string, newPassword: string, callback: CognitoCallback): void {
    // Get these details and call
    // cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, this);
    const authenticationData = {
      Username: email,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userData = {
      Username: email,
      Pool: this.cognitoUtil.getUserPool()
    };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        // User was signed up by an admin and must provide new
        // password and required attributes, if any, to complete
        // authentication.

        // the api doesn't accept this field back
        delete userAttributes.email_verified;
        cognitoUser.completeNewPasswordChallenge(newPassword, requiredAttributes, {
          onSuccess: (result) => {
            callback.cognitoCallback(null, userAttributes);
          },
          onFailure: (err) => {
            callback.cognitoCallback(err, null);
          }
        });
      },
      onSuccess: (result) => {
        callback.cognitoCallback(null, result);
      },
      onFailure: (err) => {
        callback.cognitoCallback(err, null);
      }
    });
  }

  changePassword(oldPassword: string, newPassword: string, email: string, callback: CognitoCallback): void {
    const loggedInUser = (localStorage.getItem('loggedInUserDetails') && JSON.parse(localStorage.getItem('loggedInUserDetails')));
    const userData = {
      Username: loggedInUser['cognito:username'],
      Pool: this.cognitoUtil.getUserPool()
    };
    const data = {
      PreviousPassword: oldPassword,
      ProposedPassword: newPassword
    };
    const cognitoUser = this.cognitoUtil.getCurrentUser();

    cognitoUser.getSession((err, session) => {
      if (session.isValid()) {
        cognitoUser.changePassword(oldPassword, newPassword,
          (error: any, success: any) => {
            callback.cognitoCallback(error.message || err, success);
          });
      }
    }
    );
  }

  logout() {
    if (this.cognitoUtil.getCurrentUser()) {
      this.cognitoUtil.getCurrentUser().signOut();
    }
    localStorage.clear();
    this.commsService.sendLogoutMessage();
  }

  isAuthenticated(callback: LoggedInCallback) {
    if (callback === null) {
      throw new Error(('UserLoginService: Callback in isAuthenticated() cannot be null'));
    }

    const cognitoUser = this.cognitoUtil.getCurrentUser();

    if (cognitoUser !== null) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          callback.isLoggedIn(err, false);
        } else {
          callback.isLoggedIn(err, session.isValid());
        }
      });
    } else {
      callback.isLoggedIn('Can\'t retrieve the CurrentUser', false);
    }
  }
}
