import { SharedService } from './shared.service';
import { HeaderService } from './header.service';
import { RestService } from './rest.service';
import {
  PE_API_CHARGER_ACTION,
  PE_IMG_LOADING,
  TOAST_SUCCESS,
  TOAST_ERROR,
  TOAST_NOTIFICATION_TRIGGER_SUCCESS,
  CHARGER_ACTION_REQUEST_ID,
  CHARGER_LEGEND_STATUS,
  CHARGER_ACTION
} from './../app-constants/app-constants';
import { Subject, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})

export class ChargerService {
  private chargerAction$ = new Subject<any>();
  public changeChargerIcon$ = new Subject<string>();
  public customerAssets: any;
  private chargerResponseHandler$ = new Subject<{ message: string, type: string }>();
  constructor(
    private sharedService: SharedService,
    private headerService: HeaderService,
    private restService: RestService
  ) { }

  getChargerFromShadowOrAssets(ocppId, key, value) {
    if (value) {
      return value;
    } else {
      return this.getChargerInfoFromAssets(ocppId, key);
    }
  }

  doChargerAction(strAction: string, ocppIdVal: string, chargerPositionIdVal: string, powerVal: string, chargerShadowOBj) {
    const garageId = this.headerService.getSelectedGarageId();
    const tenantId = this.headerService.getTenantId();
    if (_.isEmpty(garageId) || _.isEmpty(tenantId)) { return; }
    const reqObj = {
      url: PE_API_CHARGER_ACTION.post(garageId, tenantId),
      body: {
        id: this.sharedService.create_UUID(CHARGER_ACTION_REQUEST_ID),
        chargers: [{
          action: strAction,
          ocppId: ocppIdVal,
          power: powerVal,
          chargerIP: this.getChargerFromShadowOrAssets(ocppIdVal, 'ocppLocalIPV4', chargerShadowOBj.ocppLocalIPV4),
          chargerType: this.getChargerFromShadowOrAssets(ocppIdVal, 'chargerType', chargerShadowOBj.chargerType),
          trackId: garageId,
          chargerVendor: this.getChargerFromShadowOrAssets(ocppIdVal, 'chargerVendor', chargerShadowOBj.chargerVendor),
          chargerPositionId: chargerPositionIdVal,
          transactionId: this.getChargerFromShadowOrAssets(ocppIdVal, 'ocppTransactionId', chargerShadowOBj.ocppTransactionId)
        }]
      },
      options: {}
    };
    this.restService.post(reqObj)
      .subscribe((res: any) => {
        this.chargerAction$.next(res);
        if (!res.errorList) {
          this.chargerResponseHandler$.next(Object.assign({},
            {
              data: {
                type: TOAST_SUCCESS,
                message: res.message
              }
            }).data);
        } else {
          this.chargerResponseHandler$.next(Object.assign({},
            {
              data: {
                type: TOAST_ERROR,
                message: res.errorList[0].message
              }
            }).data);
        }
        
      });
    return this.chargerAction$.asObservable();
  }

  isShopCharger(key) {
    let returnValue = false;
    const assets = localStorage.getItem('assets');
    const assetsData = assets ? JSON.parse(assets) : [];
    let shopCharger = [];
    shopCharger = assetsData.filter(assetObj => {
      if (assetObj.assetId === key && assetObj.assetType === 'CHARGER' && assetObj.specificChargerLocation === 'Shop') {
        returnValue = true;
      }
    });
    return returnValue;
  }

  isChargerChargingInTrackSetup(ocppid) {
    let returnValue = false;
    const GARAGEALLCELLS = localStorage.getItem('garageAllCels') ? JSON.parse(localStorage.getItem('garageAllCels')) : [];
    const CHARGEROBJ = GARAGEALLCELLS.filter(item => item.ocppId === ocppid);
    if (CHARGEROBJ && CHARGEROBJ.length > 0) {
      if (CHARGEROBJ[0].chargerStatus !== null &&
        CHARGEROBJ[0].chargerStatus.toLowerCase() === CHARGER_LEGEND_STATUS.charging.toLowerCase()) {
        returnValue = true;
      }
    }
    return returnValue;
  }

  cancelAllChargers(chargersCol) {
    if (chargersCol) {
      const garageId = this.headerService.getSelectedGarageId();
      const tenantId = this.headerService.getTenantId();
      if (_.isEmpty(garageId) || _.isEmpty(tenantId)) { return; }
      const chargerCancelPayload = [];
      Object.values(chargersCol).forEach((element: any, index) => {
        if (!this.isShopCharger(Object.keys(chargersCol)[index])) {
          chargerCancelPayload.push({
            action: CHARGER_ACTION.softReset,
            ocppId: element.chargerName,
            power: null,
            chargerIP: this.getChargerFromShadowOrAssets(element.chargerName, 'ocppLocalIPV4', element.ocppLocalIPV4),
            chargerType: this.getChargerFromShadowOrAssets(element.chargerName, 'chargerType', element.chargerType),
            trackId: garageId,
            chargerVendor: this.getChargerFromShadowOrAssets(element.chargerName, 'chargerVendor', element.chargerVendor),
            transactionId: this.getChargerFromShadowOrAssets(element.chargerName, 'ocppTransactionId', element.ocppTransactionId),
            chargerPositionId: this.getChargerPositionIDFromTrackSetup(element.chargerName)
          });
        }
      });
      const reqObj = {
        url: PE_API_CHARGER_ACTION.post(garageId, tenantId),
        body: {
          id: this.sharedService.create_UUID(CHARGER_ACTION_REQUEST_ID),
          chargers: chargerCancelPayload
        },
        options: {}
      };
      this.restService.post(reqObj)
        .subscribe((res: any) => {
          this.chargerAction$.next(res);
          this.chargerResponseHandler$.next(Object.assign({},
            {
              data: {
                type: TOAST_SUCCESS,
                message: res.message
              }
            }).data);
        });
      return this.chargerAction$.asObservable();
    }
  }

  getChargerResponseTypeHandler() {
    return this.chargerResponseHandler$.asObservable();
  }

  changeChargerIcon() {
    return this.changeChargerIcon$.asObservable();
  }

  getChargerPositionIDFromTrackSetup(ocppId) {
    let returnValue = '';
    const GARAGECELLS = localStorage.getItem('garageAllCels') ? JSON.parse(localStorage.getItem('garageAllCels')) : [];
    if (GARAGECELLS) {
      for (const charger of GARAGECELLS) {
        if (charger.ocppId === ocppId) {
          returnValue = charger.chargerPositionId;
          break;
        }
      }
    }
    return returnValue;
  }

  getChargerInfoFromAssets(ocppId, key) {
    this.customerAssets = localStorage.getItem('assets') ? JSON.parse(localStorage.getItem('assets')) : [];
    let returnValue = '';
    if (this.customerAssets && this.customerAssets.length > 0) {
      const CHARGERINFO = this.customerAssets.filter(bus => bus.ocppId === ocppId);
      if (CHARGERINFO && CHARGERINFO.length !== 0) {
        if (key.toLowerCase() === 'chargermaxpowerlimit') {
          if (CHARGERINFO[0].chargerSpec) {
            returnValue = (CHARGERINFO[0].chargerSpec[key] !== null
              && CHARGERINFO[0].chargerSpec[key] !== undefined) ? CHARGERINFO[0].chargerSpec[key] : '';
          }
        } else {
          returnValue = (CHARGERINFO[0][key] !== null && CHARGERINFO[0][key] !== undefined) ? CHARGERINFO[0][key] : '';
        }
      }
    }
    return returnValue;
  }
}
