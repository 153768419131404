import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IncomingQueueComponent } from './incoming-queue.component';
import { AppMaterialModule } from '../../app/material-module';
import { MatInputModule } from '@angular/material/input';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BusIdPipe } from 'src/shared-pipes/bus-id.pipe';
import { BusService } from 'src/shared-services/bus.service';



@NgModule({
  declarations: [
    IncomingQueueComponent,
    BusIdPipe
  ],
  imports: [
    AppMaterialModule,
    MatInputModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [BusService],
  exports: [
    IncomingQueueComponent,
    CommonModule
  ]
})

export class AppIncomingQueueModule { }
