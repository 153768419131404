import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { RUN_TRACK_ASSIGNMENT_TITLE } from 'src/app-constants/app-constants';
import { TrackService } from 'src/shared-services/track.service';
import { HeaderService } from 'src/shared-services/header.service';
import { CognitoService } from 'src/app/auth/cognito.service';
import { BusService } from 'src/shared-services/bus.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})

export class AppLayoutComponent implements OnInit, OnDestroy {
  showGarage = true;
  showTrackList = false;
  showRunList = false;

  private canShowTrackListSub: Subscription;
  private loadTrackListSub: Subscription;

  constructor(
    private trackService: TrackService,
    private headerService: HeaderService,
    public cognitoService: CognitoService,
    private busService: BusService,
  ) { }

  ngOnInit() {
    this.cognitoService.getIdTokenByRefreshToken();
    if (this.busService.customerAssets) {
      this.trackService.loadTrackList();
    }

    this.canShowTrackListSub = this.trackService.canShowTrackList()
      .subscribe(res => {
        if (res) {
          res = res.toLowerCase();
          this.showTrackList = (res === 'track');
          this.showRunList = (res === 'run');
          this.showGarage = (res === 'garage');
          if (res === 'garage') {
            this.trackService.showTrackListData();
          }
        }
      });
    this.headerService.setTitle(RUN_TRACK_ASSIGNMENT_TITLE);
    // this.loadTrackListSub = this.busService.getAssetsBusList().subscribe(res => {
    //   this.trackService.loadTrackList();
    // });
  }

  ngOnDestroy() {
    this.canShowTrackListSub.unsubscribe();
    //this.loadTrackListSub.unsubscribe();
  }
}
