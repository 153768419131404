import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BusService } from 'src/shared-services/bus.service';
import { RunService } from 'src/shared-services/run.service';
import { TimerService } from 'src/shared-services/timer.service';
import { TrackService } from 'src/shared-services/track.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  TOAST_CONFIG_PAYLOAD,
  IToastMessage,
  TOASTMESSAGE_TYPE,
  NOT_APPLICABLE,
  RECOMMEND_RUN_TRACK_ACTION,
  ENABLE,
  TOAST_LOAD_RECOMMENDED_LIST_ERROR
} from '../../app-constants/app-constants';
import { CcssDisplayCommonService } from 'src/shared-services/ccss-display-common.service';
import { ToastAlertComponent } from 'src/app/toast-alert/toast-alert.component';
import { RoundNumberPipe } from 'src/shared-pipes/round-number.pipe';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { SharedService } from 'src/shared-services/shared.service';
@Component({
  selector: 'app-bus-details',
  templateUrl: './bus-details.component.html',
  styleUrls: ['./bus-details.component.scss']
})

export class BusDetailsComponent implements OnInit, OnDestroy {
  busdetails: any = {};
  logicalChargeQueueData: any;
  displyRecommendedBtn = true;
  enableMaintance = false;
  hasPermissionForRecommendRunAndTrack = false;
  private busServiceSubscription;

  get disableBtns(): boolean {
    this.hasPermissionForRecommendRunAndTrack = this.sharedService.roleHasPermissionForAction(this.router.url,
      RECOMMEND_RUN_TRACK_ACTION,
      ENABLE);
    if (this.hasPermissionForRecommendRunAndTrack === false) {
      return true;
    }
    return this.trackService.disableBtns;
  }

  constructor(
    private busService: BusService,
    private runService: RunService,
    private ccssDisplayCommonService: CcssDisplayCommonService,
    private trackService: TrackService,
    private snackBar: MatSnackBar,
    private roundNumber: RoundNumberPipe,
    private sharedService: SharedService,
    private timerService: TimerService,
    private router: Router
  ) { }

  ngOnInit() {
    this.trackService.disableBtns = false;
    this.busServiceSubscription = this.busService.getSelectedBusDetails()
      .subscribe(res => {
        this.busdetails.maintenanceRequired = false;
        this.busService.setIsMaintenanceRequired(this.busdetails.maintenanceRequired);
        if (res._source) {
          const source = res._source;
          const busMetada = source.busMetadata;
          this.busdetails.busFrom = res.busFrom;
          this.getmaintanencedata(this.busdetails.busFrom, res.busName);
          this.busdetails.vin = busMetada.vinNum;
          this.busdetails.busName = res.busName;
          const currentEnergyValue = this.getCurrentEnergyFromTrackSetup(this.busdetails.vin);
          this.busdetails.currentEnergy = currentEnergyValue ?
                                          currentEnergyValue :
                                          this.roundNumber.transform(source.PCes_usi_SystemEnergy_kwh, 0) + ' kwh';
          const currentEnergy = parseFloat(this.busdetails.currentEnergy);
          this.runService.currentEnergyVal = isNaN(currentEnergy) ? null : currentEnergy;
          this.busdetails.soc = this.getSOC(source);
          this.busdetails.range = res.range;
          if (parseInt(source.PCtc_udi_Odometer_mi, 0)) {
            this.busdetails.odoMeter = (source.PCtc_udi_Odometer_mi);
          } else {
            this.busdetails.odoMeter = '';
          }
          this.busdetails.energyConsumption = res.energyConsumption;
          this.busdetails.faultsCount = res.faultsCount;
          this.busdetails.batteryCapacity = this.getBatteryCapacityFromTrackSetup(this.busdetails.vin);
          if (this.busdetails.busFrom === 'LCQ') {
            this.ccssDisplayCommonService.loadLogicalChargeQueueSearchBus(this.busdetails.vin);
          }
          this.displyRecommendedBtn = true;
          this.trackService.disableBtns = false;
          this.enableMaintance = false;
        } else {
          this.displyRecommendedBtn = false;
          this.busdetails.maintenanceRequired = false;
          this.busService.setIsMaintenanceRequired(this.busdetails.maintenanceRequired);
          this.enableMaintance = true;
          this.busdetails = {};
        }
      });

    this.runService.getRunResponseTypeHandler()
      .subscribe(res => {
        const MESSAGEOBJ: IToastMessage = { type: TOASTMESSAGE_TYPE.SUCCESS, message: res.message };
        this.snackBar.openFromComponent(ToastAlertComponent, TOAST_CONFIG_PAYLOAD.get(MESSAGEOBJ));
      });

    this.ccssDisplayCommonService.getLogicalChargeQueueSearchBusData().subscribe(res => {
      if (res.length === 0) {
        this.displyRecommendedBtn = true;
      }
    });
  }

  ngOnDestroy() {
    this.busServiceSubscription.unsubscribe();
  }

  updateMaintenace(event) {
    this.busdetails.maintenanceRequired = event.checked;
    this.busService.toggleMaintenace(event.checked);
    this.runService.clearRecomendedRunsView();
    this.trackService.clearRecomendedTrackView();
    localStorage.removeItem('filterdBusData');
    this.ccssDisplayCommonService.clearLogicalChargeQueueSearchBusData();
  }

  recomnendedRun() {
    this.timerService.blnRecommendRunAndTrackInProcess = true;
    this.busService.toggleMaintenace(this.busdetails.maintenanceRequired);
    this.runService.loadRecommendedRuns(this.busdetails);
    this.runService.getTopRecommended().subscribe(res => {
      if (res === false && this.busdetails.maintenanceRequired === false) {
        this.runService.setHideOverride(false);
        const MESSAGEOBJ: IToastMessage = { type: TOASTMESSAGE_TYPE.ERROR, message: TOAST_LOAD_RECOMMENDED_LIST_ERROR };
        this.snackBar.openFromComponent(ToastAlertComponent, TOAST_CONFIG_PAYLOAD.get(MESSAGEOBJ));
      } else if (this.busdetails.maintenanceRequired) {
        this.trackService.clearRecomendedTrackView();
      }
    });
  }

  getBatteryCapacityFromTrackSetup(busVIN) {
    let returnValue = 0;
    const localTrackData = JSON.parse(localStorage.getItem('tracksData'));
    if (localTrackData) {
      for (const bus of localTrackData.buses) {
        if (bus.busVIN === busVIN) {
          if (bus.availableEnergy != null) {
            const availableEnergy = this.roundNumber.transform(bus.availableEnergy, 0);
            returnValue = parseFloat(availableEnergy);
          }
        }
      }
    }
    return returnValue;
  }

  getCurrentEnergyFromTrackSetup(busVIN) {
    let returnValue = '';
    const localGarageCells = localStorage.getItem('garageAllCels');
    const GARAGEALLCELLS = localGarageCells ? JSON.parse(localGarageCells) : [];
    if (GARAGEALLCELLS) {
      for (const charger of GARAGEALLCELLS) {
        if (charger.bus !== null && charger.bus.busVIN === busVIN) {
          if (charger.bus.currentEnergy === null) {
            returnValue = NOT_APPLICABLE;
          } else {
            returnValue = this.roundNumber.transform(charger.bus.currentEnergy, 0) + ' kwh';
          }
        }
      }
    }
    return returnValue;
  }

  getmaintanencedata(busFrom, busId) {
    if (!busId) { return; }
    if (busFrom === 'LCQ') {
      this.busdetails.maintenanceRequired = this.busService.maintenaceFromTracksetup === true ? true : false;
      this.busService.setIsMaintenanceRequired(this.busdetails.maintenanceRequired);
    } else if (busFrom === 'assets') {
      this.busService.getSearchedBusMaintenanceData()
        .subscribe(res => {
          this.busdetails.maintenanceRequired = res === 'Required' ? true : false;
          this.busService.setIsMaintenanceRequired(this.busdetails.maintenanceRequired);
        });
      this.busService.loadSearchedBusNeedMaintenance(busId);
    } else {
      const maintenanceValue = this.busService.maintenanceData;
      if (maintenanceValue) {
        this.busdetails.maintenanceRequired = maintenanceValue[busId] === 'Required' ? true : false;
      } else {
        this.busdetails.maintenanceRequired = false;
      }
      this.busService.setIsMaintenanceRequired(this.busdetails.maintenanceRequired);
    }
  }

  getSOC(source) {
    let returnValue = NOT_APPLICABLE;
    if (source.Ignition_Discrete) {
      if (parseInt(source.Ignition_Discrete) >= 1) {
        returnValue = this.getSOCValue(source.PCes_usi_DashSoC_pct);
      } else {
        returnValue = NOT_APPLICABLE;
      }
    } else {
      if (parseInt(source.PCbo_bo_PTHVReady) >= 1) {
        returnValue = this.getSOCValue(source.PCes_usi_DashSoC_pct);
        } else {
        returnValue = NOT_APPLICABLE;
      }
    }
    return returnValue;
  }

  getSOCValue(value) {
    return _.isEmpty(value) ?
      this.roundNumber.transform(value, 0) + ' %' : NOT_APPLICABLE;
  }
}
