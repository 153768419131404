import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { LoginServiceService } from '../app/pre-login/login/login.service';
import { HeaderService } from 'src/shared-services/header.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CommsService {

  windowOpenSource: any;
  isOnline = true;

  constructor(private loginService: LoginServiceService, private headerService: HeaderService, private router: Router) {
    window.addEventListener('message', this.receiveLoginMessage.bind(this), false);
  }

  receiveLoginMessage(event) {
    if (environment) {
      if (event.origin !== environment.APEX_URL) {
        return;
      }
      try {
        this.windowOpenSource = event.source;
        console.log("EVENT RECEIVED: " + event.data.TYPE);
        switch (event.data.TYPE) {
          case ('ESTABLISH_CONNECTION_FROM_APEX'):
            this.windowOpenSource.postMessage({ TYPE: 'ESTABLISH_CONNECTION_FROM_CCSS' }, environment.APEX_URL);
            break;
          case ('LOGIN'):
            const idToken = event.data.idToken;
            const refreshToken = event.data.refreshToken;
            const userDetails = event.data.userDetails;
            const path = event.data.path;
            if (idToken && refreshToken && userDetails && path) {
              this.loginSequence(idToken, refreshToken, userDetails, path)
            }
            break;
          case ('NAVIGATE_TO_PAGE'):
            const pagePath = event.data.pagePath;
            this.router.navigate([pagePath]);
            break;
          case ('LOGOUT'):
            this.loginService.logout();
            this.router.navigate(['login']);
            break;
          default:
            break;

        }
      } catch (error) {
        console.log('in error');
        console.log(error);
      }
    } else {
      setTimeout(function () {
        this.receiveLoginMessage(event);
      }, 2000);
    }
  }

  sendLogoutMessage() {
    if (this.windowOpenSource) {
      this.windowOpenSource.postMessage({ TYPE: 'LOGOUT' }, environment.APEX_URL);
    }
  }

  loginSequence(idToken, refreshToken, userDetails, path) {
    console.log('in loginSequence');
    this.loginService.setAccessToken(idToken);
    this.loginService.setRefreshToken(refreshToken);
    this.loginService.setLoggedInUserDetails(userDetails);
    this.headerService.loadGarages();
    this.loginService.getUITabs();
    this.router.navigate([path]);
    console.log('after loginSequence');
  }

}
