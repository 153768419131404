import { Component, OnInit, Inject } from '@angular/core';

import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import {
  TOAST_ERROR,
  TOAST_IMAGE_PATHS
} from 'src/app-constants/app-constants';

@Component({
  selector: 'app-toast-alert',
  templateUrl: './toast-alert.component.html',
  styleUrls: ['./toast-alert.component.scss']
})
export class ToastAlertComponent implements OnInit {
  message = '';
  showToast = false;
  toastImg = '';
  constructor(
    public toastRef: MatSnackBarRef<ToastAlertComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
  ) { }

  ngOnInit() {
    if (this.data) {
      this.showToast = true;
      this.toastImg = this.data.type === TOAST_ERROR ? TOAST_IMAGE_PATHS.error : TOAST_IMAGE_PATHS.success;
      this.message = this.data.message;
    }
  }

}
