import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeInHrs'
})

export class TimeInHrsPipe implements PipeTransform {

  transform(value: any, metrick: string): any {
    const measuredTime = new Date(null);
    const seconds = value;
    let returnValue = '';
    if (seconds === undefined) {
      returnValue = '';
    } else if (seconds === 0) {
      returnValue = '00:00';
    } else if (seconds === 'N/A') {
      returnValue = seconds;
    } else {
      measuredTime.setSeconds(seconds);
      const hhMm = measuredTime.toISOString().substr(11, 5);
      value ? returnValue = hhMm : returnValue = '';
    }
    return returnValue;
  }

}
