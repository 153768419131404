export const MOCK_INCOMING_BUS_QUEUE = {
    "get":"/assets/mock-data/incoming-bus-queue.json"
}
export const MOCK_BUS_DETAILS = {
    "get":"/assets/mock-data/bus-details-api-sample.json"
}
export const MOCK_RUN_TRACK_DETAILS = {
    "get":"/assets/mock-data/run-track-api-sample.json"
}

export const MOCK_CCSS_DISPLAY_CHARGER_FAULTS = {
    "get":"/assets/mock-data/ccss-display/charger-faults-api-sample.json"
}

export const MOCK_CCSS_DISPLAY_LOGICAL_CHARGE_QUEUE = {
    "get":"/assets/mock-data/ccss-display/logical-charge-queue-api-sample.json"
}

export const MOCK_CCSS_DISPLAY_CHARGING_STATIONS = {
    "get":"/assets/mock-data/ccss-display/charging-stations-sample.json"
}