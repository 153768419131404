<div class="header">
  <div class="row mx-0 header-content">
    <div class="col-3 logo-container">
      <div class="nav-menu-left" [hidden]='hideMenu'>
        <app-navbar-left></app-navbar-left>
      </div>
      <div class="logo"></div>
    </div>
    <div class="col-6 header-text text-center">{{title}}</div>
    <div class="col-3 text-right system-date-time">
      <div ngbDropdown *ngIf="garages.length" class="garage-dropdown" placement="bottom-right">
        <button class="btn btn-outline-primary" ngbDropdownToggle [matTooltip]='activeGarage' [matTooltipDisabled]="activeGarage && activeGarage.length <= 16">{{activeGarage | limitTo:16}}</button>
        <div ngbDropdownMenu aria-labelledby="garageDropdown">
            <ng-container *ngFor="let garage of garages; let i=index">
                <button ngbDropdownItem *ngIf="garage.trackSetupName !== activeGarage" (click)="switchGarage(garage,i)">
                    {{garage.trackSetupName}}
                </button>
            </ng-container>
        </div>
      </div>
      <span class='system-date'>{{systemTime | date:'dd MMM'}}</span>
      <p class="time system-date">{{systemTime | date:'HH:mm'}}</p>
      <span class='system-date logout' (click)="logout()" [hidden]='hideMenu'>Logout</span>
      <!-- <button mat-button [matMenuTriggerFor]="menu" [hidden]='hideMenu'>
        <mat-icon svgIcon="mdi-account" class="menu-btn"></mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="logout()" [hidden]='hideMenu'>Logout</button>
      </mat-menu> -->
    </div>
  </div>
</div>