import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from '../../app/material-module';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BusRunListComponent } from './bus-run-list.component';
import { BusIdPipe } from 'src/shared-pipes/bus-id.pipe';
import { MileKiloMeterPipe } from 'src/shared-pipes/mile-kilo-meter.pipe';
import { TimeInHrsPipe } from 'src/shared-pipes/time-in-hrs.pipe';

@NgModule({
  declarations: [
    BusRunListComponent,
    BusIdPipe,
    MileKiloMeterPipe,
    TimeInHrsPipe
  ],
  imports: [
    AppMaterialModule,
    MatInputModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ], providers: [],
  exports: [
    BusRunListComponent,
    CommonModule
  ]
})

export class AppBusRunModule { }
