import { Injectable } from '@angular/core';
import { Subject, throwError, BehaviorSubject } from 'rxjs';
import { BusService } from './bus.service';
import { SharedService } from './shared.service';
import { RestService } from './rest.service';
import {
  PE_API_ASSIGN_TRACK,
  PE_API_CLEAR_ALL_BUS_ASSIGMENTS,
  PE_API_TRACK_LIST,
  PE_API_ASSIGN_TRACK_MANUAL,
  PE_API_CHANGE_PARKING_POSITION,
  LOG_ERROR_TRACK_ASSIGN,
  LOG_ERROR_MANUAL_TRACK_ASSIGN,
  LOG_ERROR_FETCH_TRACK_LIST,
  TOAST_ASSIGN_BUS_SUCCESS,
  TOAST_LOAD_TRACK_LIST_ERROR,
  TOAST_SUCCESS,
  TOAST_ERROR,
  CHANGE_PARKING_POSITION_REQUEST_ID,
  LOAD_TRACK_LIST_REQUEST_ID
} from '../app-constants/app-constants';
import { MockService } from './mock.service';
import { MOCK_RUN_TRACK_DETAILS } from '../app-constants/app-mock-constants';
import { ExceptionService } from './exception.service';
import { HeaderService } from './header.service';
import { LoginServiceService } from 'src/app/pre-login/login/login.service';
import { NotificationsService } from './notifications.service';
import { CcssDisplayCommonService } from 'src/shared-services/ccss-display-common.service';

@Injectable({
  providedIn: 'root'
})

export class TrackService {
  private recommendedTracks$ = new Subject<any>();
  private recommendedTrack$ = new Subject<any>();
  private recommendedTracks: any;
  private recommendedTrack: any;
  private garageBusTrackList$ = new Subject<any>();
  private showTrackList = 'garage';
  private showTrackList$ = new Subject<string>();
  private selectedBusTrack: any;
  public hideTrackOverride$ = new Subject<boolean>();
  public blnDropSave$ = new BehaviorSubject<boolean>(false);
  public clearRecommendedRun$ = new Subject<any>();
  private trackResponseHandler$ = new Subject<{ message: string, type: string }>();
  private loadTrackListResponse$ = new Subject<{ message: string, type: string }>();
  private disableActionBtns = false;
  private disableOverrideActionBtns = false;

  get disableBtns(): boolean {
    return this.disableActionBtns;
  }

  set disableBtns(flag: boolean) {
    this.disableActionBtns = flag;
  }

  get disableOverrideBtns(): boolean {
    return this.disableOverrideActionBtns;
  }

  set disableOverrideBtns(flag: boolean) {
    this.disableOverrideActionBtns = flag;
  }

  constructor(
    private ccssService: CcssDisplayCommonService,
    private busService: BusService,
    private sharedService: SharedService,
    private restService: RestService,
    private mockService: MockService,
    private exceptionService: ExceptionService,
    private headerService: HeaderService,
    private loginService$: LoginServiceService,
    private notificationsService: NotificationsService
  ) { }

  clearAssignments() {
    const garageId = this.headerService.getSelectedGarageId();
    const tenantId = this.headerService.getTenantId();
    if (garageId === null || garageId === '') { return; }
    if (tenantId === null || tenantId === '') { return; }
    const reqObj = {
      url: PE_API_CLEAR_ALL_BUS_ASSIGMENTS.post(garageId, tenantId),
      body: {},
      options: {}
    };

    this.restService.post(reqObj)
      .subscribe(res => {
        const res1 = JSON.parse('{"successMsg":"all assigned tracks are cleared"}');
        this.garageBusTrackList$.next(Object.assign({}, { data: res1 }).data);
      });
  }

  loadRecommendedTracks(trackData) {
    this.recommendedTracks = trackData;
    this.setSelectedBusTrack(this.recommendedTracks);
    this.recommendedTracks$.next(Object.assign({}, { data: this.recommendedTracks }).data);
    this.hideTrackOverride$.next(false);
  }

  assignTrack() {
    const garageId = this.headerService.getSelectedGarageId();
    const tenantId = this.headerService.getTenantId();
    if (garageId === null || garageId === '') { return; }
    if (tenantId === null || tenantId === '') { return; }
    const reqObj = {
      url: PE_API_ASSIGN_TRACK.post(garageId, tenantId),
      body: this.getTrackAssignmentRequestPayload(),
      options: {}
    };

    this.restService.post(reqObj)
      .subscribe(res => {
        const resObj = { ...res, successMessage: 'Track is assigned successfully' };
        this.garageBusTrackList$.next(Object.assign({}, { data: resObj }).data);
        this.setShowTrackList('garage');
        this.busService.removeAssignedBusFromIQ(reqObj.body.busVIN);
        this.clearRecomendedTrackView();
        this.clearRecommendedRun$.next({});
      }, err => {
        this.exceptionService.log(LOG_ERROR_TRACK_ASSIGN, err);
      });
  }

  changeRecomendedTrack() {
    this.recommendedTrack = null;
    this.recommendedTrack$.next(Object.assign({}, { data: this.recommendedTrack }).data);
  }

  changeParkingPosition(trackData, currTrackData) {
    const garageId = this.headerService.getSelectedGarageId();
    const garage = this.headerService.getSelectedGarage();
    const tenantIdVal = this.headerService.getTenantId();
    if (garageId === null || garageId === '') { return; }
    if (tenantIdVal === null || tenantIdVal === '') { return; }
    const busVINVal = trackData.bus.busVIN;
    const busNameVal = trackData.bus.busName;
    const runID = trackData.bus.assignedRunId;
    const runNumberVal = trackData.bus.assignedRunNumber;
    const reqObj = {
      url: PE_API_CHANGE_PARKING_POSITION.post(garageId, tenantIdVal) + '/' + busVINVal,
      body: {
        customerGarageId: garage.customerGarageId,
        id: this.sharedService.create_UUID(CHANGE_PARKING_POSITION_REQUEST_ID),
        run: {
          busVIN: busVINVal,
          busName: busNameVal,
          chargerPositionId: currTrackData.chargerPositionId,
          runId: runID,
          runNumber: runNumberVal,
          trackId: garageId,
          rowStart: currTrackData.rowStart,
          rowEnd: currTrackData.rowEnd,
          columnStart: currTrackData.columnStart,
          columnEnd: currTrackData.columnEnd,
          busUnitId: busNameVal,
          oldPositionId: trackData.customerPositionId,
          positionId: currTrackData.customerPositionId,
          trackName: currTrackData.trackName,
          trackPosition: currTrackData.positionInTrack + 1,
          oldTrackName: trackData.trackName,
          oldTrackPosition: trackData.positionInTrack + 1,
          isMaintenanceRequired: trackData.bus.isMaintenanceRequired ? trackData.bus.isMaintenanceRequired : false
        },
        tenantId: tenantIdVal
      },
      options: {}
    };

    this.restService.post(reqObj)
      .subscribe((res: any) => {
        const resObj = { ...res, successMessage: 'Track is assigned successfully' };
        this.setShowTrackList('garage');
        this.blnDropSave$.next(true);
        this.trackResponseHandler$.next(Object.assign({},
          {
            data: {
              type: TOAST_SUCCESS,
              message: res.message
            }
          }).data);
        this.busService.removeAssignedBusFromIQ(busVINVal);
        this.notificationsService.loadNotifications();
      }, err => {
        this.blnDropSave$.next(false);
        const ERROR_MESSAGE = err.error ? err.error.message : err.message;
        this.exceptionService.log(LOG_ERROR_MANUAL_TRACK_ASSIGN, err);
      });
  }

  loadTrackList() {
    const USERDATA = this.loginService$.getAccessToken();
    if (USERDATA) {
      const TenantId = this.headerService.getTenantId();
      const garageId = this.headerService.getSelectedGarageId();
      if (garageId === null || garageId === '') {  this.garageBusTrackList$.next({}); return; }
      if (TenantId === null || TenantId === '') { return; }
      const selectedGarage = localStorage.getItem('selectedGarage') ? localStorage.getItem('selectedGarage') : '';
      const garageName = JSON.parse(selectedGarage).trackSetupName;
      const reqObj = {
        url: PE_API_TRACK_LIST.get(TenantId) + garageId,
        options: {
          params: {
            tenantId: TenantId,
            requestId: this.sharedService.create_UUID(LOAD_TRACK_LIST_REQUEST_ID),
          }
        }
      };

      this.restService.get(reqObj)
        .subscribe(res => {
          const loadTrackListResponse = {
            message: TOAST_LOAD_TRACK_LIST_ERROR.get(garageName),
            type: TOAST_ERROR
          };
          localStorage.removeItem('tracksData');
          localStorage.setItem('tracksData', JSON.stringify(res));
          let trackListData: any;
          trackListData = res;
          if (trackListData === {} || (trackListData && (trackListData.numberOfColumns === null && trackListData.numberOfRows === null))) {
            this.loadTrackListResponse$.next(Object.assign({}, { data: loadTrackListResponse }).data);
            this.garageBusTrackList$.next(Object.assign({}, { data: [] }).data);
            this.notificationsService.clearNotifications();
            this.busService.clearIncomingQueue();
          } else {
            this.garageBusTrackList$.next(Object.assign({}, { data: res }).data);
            this.ccssService.loadLogicalChargeQueue();
            this.busService.loadIncomingBusList();
            this.notificationsService.loadNotifications();
          }
        }, err => {
          this.exceptionService.log(LOG_ERROR_FETCH_TRACK_LIST, err);
        });
    }
  }

  showTrackListData(skipGarage = false) {
    const USERDATA = this.loginService$.getAccessToken();
    const existedTracksData = localStorage.getItem('tracksData') ? localStorage.getItem('tracksData') : '';
    if(existedTracksData !== '' && skipGarage) {
      this.garageBusTrackList$.next(Object.assign({}, { data: JSON.parse(existedTracksData) }).data);
      return;
    }
    if (USERDATA) {
      const TenantId = this.headerService.getTenantId();
      const garageId = this.headerService.getSelectedGarageId();
      const reqObj = {
        url: PE_API_TRACK_LIST.get(TenantId) + garageId,
        options: {
          params: {
            tenantId: TenantId,
            requestId: this.sharedService.create_UUID(LOAD_TRACK_LIST_REQUEST_ID),
          }
        }
      };

      this.restService.get(reqObj)
        .subscribe(res => {
          localStorage.removeItem('tracksData');
          localStorage.setItem('tracksData', JSON.stringify(res));
          this.garageBusTrackList$.next(Object.assign({}, { data: res }).data);
          this.ccssService.loadLogicalChargeQueue();
          this.notificationsService.loadNotifications();
        }, err => {
          this.exceptionService.log(LOG_ERROR_FETCH_TRACK_LIST, err);
        });
    }
  }

  setShowTrackList(showValue) {
    this.showTrackList = showValue;
    /* istanbul ignore else */
    this.showTrackList$.next(showValue);
  }

  showTrackListValue() {
    return this.showTrackList;
  }

  setSelectedBusTrack(selectedBusTrack) {
    this.selectedBusTrack = selectedBusTrack;
  }

  clearRecomendedTrackView() {
    this.recommendedTracks$.next({});
    this.hideTrackOverride$.next(true);
    this.selectedBusTrack = null;
  }

  getRecommendedTracks() {
    return this.recommendedTracks$.asObservable();
  }

  hideTrackOverride() {
    return this.hideTrackOverride$.asObservable();
  }

  getGarageBusTrackList() {
    return this.garageBusTrackList$.asObservable();
  }

  canShowTrackList() {
    return this.showTrackList$.asObservable();
  }

  getTrackResponseTypeHandler() {
    return this.trackResponseHandler$.asObservable();
  }

  getDropSaveFlag() {
    return this.blnDropSave$.asObservable();
  }

  clearRecommendedRun() {
    return this.clearRecommendedRun$.asObservable();
  }

  getTrackListStatus() {
    return this.loadTrackListResponse$.asObservable();
  }

  private getTrackAssignmentRequestPayload() {
    const busSource = this.busService.getSelectedBusMetaData();
    return {
      busVIN: busSource.busMetadata.vinNum,
      bus: {
        distanceInMiles: this.selectedBusTrack.distanceInMiles,
        bookOutTime: this.selectedBusTrack.bookOutTime,
        maintenanceRequired: busSource.busMetadata.inServiceFlag > 0,
        currentEnergy: busSource.PCes_usi_SystemEnergy_kwh,
        assignedRunId: this.selectedBusTrack.runId,
        bookInTime: this.selectedBusTrack.bookInTime
      }
    };
  }

  private getDropTrackAssignmentRequestPayload(busVINNumber) {
    const busSource = this.busService.getSelectedBusMetaData();
    return {
      busVIN: busVINNumber,
      bus: {
        distanceInMiles: this.selectedBusTrack.distanceInMiles,
        bookOutTime: this.selectedBusTrack.bookOutTime,
        maintenanceRequired: busSource.busMetadata.inServiceFlag > 0,
        currentEnergy: busSource.PCes_usi_SystemEnergy_kwh,
        assignedRunId: this.selectedBusTrack.runId,
        bookInTime: this.selectedBusTrack.bookInTime
      }
    };
  }



  loadMockTracks() {
    this.mockService.get(MOCK_RUN_TRACK_DETAILS.get)
      .subscribe(res => {
        this.recommendedTracks = res[0].busTrack;
        this.recommendedTracks$.next({});
      });
  }
}
