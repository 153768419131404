import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginServiceService } from './login.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ChallengeParameters, CognitoCallback, LoggedInCallback } from '../../auth/cognito.service';
import { distinctUntilChanged, map, debounceTime } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HeaderService } from 'src/shared-services/header.service';
import { SharedService } from 'src/shared-services/shared.service';
import { ACCESS_PERMISSION_ERROR, AGREEMENT_ERROR, CCSS, ECMS } from '../../../app-constants/app-constants';
import {  CognitoService } from '../../auth/cognito.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgForm } from '@angular/forms';

const docuSignClick = require('src/docusign-click.js');
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  @ViewChild('loginForms', {static: false}) form;
  loginForm = {
    username: '',
    userPd: '',
    rememberMe: false
  };
  users;
  userNames: Array<any> = [];
  public loginErrorMessage: any;
  errorMessage: string;
  successMessage: string;
  mfaStep = false;
  mfaData = {
    destination: '',
    callback: null
  };

  constructor(
    private router: Router,
    private loginService: LoginServiceService,
    private authService: AuthService,
    private currentRoute: ActivatedRoute,
    private headerService: HeaderService,
    private sharedService: SharedService,
    private snackBar: MatSnackBar,
    private cognitoUtil: CognitoService
  ) { }

  ngOnInit() {
    this.headerService.setTitle('Login', true);
    this.successMessage = this.currentRoute.snapshot.queryParams['successMessage'];
    this.errorMessage = this.currentRoute.snapshot.queryParams['errorMessage'];
    this.authService.isAuthenticated(this);
    const users = JSON.parse(localStorage.getItem('rememberedUsers') || '[]');
    this.users = Array.apply([], users);
    if (users.length) {
      users.forEach((user) => {
        this.userNames.push(user.username);
      });
      this.loginForm = Object.assign({}, this.users[0]);
    }
  }

  navigateToFPPage() {
    this.router.navigate(['/forgotPassword']);
  }

  navigateToDashboard() {
    this.authService.authenticate(this.loginForm.username, this.loginForm.userPd, this);
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : (this.userNames).filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)))
  onselect(e) {
    const find = this.users.find((user) => {
      return user.username === e.item
    });
    this.loginForm = find ? Object.assign({}, find) : { username: '', userPd: '', rememberMe: false };
  }

  cognitoCallback(message: string, result: any) {
    if (this.loginForm.rememberMe === true) {
      const users = JSON.parse(localStorage.getItem('rememberedUsers') || '[]');
      const findUser = users.findIndex((user) => {
        return user.username === this.loginForm.username;
      });

      if (findUser < 0) {
        users.push(this.loginForm);
      } else if (findUser >= 0) {
        users[findUser] = this.loginForm;
      }
      localStorage.setItem('rememberedUsers', JSON.stringify(users));
    }
    if (message !== null) { // error
      this.errorMessage = message;
    } else { // success
      const idToken = result.idToken.jwtToken;
      const refreshToken = result.refreshToken.token;
      this.loginService.setAccessToken(idToken);
      this.loginService.setRefreshToken(refreshToken);
      this.loginService.setLoggedInUserDetails(result.idToken.payload);
      const tenantId = this.headerService.getTenantId();
      if(!!tenantId) {
        this.checkForTenantAgreementStatus(tenantId);
      } else {
        this.loadTabsApiAndCheckPermission();
      }
    }
  }

  loadTabsApiAndCheckPermission(): void {
    this.loginService.getUITabs();
    this.loginService.getPermission().subscribe(res => {
      const permission: any = res;
      if (!permission.hasOwnProperty(CCSS) && !permission.hasOwnProperty(ECMS)) {
        this.errorMessage = ACCESS_PERMISSION_ERROR;
        this.authService.logout();
        return;
      }
      this.headerService.loadGarages();
      this.router.navigate(['runTrackAssign']);
    });
  }

  checkForTenantAgreementStatus(tenantId: string): void {
    var appRef = this;
    appRef.loginService.checkCustomerAgreementStatus(tenantId)
    .subscribe(response => {
      if(response.data && response.data.agreementUrl) {
        const agreementUrl = response.data.agreementUrl;
        docuSignClick.Clickwrap.render({
          agreementUrl:  agreementUrl,
          onAgreed: function (agreementData) {
              appRef.loginService.updateCustomerAgreementStatus(tenantId, {
                status: agreementData.status,
                lastAcceptedTime: appRef.sharedService.date_ISO(new Date()),
                clientUniqueId: response.data.clientUserId,
                versionNumber: response.data.versionNumber
              })
              .subscribe((agreeResponse) => {
                if(agreeResponse && agreeResponse.status === 200) {
                  appRef.loadTabsApiAndCheckPermission();
                } else {
                  appRef.snackBar.open(AGREEMENT_ERROR, '', {
                    duration: 5000,
                    verticalPosition: 'top',
                    horizontalPosition: 'right',
                    panelClass: ['snack-bar-error']
                  });
                }
              }, () => {
                appRef.snackBar.open(AGREEMENT_ERROR, '', {
                  duration: 5000,
                  verticalPosition: 'top',
                  horizontalPosition: 'right',
                  panelClass: ['snack-bar-error']
                });
              });
          },

          onDeclined: function (agreementData) {
            appRef.loginService.updateCustomerAgreementStatus(tenantId, {
              status: agreementData.status,
              lastAcceptedTime: appRef.sharedService.date_ISO(new Date()),
              clientUniqueId: response.data.clientUserId,
              versionNumber: response.data.versionNumber
            })
            .subscribe((declineResponse) => {
              appRef.form.reset();
              appRef.errorMessage = null;
              if(declineResponse && declineResponse.status !== 200) {
                appRef.snackBar.open(AGREEMENT_ERROR, '', {
                  duration: 5000,
                  verticalPosition: 'top',
                  horizontalPosition: 'right',
                  panelClass: ['snack-bar-error']
                });
              }
            }, () => {
              appRef.snackBar.open(AGREEMENT_ERROR, '', {
                duration: 5000,
                verticalPosition: 'top',
                horizontalPosition: 'right',
                panelClass: ['snack-bar-error']
              });
            });
          },
          onError: function(error) {
            console.log('error', error)
          }
          }, '#ds-terms-of-servicep');

      } else {
        this.loadTabsApiAndCheckPermission();
      }
    }, err => {
      this.snackBar.open(AGREEMENT_ERROR, '', {
        duration: 5000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
        panelClass: ['snack-bar-error']
      });
    });
  }

  handleMFAStep(challengeName: string, challengeParameters: ChallengeParameters, callback: (confirmationCode: string) => any): void {
    this.mfaStep = true;
    this.mfaData.destination = challengeParameters.CODE_DELIVERY_DESTINATION;
    this.mfaData.callback = (code: string) => {
      if (code === null || code.length === 0) {
        this.errorMessage = 'Code is required';
        return;
      }
      this.errorMessage = null;
      callback(code);
    };
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (isLoggedIn) {
      // this.router.navigate(['/home'], { queryParams: { message: 'User is already authenticated' } });
      // for token expired
      this.headerService.loadGarages();
      this.router.navigate(['runTrackAssign']);
    }
  }

  cancelMFA(): boolean {
    this.mfaStep = false;
    return false;   // necessary to prevent href navigation
  }

  showPassword(img) {
    const image = document.createElement('img');
    const src = '../../../assets/images/passwords/' + img + '.svg';
    image.src = src;
    return image.outerHTML;
  }
}
