import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'busId'
})

export class BusIdPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return value ? value.slice(value.length - 4, value.length) : null;
  }
}
