import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from '../../app/material-module';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BusTrackListComponent } from './bus-track-list.component';
import { BusIdPipe } from 'src/shared-pipes/bus-id.pipe';
import { TrackService } from 'src/shared-services/track.service';

@NgModule({
  declarations: [
    BusTrackListComponent,
    BusIdPipe
  ],
  imports: [
    AppMaterialModule,
    MatInputModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [TrackService],
  exports: [
    BusTrackListComponent,
    CommonModule
  ]
})

export class AppBusTrackModule { }
