import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mileKiloMeter'
})

export class MileKiloMeterPipe implements PipeTransform {

  transform(value: any, metrick: string): any {
    const VAL = parseFloat(value);
    const result = (VAL > -1) ? (VAL * 1.60934).toFixed() : '';
    return  result ? result + ' ' + metrick : '';
  }

}
