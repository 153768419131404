<div class="">
  <div class="container-fluid login-div">

    <h5 class="login-header" i18n="@@loginTitle">Enter credentials</h5>
    <div *ngIf="loginErrorMessage || errorMessage || successMessage" [ngClass]="{
              'alert-success': successMessage,
              'alert-danger': errorMessage || loginErrorMessage
            }" class="alert alert-dismissible" role="alert">
      {{
              successMessage
                ? successMessage
                : loginErrorMessage
                ? loginErrorMessage
                : errorMessage
            }}
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <form #loginForms="ngForm" (ngSubmit)="navigateToDashboard()">
      <div class="md-form">
        <mat-form-field class='full-width'>
          <input matInput type='text' id="username" [(ngModel)]="loginForm.username" name="username"
            placeholder='User name' #username="ngModel" required email (selectItem)="onselect($event)">
        </mat-form-field>
        <!-- <input mdbInput type="text" id="username" [(ngModel)]="loginForm.username" name="username"
                #username="ngModel" required class="form-control" email (selectItem)="onselect($event)" />
              <label for="username" i18n="@@loginUsername">Username</label> -->
        <div *ngIf="username.invalid && (username.dirty || username.touched)">
          <mdb-error class="error" *ngIf="username.errors.required">Username is required</mdb-error>
          <mdb-error class="error" *ngIf="username.errors.email"> Invalid Email</mdb-error>
        </div>
      </div>
      <div class="md-form input-group">
        <mat-form-field class='full-width'>
          <input matInput type='password' [(ngModel)]="loginForm.userPd" id="userPd" placeholder='Password'
            name="userPd" #userPd="ngModel" showHideInput class="" required>
        </mat-form-field>
        <!-- <input required mdbInput type="password" [(ngModel)]="loginForm.userPd" id="userPd" name="userPd"
                #userPd="ngModel" showHideInput class="form-control no-padding" />
              <label for="userPd" i18n="@@loginPassword">Password</label> -->
        <!-- <div class="input-group-append rightIcon">
          <span class="input-group-text">
             <span matSuffix showHideTrigger="userPd" [showHideStatus]="{
                      hide: showPassword('show'),
                      show: showPassword('show_password'),
                      materialIcon: true,
                      id: 'userPd'
                    }"></span>
          </span>
        </div> -->
        <div *ngIf="userPd.invalid && (userPd.dirty || userPd.touched)">
          <mdb-error class="error" *ngIf="userPd.errors.required">Password is required</mdb-error>
        </div>
      </div>
      <!-- <div class="row justify-content-between">
        <div class="col">
         // Remember me 
          <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="materialLoginFormRemember" name="rememberMe"
                    [(ngModel)]="loginForm.rememberMe" />
                  <label class="form-check-label" for="materialLoginFormRemember" i18n="@@loginRememberMe">Remember
                    me</label>
                </div>
        </div>
        <div class="col-auto">
          // Forgot password
          <button type="button" class="btn btn-link linkBtn" (click)="navigateToFPPage()" i18n="@@loginFP">
                  Forgot password?
                </button>
        </div>
      </div> -->

      <!-- Sign in button -->
      <div class="md-form">
        <button [disabled]="!loginForms.valid" class="btn button-yellow" type="submit" i18n="@@loginLogin">
            Submit
        </button>
      </div>
    </form>
  </div>
</div>