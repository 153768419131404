import { environment } from 'src/environments/environment';
import {
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material';


/*---------------------APP VARIABLES-------------------*/

export const CURRENT_BUILD_DATE = 'Build 12.21.2020';

/*------------------------------------------------------*/




export const PE_API_LOGIN = {
  post_token: environment.baseUrl_CustomerAssets + '/v1/cognitoLogin'
};

export const PE_API_INCOMING_QUEUE = {
  get: (tenantId, garageId) => environment.baseUrl_IncomingQueue + '/iq/tenants/' + tenantId + '/garages/' + garageId + '/incomingBuses'
};

export const PE_API_UI_TABS = {
  get: environment.baseUrl_CustomerAssets + '/ams/v1/ui/tabs',
};

// export const PE_API_BUS_DETAILS = {
//   get: garageId => environment.baseUrl_Garage_Tracks + '/v1/garages/' + garageId + '/bus/getBusDetails'
// };

export const PE_API_BUS_DETAILS = {
  get: garageId => environment.baseUrl_Garage_Tracks + '/v1/garages/' + garageId + '/bus/getBusDetails',
  post: (garageId, tenantId) =>
    environment.baseUrl_Garage_Tracks + '/v1/tenants/' + tenantId + '/garages/' + garageId + '/bus/getBusDetails'
};

export const TENANT_AGREEMENT_DETAILS = {
  get: tenantId => environment.AGREEMENT_URL + '/tenants/' + tenantId + '/agreement/url',
  post: tenantId => environment.AGREEMENT_URL + '/tenants/' + tenantId +  '/agreement'
};

export const PE_API_RECOMMENDED_RUNS = {
  post: (garageId, tenantId) => environment.baseUrl_RecommendedRun + '/v1/tenants/' + tenantId + '/garages/' + garageId + '/runs'
};

export const PE_API_RECOMMENDED_TRACKS = {
  get: environment.baseUrl_Garage_Tracks + '/v1/tracks'
};

export const PE_API_ASSIGN_TRACK = {
  post: (garageId, tenantId) =>
         environment.baseUrl_Garage_Tracks + '/v1/tenants/' + tenantId + '/trackSetups/' + garageId + '/busAssignments'
};

export const PE_API_CLEAR_ALL_BUS_ASSIGMENTS = {
  post: (garageId, tenantId) =>
        environment.baseUrl_Garage_Tracks + '/v1/tenants/' + tenantId + '/trackSetups/' + garageId + '/clearAllAssignmentRequests'
};

export const PE_API_TRACK_LIST = {
  get: tenantId => environment.baseUrl_Garage_Tracks + '/v1/tenants/' + tenantId + '/trackSetups/'
};

export const PE_API_ASSIGN_TRACK_MANUAL = {
  post: (garageId, tenantId) =>
        environment.baseUrl_Garage_Tracks + '/v1/tenants/' + tenantId + '/trackSetups/' + garageId + '/busManualAssignments'
};

export const PE_API_CHANGE_PARKING_POSITION = {
  post: (garageId, tenantId) =>
       environment.baseUrl_Garage_Tracks + '/v1/tenants/' + tenantId + '/garages/' + garageId + '/changeParkingPosition'
};

export const PE_API_CUSTOMER_ASSESTS = {
  get: garageId => environment.baseUrl_CustomerAssets + '/v1/tenants/' + garageId + '/assets'
};

export const PE_API_NOTIFICATIONS = {
  get: tenantId => environment.baseUrl_Garage_Tracks + '/v1/tenants/' + tenantId + '/notifications'
};

export const PE_API_CCSS_DISPLAY_CHARGER_HISTORY = {
  get: (garageId, tenantId, ocppIDs) =>
        environment.baseUrl_Bus_CCSS_Display + '/v1/connectedportal/chargers/tenants/' +
           tenantId + '/garages/' + garageId + '/chargeSessions?ocppIds=' + ocppIDs
};


export const PE_API_CCSS_DISPLAY_BUS_FAULTS = {
  post: environment.baseUrl_Bus_CCSS_Display + '/v1/connectedportal/counts/bus'
};

export const PE_API_CCSS_DISPLAY_CHARGER_FAULTS = {
  post: environment.baseUrl_Bus_CCSS_Display + '/v1/connectedportal/counts/charger'
};

export const PE_API_ASSIGNRUN = {
  post: (garageId, tenantId) => environment.baseUrl_Garage_Tracks + '/v1/tenants/' + tenantId + '/garages/' + garageId +  '/assignRun'
};

export const PE_API_NEEDMAINTENANCE = {
  post: (garageId, tenantId) => environment.baseUrl_Garage_Tracks + '/v1/tenants/' + tenantId + '/garages/' + garageId + '/getm5busstatus'
};

export const PE_API_ALERTACTION = {
  post: (garageId, tenantId, notificationId) => environment.baseUrl_Garage_Tracks + '/v1/tenants/' + tenantId + '/garages/' +
    garageId + '/notifications/' + notificationId + '/action'
};

export const PE_API_CCSS_DISPLAY_LOGICAL_CHARGE_QUEUE = {
  get: (garageId, tenantId) =>
        environment.baseUrl_Garage_Tracks + '/v1/tenants/' + tenantId + '/trackSetups/' + garageId + '/logicalChargeQueue'
};

export const PE_API_GARAGE_LIST = {
  get: tenantId => environment.baseUrl_Garage_Tracks + '/v1/tenants/' + tenantId + '/trackSetups'
};

export const PE_API_CCSS_DISPLAY_BEB_CHARGING_STATIONS = {
  get: garageId => environment.baseUrl_Garage_Tracks + '/v1/garages/' + garageId + '/bookout/getBookOutAndChargingStatus'
};

export const PE_API_CHARGER_ACTION = {
  post: (garageId, tenantId) =>
        environment.baseUrl_Garage_Tracks + '/v1/tenants/' + tenantId + '/garages/' + garageId + '/chargerActions/perform'
};

export const PE_API_CCSS_DISPLAY_CHARGING_STATES = {
  get: (garageId, tenantId) => environment.baseUrl_CustomerAssets + '/v1/tenants/' + tenantId + '/garages/' + garageId + '/chargerStates'
};

/* ECMS module APIS */
export const PE_API_ECMS_POWER_CAPACITY = {
  get: (tenantId, garageId) => `${environment.baseUrl_Garage_Tracks}/v1/tenants/${tenantId}/garages/${garageId}/ecms/power`,
  post: (tenantId, garageId) => `${environment.baseUrl_Garage_Tracks}/v1/tenants/${tenantId}/garages/${garageId}/ecms/power`
};

export const PE_API_CurrentUser = {
  get: (tenantId, tenantUserId) => `${environment.baseUrl_CustomerAssets}/v1/tenants/${tenantId}/tenantUsers/${tenantUserId}`,
};

export const PE_API_CurrentAdmin = {
  get: (tenantId, tenantAdminId) => `${environment.baseUrl_CustomerAssets}/v1/tenants/${tenantId}/tenantAdmins/${tenantAdminId}`,
};

export const PE_API_ECMS_POWER_MONITORING = {
  get: (tenantId, garageId) => `${environment.baseUrl_Garage_Tracks}/v1/tenants/${tenantId}/garages/${garageId}/ecms/monitoring`,
};
/* End of ECMS module APIS */


export const PE_API_CONFIGURATIONS = {
  get: (tenantId, garageId) => `${environment.baseUrl_Garage_Tracks}/v1/tenants/${tenantId}/garages/${garageId}/configurations`,
  put: (tenantId, garageId) => `${environment.baseUrl_Garage_Tracks}/v1/tenants/${tenantId}/garages/${garageId}/configurations`
};

// Static Object values
export const PE_IMG_LOADING = '../../assets/images/loading.gif';

export const CHARGER_IMAGE_PATH = {
  inactive: '../../assets/images/charger/inactive.svg',
  active: '../../assets/images/charger/Active.svg',
  charging: '../../assets/images/charger/Charging.svg',
  maintenance: '../../assets/images/charger/Maintenance.svg',
  disable: '../../assets/images/charger/disable.svg'
};

export enum CHARGER_LEGEND_STATUS {
  active = 'Active',
  inactive = 'In-Active',
  charging = 'Charging',
  maintenance = 'Maintenance',
  disable = 'Disabled'
}

export enum POWER_GRAPH_TIME {
  time_24hrs = '24 Hrs',
  time_12hrs = '12 Hrs',
  time_1hr = '1 Hr',
  time_15mins = '15 Mins'
}

export enum POWER_GRAPH_TIME_KEYS {
  time_key_24hrs = '24hrs',
  time_key_12hrs = '12hrs',
  time_key_1hr = '1hr',
  time_key_15mins = '15mins'
}

export enum POWER_GRAPH_TIME_AGG_MINS {
  time_agg_24hrs = 60,
  time_agg_12hrs = 30,
  time_agg_1hr = 5,
  time_agg_15mins = 1
}

export enum SORT_ORDER {
  asc = 'asc',
  desc = 'desc'
}

export enum CRITICALITY {
  critical = 'Critical',
  major = 'Major',
  minor = 'Minor'
}

export enum NUMBER_TYPE {
  integer = 'Integer',
  double = 'Double'
}

export enum INPUT_TYPE {
  text = 'text',
  number = 'number',
  boolean = 'boolean'
}

export const BUS_IMAGE_PATH = {
  horizontal: color => '../../assets/images/bus-icon/' + color + 'h.svg',
  vertical: color => '../../assets/images/bus-icon/' + color + '.svg'
};

export enum TOASTMESSAGE_TYPE {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR'
}

export enum CHARGER_STATUS {
  active = 'active',
  inactive = 'in-active',
  charging = 'charging',
  maintenance = 'maintenance',
  disabled = 'disabled',
  // These are used for the Changer shadow API.
  available = 'available',
  unavailable = 'unavailable',
  finishing = 'finishing',
  preparing = 'preparing',
  faulted = 'faulted',
  disconnected = 'disconnected'
}

export enum CHARGER_ACTION {
  start = 'START',
  stop = 'STOP',
  terminate = 'TERMINATE',
  enable = 'ENABLE',
  disable = 'DISABLE',
  softReset = 'SOFT_RESET',
  hardReset = 'HARD_RESET',
  setPower = 'SET_POWER',
  clearProfile = 'CLEAR_PROFILE',
}

export enum ASSET_TYPE {
  vehicle = 'VEHICLE',
  charger = 'CHARGER'
}

/*---------------String constants------------------------------------*/
export const LOGIN_AUTHENTICATION_REQUIRED = 'Authentication Required';
export const LOGIN_APPROVAL_REQUIRED = 'Supervisor Approval Required';
export const RUN_TRACK_ASSIGNMENT_TITLE = 'Run & Track Assignment';
export const BOOK_OUT_STATUS_TITLE = 'BEB Book Out Status';
export const BUS_COUNTS_TITLE = 'Bus Counts';
export const GARAGE_OVERVIEW_TITLE = 'Garage Overview';
export const CHARGER_CONTROL_TITLE = 'Charger Control';
export const ENERGY_MANAGEMENT_TITLE = 'Energy Management';
export const CHARGING_STATIONS_TITLE = 'Charging Stations';
export const CHARGING_STATIONS_SUBTITLE = 'Depot | Shop';
export const LCQ_TITLE = 'Logical Charge Queue';
export const CHARGE_HISTORY_TITLE = 'Charge History';
export const ELECTRIC_BUS_FAULTS_TITLE = 'Electric Bus Faults';
export const CHARGER_FAULTS_TITLE = 'Charger Faults';
export const ECMS_TITLE = 'Energy Management';
export const CONFIGURATION_TITLE = 'CCSS Configuration';
export const CHARGER_CLASSIFICATION = 'positionInChargeQueue';
export const CHARGER_MODEL = 'chargerModel';
export const CHARGER_POWERLIMIT = 'chargerMaxPowerLimit';
export const CHARGER_CURRENTLIMIT = 'ocppTransactionPowerActiveImport';
export const CHARGER_POWER_TEXT = 'Charger Power Consumption (kW)';
export const CHARGER_ASSET_ID = 'assetId';
export const CHARGER_ID = 'chargerId';
export const ONLINE_POWER_TEXT = 'Online Power Capacity (kW)';
export const INCOMING_POWER_TEXT = 'Available Incoming Power (kW)';
export const Y_AXIS_TITLE = 'Power (kW)';
export const CHART_TITLE = 'Power Monitoring';
export const TIMESTAMP = 'Timestamp';
export const DATE_TIME = 'Date and Time';
export const INFINTE_DELAY = 'Infinite';
export const INFINITE_BOOKOUT_DELAY = 'Infinity';
export const INITIATE_CHARGING = 'Initiate Charging';
export const ABB_CHARGER = 'ABB';
export const GLOBAL = 'Global';
export const TIME_IN_MIN = 'timeInMs';

export const HEADER_CHARGING_STATION_ID = 'Charging Station ID';
export const HEADER_BUS_ID = 'Bus ID';
export const HEADER_FAULTED_SYSTEM = 'Faulted System';
export const HEADER_CRITICALITY = 'Criticality';
export const HEADER_DATE_TIME = 'Date and Time';
export const HEADER_CHARGING_START_TIME = 'Charging Start Time';
export const HEADER_CHARGING_SESSION_DURATION = 'Charging Session Duration';
export const HEADER_ENERGY_DELIVERED = 'Energy Delivered (kWh)';
export const HEADER_TERMINATION_REASON = 'Termination Reason';

export const HEADER_KEY_CHARGING_STATION_ID = 'chargerId';
export const HEADER_KEY_BUS_ID = 'busid';
export const HEADER_KEY_FAULTED_SYSTEM = 'faultedsystem';
export const HEADER_KEY_CRITICALITY = 'criticallty';
export const HEADER_KEY_DATE_TIME = 'dateTime';
export const HEADER_KEY_CHARGER_POINT = 'chargepoint';
export const HEADER_KEY_VEHICLE = 'vehicle';
export const HEADER_KEY_TRANSACTION_START = 'transactionstart';
export const HEADER_KEY_CHARGING_TIME = 'chargingtime';
export const HEADER_KEY_ENERGY = 'energy';
export const HEADER_KEY_TERMINATION_REASON = 'terminationreason';

/*---------------Color codes------------------------------------*/
export const BLACK_COLOR = 'rgba(0, 0, 0, 1)';
export const WHITE_COLOR = 'rgba(255, 255, 255, 1)';
export const TRANSPARENT_COLOR = 'rgba(0, 0, 0, 0)';
export const TITLE_COLOR = 'rgba(255, 255, 255, 0.6)';
export const GRID_COLOR = 'rgba(151, 151, 151, 0.4)';
export const CHARGER_POWER_CONSUMPTION_COLOR = 'rgba(0, 145, 255, 1)';
export const ONLINE_POWER_CAPACITY_COLOR = 'rgba(252, 213, 0, 1)';
export const INCOMING_POWER_CAPACITY_COLOR = 'rgba(185, 68, 55, 1)';
export const TICK_COLOR = WHITE_COLOR;
export const PDF_COLOR = BLACK_COLOR;
export const BUS_COLOR_YELLOW = 'yellow';
export const BUS_COLOR_RED = 'red';
export const BUS_COLOR_MAINT = 'maint';
export const BUS_COLOR_ORANGE = 'orange';
export const BUS_COLOR_DEFAULT = 'blue';
export const BUS_COLOR_GREEN = 'green';

/*---------------Monitoring graph CSV keys ------------------------------------*/
export const KEY_TIMESTAMP = 'timeStamp';
export const KEY_CHARGER_POWER_CONSUMPTION = 'chargerPowerConsumption';
export const KEY_ONLINE_POWER_CAPACITY = 'onlinePowerCapacity';
export const KEY_INCOMING_POWER_CAPACITY = 'incomingPowerCapacity';

/*---------------Route------------------------------------*/
export const GARAGE_OVERVIEW_ROUTE = '/garageOverview';
export const CHARGER_CONTROL_ROUTE = '/chargerControl';
export const RUN_TRACK_ASSIGNMENT_ROUTE = '/runTrackAssign';
export const ECMS_ROUTE = '/energyManagement';
export const CONFIGURATION_ROUTE = '/configuration';

export const PDF_REPORT = 'Proterra Report';
export const PDF_CONFIDENTIAL = 'PROTERRA CONFIDENTIAL';

/*---------------Error Messages------------------------------------*/
export const  ACCESS_PERMISSION_ERROR = 'You do not have sufficient permissions to access this application';
export const TOAST_LOAD_RECOMMENDED_LIST_ERROR = 'Unable to recommend a run. Please click Override button to select a run';
export const LOG_ERROR_REASSIGN_BUS_CHARGING =
'Unable to reassign a bus that is currently charging to another track. Please stop charging the bus in order to proceed';
export const LOG_ERROR_INCOMING_BUS_QUEUE = 'Error in loading Incoming bus queue through api call';
export const LOG_ERROR_CUSTOMER_ASSETS = 'Error while fetching Customer Assets using api call';
export const LOG_ERROR_BUS_MAINTENANCE = 'Error while fetching bus maintenance using api call';
export const LOG_ERROR_BUS_DETAILS = 'Error while fetching bus details using api call';
export const LOG_ERROR_BUS_FAULTS = 'Error in loading Bus faults api call';
export const LOG_ERROR_CHARGER_FAULTS = 'Error in loading Charger faults api call';
export const LOG_ERROR_LCQ = 'Error in loading Logical Charge queue api call';
export const LOG_ERROR_CHARGING_STATES = 'Error in loading charging states api call';
export const LOG_ERROR_CHARGE_HISTORY = 'Error in loading charge history api call';
export const LOG_ERROR_GARAGES = 'Error in loading Garages through api call';
export const LOG_ERROR_FETCH_NOTIFICATIONS = 'Error while fetching notifications using api call';
export const LOG_ERROR_TRIGGER_NOTIFICATION = 'Error while triggering notification action using api call';
export const LOG_ERROR_FETCH_RUN_TRACK = 'Error in fetching recommended runs and tracks using api call';
export const LOG_ERROR_ASSIGN_RUN = 'Error in assigning run using api call';
export const LOG_ERROR_TRACK_ASSIGN = 'Error in assigning the track with api call';
export const LOG_ERROR_MANUAL_TRACK_ASSIGN = 'Error in assigning the track manually with api call';
export const LOG_ERROR_FETCH_TRACK_LIST = 'Error while fetching Track List using api call';
export const LOG_ERROR_NO_GARAGES = 'No Garages available for current Tenant id';
export const LOG_ERROR_FETCH_ECMS_POWER_CAPACITY = 'Error in loading ECMS power capacity api call';
export const LOG_ERROR_UPDATE_POWER_CAPACITY = 'Error in updating power capacity';
export const LOG_ERROR_UPDATE_LIMIT = 'Error in updating limit';
export const LOG_ERROR_FETCH_ECMS_POWER_MONITORING = 'Error in loading ECMS power monitoring api call';
export const LOG_ERROR_FETCH_CONFIG = 'Error in loading config api call';
export const LOG_ERROR_UPDATE_CONFIG = 'Error in updating config api call';
export const LOG_ERROR_COMMON = 'Internal server error';
export const LOG_ERROR_COMMON_SUPPORT = 'An error has occurred, please contact customer support';
export const LOG_ERROR_POWER_MONITORING = 'Something went wrong';
export const LOG_ERROR_INVALID_TENANT_GARAGE = 'Invalid tenant or garage id';
export const LOG_ERROR_NO_GARGAE_CELLS = 'Track setup response is empty';
export const LOG_ERROR_NO_NETWORK_CONNECTION = 'Please check network connectivity \n \t ERR_INTERNET_DISCONNECTED';
export const ERROR_INVALID_INTEGER = 'Please enter correct format. Decimals are not accepted.';
export const ERROR_INVALID_DECIMAL = 'Please enter a valid number.';
export const ERROR_EMPTY_FIELD = 'Please enter a value';
export const TOAST_ERROR_NO_RUN = 'Please select a run';
export const TOAST_ERROR_BUS_EXISTS = 'Bus already exists';
export const TOAST_ERROR_BUS_CHARGER_LIMIT = 'Charging Limit cannot be lower than active charging sessions';
export const TOAST_ERROR_POWER_CAPACITY_LIMIT = 'Online Power Capacity cannot be lower than total power used by active charging sessions';
export const TOAST_ERROR_NO_PARKING_POSITION = 'Please select a parking position';
export const TOAST_CONFIG_SUCCESS = 'Successfully updated';
export const TOAST_NO_GARAGE = 'You have been logged out due to the garage inaccessibility. Please contact the administrator';
export const TOAST_IMAGE_PATHS = {
  error: '../../assets/images/red_square.png',
  success: '../../assets/images/green_square.png',
};
export const TOAST_CONFIG_PAYLOAD = {
  get: (data: IToastMessage) => {
    let config: MatSnackBarConfig;
    const hPosition: MatSnackBarHorizontalPosition = 'center';
    const vPosition: MatSnackBarVerticalPosition = 'top';
    config = {
      data: { ...data },
      ...{
        duration: 5000,
        verticalPosition: vPosition,
        horizontalPosition: hPosition
      }
    };
    return config;
  }
};
export const TOAST_ERROR = 'ERROR';
export const TOAST_SUCCESS = 'SUCCESS';
export const TOAST_RUN_ID_REQUIRED_TEXT = 'A Run is required for this assignment';
export const TOAST_TRACK_ID_REQUIRED_TEXT = 'A run cannot be assigned to the bus without selecting a track';
export const TOAST_ASSIGN_BUS_SUCCESS = {
  get: (busId, trackName, trackPossition) => 'BUS ' + busId + ' has been successfully assigned to position '
    + trackName + '-' + trackPossition
};
export const TOAST_LOAD_TRACK_LIST_ERROR = {
  get: (garageName) => 'Internal Server Error, failed to retrieve Garage setup information for Garage ' + garageName
};

export const TOAST_NOTIFICATION_TRIGGER_SUCCESS = {
  get: () => 'Successfully submitted request to initiate charging for charger. Please wait until charger initiates'
};

export const TOAST_INVALID_TRACK_POSITION = 'Invalid Track No/Position';
export const TOAST_BUS_EXISTS_IN_POSITION = 'Bus already present in the selected track position';

//  Terms and Conditions
export const AGREEMENT_ERROR = 'Unknown error encountered, please refresh and try again. If the error persists please contact customer support.';

/*---------------Interfaces------------------------------------*/
export interface IToastMessage {
  type: TOASTMESSAGE_TYPE;
  message: string;
}

/*---------------UI ACTIONS------------------------------------*/
export const RECOMMEND_RUN_TRACK_ACTION = 'recommendRunTrack';
export const OVERRIDE_RUN_TRACK_ACTION = 'overrideRunTrack';
export const CHARGER_ACTIONS_ACTION = 'chargerActions';
export const ALERT_ACTIONS_ACTION = 'alertActions';
export const EMERGENCY_STOP_ALL_ACTION = 'emergencyStopAll';
export const DRAG_AND_DROP_ACTION = 'dragAndDrop';
export const EXPORT_TO_PDF_CSV_ACTION = 'exportToCSVPDF';
export const ECMS_FIELDS_ACTION = 'ECMSFields';
export const CONFIG_FIELDS_ACTION = 'configFields';
export const ENABLE = 'enable';
export const UPDATE = 'update';
export const DISPLAY = 'display';


export const GET_LOAD_GARAGES_REQUEST_ID = 'GET_LOAD_GARAGES-';
export const GET_INCOMING_BUS_QUEUE_REQUEST_ID = 'GET_INCOMING_BUS_QUEUE-';
export const GET_CUSTOMER_ASSETS_REQUEST_ID = 'GET_CUSTOMER_ASSETS-';
export const GET_BUS_DETAILS_REQUEST_ID = 'GET_BUS_DETAILS-';
export const GET_M5_BUS_STATUS_REQUEST_ID = 'GET_M5_BUS_STATUS-';

export const GET_CHARGER_HISTORY_REQUEST_ID = 'GET_CHARGER_HISTORY-';
export const GET_BUS_FAULTS_REQUEST_ID = 'BUS_FAULTS-';
export const GET_CHARGER_FAULTS_REQUEST_ID = 'GET_CHARGER_FAULTS-';
export const GET_LOGICAL_CHARGE_QUEUE_REQUEST_ID = 'GET_LOGICAL_CHARGE_QUEUE-';
export const GET_CHARGING_STATES_REQUEST_ID = 'GET_CHARGING_STATES-';

export const CHARGER_ACTION_REQUEST_ID = 'CHARGER_ACTION-';

export const GET_NOTIFICATIONS_REQUEST_ID = 'GET_NOTIFICATIONS-';
export const NOTIFICATION_ACTION_REQUEST_ID = 'NOTIFICATION_ACTION-';

export const GET_RECOMMENDED_RUNS_REQUEST_ID = 'RUNS-';
export const ASSIGN_RUN_REQUEST_ID = 'RUNS-';
export const CHANGE_PARKING_POSITION_REQUEST_ID = 'CHANGE_PARKING_POSITION-';
export const LOAD_TRACK_LIST_REQUEST_ID = 'LOAD_TRACK_LIST_REQUEST_ID';

export const GET_ECMS_POWER_CAPACITY_REQUEST_ID = 'GET_ECMS_POWER_CAPACITY-';
export const UPDATE_ECMS_POWER_CAPACITY_REQUEST_ID = 'UPDATE_ECMS_POWER_CAPACITY-';
export const UPDATE_ECMS_LIMIT_REQUEST_ID = 'UPDATE_ECMS_LIMIT-';
export const ECMS_POWER_MONITORING_REQUEST_ID = 'ECMS_POWER_MONITORING-';

export const GET_UI_TABS_REQUEST_ID = 'GET_UI_TABS-';
export const GET_CONFIGURATION = 'GET_CONFIGURATION-';
export const PUT_CONFIGURATION = 'PUT_CONFIGURATION-';

export const ASSIGNED = 'Assigned';
export const NOT_ASSIGNED = 'Not Assigned';
export const RECOMMENDED = 'Recommended';
export const NOT_APPLICABLE = 'N/A';
export const CHARGERRATE_UNITS = 'kW';
export const CHARGING = 'Charging';
export const NOT_CHARGING = 'Not Charging';
export const REASSIGN_DIALOG_TEXT = 'Are you sure you want to re-assign the bus to this run and track No.?';
export const REASSIGN_DIALOG_MAINTENANCE_TEXT = 'Are you sure you want to assign the bus to this run and track No.?';
export const CHARGER_ACTION_LOW_PRIORITY_DIALOG_TEXT = 'Are you sure you want to initiate charging for this bus? This bus is not highest priority';
export const CHARGER_ACTION_NOBUS_CHARGER_DIALOG_TEXT = 'No bus is parked at this position, are you sure you want to initiate charging for this charger? Priority for this charger cannot be determined.';
export const CHARGER_ACTION_MAINTENANCE_BUS_DIALOG_TEXT = 'The bus in the position has a status of In Maintenance. Priority for this charger cannot be determined. Please confirm you want to initiate charging for this charger?';
export const CHARGER = 'CHARGER';
export const IQ = 'IQ';
export const RUN = 'run';
export const TRACK = 'track';
export const LCQ = 'LCQ';
export const GARAGE = 'garage';
export const HEADER = 'HEADER';
export const TOKEN = 'token';
export const GARAGE_FOCUSED_HEADER = 'TRACK_HEADER_2';
export const APP_EXPIRYTIMEOFFSET = 5; // in mins
export const ECMS = 'ecms';
export const CCSS = 'ccss';
export const TENANTADMIN = 'TENANT_ADMIN';
export const SUPERADMIN = 'SUPER_ADMIN';
