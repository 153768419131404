import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GarageViewComponent } from './garage-view.component';
import { AppMaterialModule } from '../../app/material-module';
import { BrowserModule } from '@angular/platform-browser';
import { BusIdPipe } from 'src/shared-pipes/bus-id.pipe';
import { TimeInHrsPipe } from 'src/shared-pipes/time-in-hrs.pipe';
import { TrackService } from 'src/shared-services/track.service';
import { RoundNumberPipe } from 'src/shared-pipes/round-number.pipe';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';

@NgModule({
  declarations: [GarageViewComponent, BusIdPipe, RoundNumberPipe, LoginDialogComponent, TimeInHrsPipe],
  imports: [
    BrowserModule, CommonModule, AppMaterialModule
  ],
  providers: [TrackService, RoundNumberPipe],
  exports: [GarageViewComponent, CommonModule]
})
export class AppGarageViewModule { }
