<div class="garage-view-legend-block">
  <div style="margin-right:0px !important" class="row py-2 px-2 bg-black align-items-center"  [ngClass]="infoRow" >
    <div class="display-flex">
      <div class="media large">
        <img src="../../assets/images/bus-icon/green.svg">
        <div class="media-body">
          <span class="info-text">Ready/Topped Up</span>
        </div>
      </div>
      <div class="media">
        <img src="../../assets/images/bus-icon/orange.svg">
        <div class="media-body">
          <span class="info-text">Ready</span>
        </div>
      </div>
      <div class="media">
        <img src="../../assets/images/bus-icon/blue.svg">
        <div class="media-body">
          <span class="info-text">Not Ready</span>
        </div>
      </div>
      <div class="media">
        <img src="../../assets/images/bus-icon/red.svg">
        <div class="media-body">
          <span class="info-text">Delay</span>
        </div>
      </div>
      <div class="media large">
        <img src="../../assets/images/bus-icon/maint.svg">
        <div class="media-body">
          <span class="info-text">Bus in Maintenance</span>
        </div>
      </div>
      <div class="media">
        <img src="../../assets/images/bus-icon/diesel.svg">
        <div class="media-body">
          <span class="info-text">Diesel Bus</span>
        </div>
      </div>
      <div class="media small">
        <img src="../../assets/images/charger/Charging.svg">
        <div class="media-body"> <span class="info-text">Charging</span></div>
      </div>
      <div class="media">
        <img src="../../assets/images/charger/Active.svg">
        <div class="media-body"><span class="info-text">Active</span> </div>
      </div>
      <div class="media small">
        <img src="../../assets/images/charger/inactive.svg">
        <div class="media-body"><span class="info-text">In-Active</span> </div>
      </div>
      <div class="media medium">
        <img src="../../assets/images/charger/Maintenance.svg">
        <div class="media-body"><span class="info-text">Maintenance</span> </div>
      </div>
      <button mat-flat-button class="btn-emrg"  (click)='showDialog()' [disabled]="isEmergencyButtonDisabled()">Emergency Stop All</button>
    </div>
  </div>
</div>

<ng-template #emergencyStop>
  <div mat-dialog-content class="dialogBox">
    <h6 style="line-height: 30px; font-size: 20px;">Are you sure you want to 'Stop all charging sessions'?</h6>
  </div>
  <div mat-dialog-actions class="dialogBoxActions float-right">
    <button mat-stroked-button class='button-cancel' (click)='onCancel()'>Cancel</button>
    <button mat-raised-button class='button-yellow' (click)='onSubmit()' [mat-dialog-close]>Yes</button>
  </div>
</ng-template>