<div class='col-12 heading'><span>Bus Details</span></div>
<div class='item'>
    <div class='row'>
        <div class='col-3'>
            <div class='title'>Bus ID</div>
        </div>
        <div class='col-3'>
            <div class='title'>Current Energy</div>
        </div>
        <div class='col-3'>
            <div class='title'>SOC</div>
        </div>
        <div class='col-3'>
            <div class='title'>Range</div>
        </div>
    </div>
    <div class='row'>
        <div class='col-3'>
            <div class='data'>{{busdetails.busName}}</div>
        </div>
        <div class='col-3'>
            <div class='data'>{{busdetails.currentEnergy}}</div>
        </div>
        <div class='col-3'>
            <div class='data'>{{busdetails.soc}}</div>
        </div>
        <div class='col-3'>
            <div class='data'>{{busdetails.range | mileKiloMeter:'km'}}</div>
        </div>
    </div>
</div>

<div class='item'>
    <div class="row">
        <div class='col-3'>
            <div class='title'>Odometer</div>
        </div>
        <div class='col-3'>
            <div class='title'>Energy Consumption</div>
        </div>
        <div class='col-3'>
            <div class='title'>HDDTC</div>
        </div>
        <div class='col-3'>
            <div class='title'>Needs Maintenance</div>
        </div>
    </div>

    <div class="row">
        <div class='col-3'>
            <div class='data'>{{busdetails.odoMeter | mileKiloMeter:'km'}}</div>
        </div>
        <div class='col-3'>
            <div class='data'>{{busdetails.energyConsumption | kWhMileTokWhKilometer:'kWh/km'}}</div>
        </div>
        <div class='col-3'>
            <div class='data'>{{busdetails.faultsCount}}</div>
        </div>
        <div class='col-3'>
            <mat-checkbox [disabled]="enableMaintance" [checked]="this.busdetails.maintenanceRequired"
                (change)='updateMaintenace($event)'>
            </mat-checkbox>
        </div>
    </div>
</div>
<div class="row">
    <div class='col-12 bottom-button-panel'>
        <!-- <a mat-flat-button routerLink="" class='override'>Extra/Hold</a> -->
        <button mat-raised-button [disabled]="disableBtns" class='recommended-run-button button-yellow' *ngIf="displyRecommendedBtn"
            (click)='recomnendedRun()'>Recommended Run & Track</button>
        <div class='clear-both'></div>
    </div>
</div>