<div class="notifications"
    [ngClass]="{'ccss-control-view-notifications': currentPage=='/chargerControl','layout-notifications':currentPage=='/runTrackAssign'}">
    <div class="card  bg-transparent">
        <div class="card-header text-white">
            Notifications
        </div>
        <div class="card-body p-0">
            <div *ngIf='notifications'>
                <div class="tabContent" *ngFor="let item of notifications">
                    <p class="dateTime">{{item.updatedTimestamp | date:'dd MMM HH:mm'}}</p>
                    <p class="assignedToLabel" [matTooltip]='item.message'>{{item.message}}</p>
                </div>
            </div>
        </div>

    </div>

</div>