import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecommendedRunsComponent } from './recommended-runs.component';
import { MatInputModule } from '@angular/material/input';
import { AppMaterialModule } from 'src/app/material-module';
import { RunService } from 'src/shared-services/run.service';
import { RoundNumberPipe } from 'src/shared-pipes/round-number.pipe';
import { MileKiloMeterPipe } from 'src/shared-pipes/mile-kilo-meter.pipe';
import { TimeInHrsPipe } from 'src/shared-pipes/time-in-hrs.pipe';

@NgModule({
  declarations: [
    RecommendedRunsComponent,
    RoundNumberPipe,
    MileKiloMeterPipe,
    TimeInHrsPipe
  ],
  imports: [
    CommonModule,
    MatInputModule,
    AppMaterialModule
  ],
  providers: [RunService],
  exports: [
    RecommendedRunsComponent,
    CommonModule
  ]
})

export class AppRecommendedRunsModule { }
