import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, catchError, finalize } from 'rxjs/operators';
import { LoginServiceService } from '../pre-login/login/login.service';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { SharedService } from '../../shared-services/shared.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastAlertComponent } from 'src/app/toast-alert/toast-alert.component';
import { CognitoService } from 'src/app/auth/cognito.service';
import { CommsService } from '../../shared-services/comms.service';
import {
  TOAST_CONFIG_PAYLOAD,
  TOAST_ERROR,
  LOG_ERROR_COMMON,
  LOG_ERROR_COMMON_SUPPORT,
  IToastMessage,
  TOASTMESSAGE_TYPE,
  LOG_ERROR_NO_NETWORK_CONNECTION
} from '../../app-constants/app-constants';
@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {
  activeRequests: number = 0;
  constructor(
    private loginService: LoginServiceService,
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar,
    private sharedService: SharedService,
    public cognitoService: CognitoService,
    private commonService: CommsService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.activeRequests === 0) {
      this.sharedService.startLoader();
    }

    this.activeRequests++;

    return next.handle(request).pipe(
      catchError(err => {
        if (err.status === 401) {
          this.activeRequests--;
          this.cognitoService.getTokenAfterExpiredAndRefresh();
        } else if (err.status >= 500 && err.status <= 599) {
          if(err.error) {
            const error = err.error && err.error.message ? err.error.message : LOG_ERROR_COMMON_SUPPORT;
            const ERROROBJ: IToastMessage = { type: TOASTMESSAGE_TYPE.ERROR, message: error};
            this.snackBar.openFromComponent(ToastAlertComponent, TOAST_CONFIG_PAYLOAD.get(ERROROBJ));
            return throwError(error);
          } else {
            this.activeRequests--;
            // const ERROROBJ: IToastMessage = { type: TOASTMESSAGE_TYPE.ERROR, message: LOG_ERROR_COMMON};
            // this.snackBar.openFromComponent(ToastAlertComponent, TOAST_CONFIG_PAYLOAD.get(ERROROBJ));
            // return throwError(LOG_ERROR_COMMON);
          }
          
        } else if (err.status === 0) {
          if (!this.commonService.isOnline) {
            const ERROROBJ: IToastMessage = { type: TOASTMESSAGE_TYPE.ERROR, message: LOG_ERROR_NO_NETWORK_CONNECTION};
            this.snackBar.openFromComponent(ToastAlertComponent, TOAST_CONFIG_PAYLOAD.get(ERROROBJ));
            return throwError(LOG_ERROR_COMMON_SUPPORT); 
          }
          const ERROROBJ: IToastMessage = { type: TOASTMESSAGE_TYPE.ERROR, message: LOG_ERROR_COMMON_SUPPORT};
          this.snackBar.openFromComponent(ToastAlertComponent, TOAST_CONFIG_PAYLOAD.get(ERROROBJ));
          return throwError(LOG_ERROR_COMMON_SUPPORT); 
         } else  {
          const error = err.error && err.error.message ? err.error.message : LOG_ERROR_COMMON_SUPPORT;
          const ERROROBJ: IToastMessage = { type: TOASTMESSAGE_TYPE.ERROR, message: error};
          this.snackBar.openFromComponent(ToastAlertComponent, TOAST_CONFIG_PAYLOAD.get(ERROROBJ));
          return throwError(error);
        }
      }),
      finalize(() => {
        this.activeRequests--;
        if (this.activeRequests === 0) {
            this.sharedService.stopLoader();
        }
      }));
  }
}
