import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { AppMaterialModule } from '../../app/material-module';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { AppNavBarQueueModule } from '../navbar-left/navbar-left.module';
import { LimitToPipe } from 'src/shared-pipes/limit-to.pipe';

@NgModule({
  declarations: [
    HeaderComponent,
    LimitToPipe
  ],
  imports: [
    CommonModule,
    AppMaterialModule,
    MatButtonToggleModule,
    AppNavBarQueueModule
  ],
  exports: [
    HeaderComponent,
    CommonModule,
    AppNavBarQueueModule
  ]
})

export class AppHeaderModule { }
