import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk/global';
import * as awsservice from 'aws-sdk/lib/service';
import * as CognitoIdentity from 'aws-sdk/clients/cognitoidentity';
import * as jwt_decode from 'jwt-decode';
import { APP_EXPIRYTIMEOFFSET, TOKEN } from './../../app-constants/app-constants';
import { TimerService } from 'src/shared-services/timer.service';

@Injectable()
export class CognitoService {

  constructor(
    private timerService: TimerService,
  ) { }

  public static _REGION = environment.region;

  public static _IDENTITY_POOL_ID = environment.identityPoolId;
  public static _USER_POOL_ID = environment.userPoolId;
  public static _CLIENT_ID = environment.clientId;

  public static _POOL_DATA: any = {
    UserPoolId: CognitoService._USER_POOL_ID,
    ClientId: CognitoService._CLIENT_ID
  };

  public cognitoCreds: AWS.CognitoIdentityCredentials;

  getUserPool() {
    if (environment.cognito_idp_endpoint) {
      CognitoService._POOL_DATA.endpoint = environment.cognito_idp_endpoint;
    }
    return new CognitoUserPool(CognitoService._POOL_DATA);
  }

  getCurrentUser() {
    return this.getUserPool().getCurrentUser();
  }

  // AWS Stores Credentials in many ways, and with TypeScript this means that
  // getting the base credentials we authenticated with from the AWS globals gets really murky,
  // having to get around both class extension and unions. Therefore, we're going to give
  // developers direct access to the raw, unadulterated CognitoIdentityCredentials
  // object at all times.
  setCognitoCreds(creds: AWS.CognitoIdentityCredentials) {
    this.cognitoCreds = creds;
  }

  getCognitoCreds() {
    return this.cognitoCreds;
  }

  // This method takes in a raw jwtToken and uses the global AWS config options to build a
  // CognitoIdentityCredentials object and store it for us. It also returns the object to the caller
  // to avoid unnecessary calls to setCognitoCreds.

  buildCognitoCreds(idTokenJwt: string) {
    let url = 'cognito-idp.' + CognitoService._REGION.toLowerCase() + '.amazonaws.com/' + CognitoService._USER_POOL_ID;
    if (environment.cognito_idp_endpoint) {
      // url = environment.cognito_idp_endpoint 
      url = environment.cognito_idp_endpoint + '/' + CognitoService._USER_POOL_ID;
    }
    let logins: CognitoIdentity.LoginsMap = {};
    logins[url] = idTokenJwt;
    let params = {
      IdentityPoolId: CognitoService._IDENTITY_POOL_ID, /* required */
      Logins: logins
    };
    let serviceConfigs = <awsservice.ServiceConfigurationOptions>{};
    if (environment.cognito_identity_endpoint) {
      serviceConfigs.endpoint = environment.cognito_identity_endpoint;
    }
    let creds = new AWS.CognitoIdentityCredentials(params, serviceConfigs);
    this.setCognitoCreds(creds);
    return creds;
  }


  getCognitoIdentity(): string {
    return this.cognitoCreds.identityId;
  }

  getAccessToken(callback: Callback): void {
    if (callback === null) {
      throw ('CognitoUtil: callback in getAccessToken is null...returning');
    }
    if (this.getCurrentUser() !== null) {
      this.getCurrentUser().getSession(function (err, session) {
        if (err) {
          callback.callbackWithParam(null);
        }
        else {
          if (session.isValid()) {
            callback.callbackWithParam(session.getAccessToken().getJwtToken());
          }
        }
      });
    }
    else {
      callback.callbackWithParam(null);
    }
  }

  getIdToken(callback: Callback): void {
    if (callback === null) {
      throw ('CognitoUtil: callback in getIdToken is null...returning');
    }
    if (this.getCurrentUser() !== null)
      this.getCurrentUser().getSession(function (err, session) {
        if (err) {
          callback.callbackWithParam(null);
        }
        else {
          if (session.isValid()) {
            callback.callbackWithParam(session.getIdToken().getJwtToken());
          } else {
          }
        }
      });
    else
      callback.callbackWithParam(null);
  }

  getRefreshToken(callback: Callback): void {
    if (callback === null) {
      throw ('CognitoUtil: callback in getRefreshToken is null...returning');
    }
    if (this.getCurrentUser() !== null)
      this.getCurrentUser().getSession(function (err, session) {
        if (err) {
          callback.callbackWithParam(null);
        }

        else {
          if (session.isValid()) {
            callback.callbackWithParam(session.getRefreshToken());
          }
        }
      });
    else
      callback.callbackWithParam(null);
  }

  refresh(): void {
    this.getCurrentUser().getSession(function (err, session) {
      if (err) {
      }

      else {
        if (session.isValid()) {
        } else {
        }
      }
    });
  }

  checkTokenExpiryAndGetToken() {
    const EXISTEDTOKEN  = localStorage.getItem(TOKEN) ? localStorage.getItem(TOKEN) : '';
    if (EXISTEDTOKEN === '') { return; }
    try {
      const decodedToken = jwt_decode(EXISTEDTOKEN);
      const currentDT = new Date();
      const date = new Date(0);
      currentDT.setMinutes(currentDT.getMinutes() - APP_EXPIRYTIMEOFFSET);
      if (decodedToken.exp !== undefined) {
        const tokenExpdate = date.setUTCSeconds(decodedToken.exp);
        if (tokenExpdate.valueOf() < currentDT.valueOf()) {
          this.getIdTokenByRefreshToken();
        }
      }
    } catch (error) {
      console.log(error);
    }
    return localStorage.getItem(TOKEN);
  }

  getTokenAfterExpiredAndRefresh() {
    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken) {
      fetch(environment.cognito_idp_endpoint, {
        headers: {
          'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
          'Content-Type': 'application/x-amz-json-1.1',
        },
        mode: 'cors',
        cache: 'no-cache',
        method: 'POST',
        body: JSON.stringify({
          ClientId: environment.clientId,
          AuthFlow: 'REFRESH_TOKEN_AUTH',
          AuthParameters: {
            REFRESH_TOKEN: refreshToken,
          }
        }),
      }).then((res) => {
        return res.json(); // this will give jwt id and access tokens
      }).then(data => {
        if (data && data.AuthenticationResult.IdToken) {
          const IdToken = data.AuthenticationResult.IdToken;
          localStorage.setItem('token', IdToken);
          this.timerService.refreshAPIs();
        }
      });
    }
  }

  getIdTokenByRefreshToken() {
    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken) {
      fetch(environment.cognito_idp_endpoint, {
        headers: {
          'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
          'Content-Type': 'application/x-amz-json-1.1',
        },
        mode: 'cors',
        cache: 'no-cache',
        method: 'POST',
        body: JSON.stringify({
          ClientId: environment.clientId,
          AuthFlow: 'REFRESH_TOKEN_AUTH',
          AuthParameters: {
            REFRESH_TOKEN: refreshToken,
          }
        }),
      }).then((res) => {
        return res.json(); // this will give jwt id and access tokens
      }).then(data => {
        if (data && data.AuthenticationResult.IdToken) {
          const IdToken = data.AuthenticationResult.IdToken;
          localStorage.setItem('token', IdToken);
        }
      });
    }
  }
}


export interface CognitoCallback {
  cognitoCallback(message: string, result: any): void;

  handleMFAStep?(challengeName: string, challengeParameters: ChallengeParameters, callback: (confirmationCode: string) => any): void;
}

export interface LoggedInCallback {
  isLoggedIn(message: string, loggedIn: boolean): void;
}

export interface ChallengeParameters {
  CODE_DELIVERY_DELIVERY_MEDIUM: string;

  CODE_DELIVERY_DESTINATION: string;
}

export interface Callback {
  callback(): void;

  callbackWithParam(result: any): void;
}

