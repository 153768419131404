
import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewContainerRef,
  OnDestroy
} from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import * as _ from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import { emptyScheduled } from 'rxjs/internal/observable/empty';
import { TrackService } from 'src/shared-services/track.service';
import { ToastAlertComponent } from 'src/app/toast-alert/toast-alert.component';
import { MatDialog, MatDialogRef } from '@angular/material';
import { BusService } from 'src/shared-services/bus.service';
import { TimerService } from 'src/shared-services/timer.service';
import { CcssDisplayCommonService } from 'src/shared-services/ccss-display-common.service';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { EventEmitterService } from '../../shared-services/event-emitter.service';
import { ChargerService } from '../../shared-services/charger.service';

import {
  TOAST_ERROR_BUS_EXISTS,
  CHARGER_STATUS,
  CHARGER_LEGEND_STATUS,
  CHARGER_IMAGE_PATH,
  BUS_IMAGE_PATH,
  CHARGER_CLASSIFICATION,
  TOAST_CONFIG_PAYLOAD,
  CHARGER_POWERLIMIT,
  CHARGER_CURRENTLIMIT,
  CHARGER_MODEL,
  IToastMessage,
  TOASTMESSAGE_TYPE,
  BUS_COLOR_YELLOW,
  BUS_COLOR_RED,
  BUS_COLOR_MAINT,
  BUS_COLOR_ORANGE,
  BUS_COLOR_DEFAULT,
  PE_IMG_LOADING,
  HEADER,
  DRAG_AND_DROP_ACTION,
  ENABLE,
  CHARGER_ACTIONS_ACTION,
  GARAGE_FOCUSED_HEADER,
  GARAGE_OVERVIEW_ROUTE,
  RUN_TRACK_ASSIGNMENT_ROUTE,
  CHARGERRATE_UNITS,
  NOT_APPLICABLE,
  CHARGER_ACTION_NOBUS_CHARGER_DIALOG_TEXT,
  CHARGER_ACTION_MAINTENANCE_BUS_DIALOG_TEXT,
  CHARGER_ACTION_LOW_PRIORITY_DIALOG_TEXT,
  LOG_ERROR_NO_GARGAE_CELLS

} from '../../app-constants/app-constants';
import { TemplatePortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { fromEvent, Subscription } from 'rxjs';
import { take, filter } from 'rxjs/operators';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { RoundNumberPipe } from 'src/shared-pipes/round-number.pipe';
import { Router } from '@angular/router';
import { SharedService } from 'src/shared-services/shared.service';

@Component({
  selector: 'app-garage-view',
  templateUrl: './garage-view.component.html',
  styleUrls: ['./garage-view.component.scss']
})

export class GarageViewComponent implements OnInit, OnDestroy {
  @ViewChild('trackChangeConformationDialog', { static: false }) trackDialog: TemplateRef<any>;
  @ViewChild('tipContent', { static: false }) tipContentTooltip: TemplateRef<any>;
  @ViewChild('chargerMenu', { static: false }) chargerMenu: TemplateRef<any>;
  @ViewChild('ttImage', { static: false }) tipContent: NgbTooltip;
  @ViewChild('chargingConfirmationDialog', { static: false }) chargeDialog: TemplateRef<any>;
  busWithTrack: any[] = [];
  garageRows: any = [];
  dragDropBus: any = [];
  dropSelf: any = [];
  previousCharger: any;
  currentCharger: any;
  garageLegend: any = [];
  busDetailsJson: any;
  logicalChargeQueue: any;
  busImagePath: string | '../../assets/images/bus-icon/blue.svg';
  chargerImagePath: string | '';
  currBusObject: any;
  returnVal: any;
  garageObject: any = { rows: [], cols: [], cells: [] };
  @Input() public blnDragAndDropDisabled: boolean;
  @Input() public blnRightClickContextMenu: boolean;
  sub: Subscription;
  tracksList: any;
  cellHeightBinding: string = 'hCellHeight';
  overlayRef: OverlayRef | null;
  dialogRef: MatDialogRef<any, any>;
  blnChargerStatusDrop: boolean = true;
  blnChargerStatusRunning: boolean = false;
  blnProtectingDragAction: boolean = false;
  currentChargerActionsObject: any;
  currChargerImgObj: any;
  currChargerStatus: string = '';
  hasPermissionForChargerActions = false;
  hasPermissionForDragAndDrop = false;
  blnBusDragging = false;
  strChargerConfirmationText  = '';
  private chargerStatesSub: Subscription;
  strAction: string;
  getAttributeFromLogicalchargeQueue(busVIN, bKey) {
    if (this.logicalChargeQueue === null || this.logicalChargeQueue === undefined) { return ''; }
    const LCITEM = this.logicalChargeQueue.filter(item => item.busVIN === busVIN);
    if (LCITEM && LCITEM.length !== 0) {
      this.returnVal = (LCITEM[0][bKey] !== '' && LCITEM[0][bKey] !== null && LCITEM[0][bKey] !== undefined)
        ? LCITEM[0][bKey].toString().toLowerCase()
        : '';
    } else if (bKey.toLowerCase() === 'chargeclassification') {
      this.returnVal = 'blue';
    } else {
      this.returnVal = '';
    }
    return this.returnVal;
  }

  getAttributeFromBusDetailsService(busVIN, key) {
    return this.busService.getSOCByBUSVIN(busVIN, key);
  }

  getChargerInfo(ocppId, bKey) {
    return this.chargerService.getChargerInfoFromAssets(ocppId, bKey);
  }

  getColSpan(row, col) {
    const cellObj = this.getCellObject(row, col);
    this.returnVal = 0;
    if (cellObj !== null) {
      this.returnVal = (cellObj.uiColumnEnd - cellObj.uiColumnStart) + 1;
    }
    return this.returnVal;
  }

  getRowSpan(row, col) {
    const cellObj = this.getCellObject(row, col);
    this.returnVal = 0;
    if (cellObj !== null) {
      this.returnVal = (cellObj.uiRowEnd - cellObj.uiRowStart) + 1;
    }
    return this.returnVal;
  }

  getBusObject(row, col) {
    const cellObj = this.getCellObject(row, col);
    this.returnVal = null;
    if (cellObj !== null) {
      if (cellObj.bus !== undefined && cellObj.bus !== null) {
        this.currBusObject = cellObj.bus;
        this.returnVal = this.currBusObject;
      }
    }
    return this.returnVal;
  }

  getCellObject(row, col) {
    this.returnVal = null;
    const cellObj = this.garageObject.cells.filter(obj =>
      parseInt(obj.uiRowStart, 0) === parseInt(row, 0) && parseInt(obj.uiColumnStart, 0) === parseInt(col, 0));
    if (cellObj.length > 0) {
      this.returnVal = cellObj[0];
    }
    return this.returnVal;
  }

  getOCPPId(row, col) {
    this.returnVal = 'charger' + row + col;
    const cellObj = this.garageObject.cells.filter(obj =>
      parseInt(obj.uiRowStart, 0) === parseInt(row, 0) && parseInt(obj.uiColumnStart, 0) === parseInt(col, 0));
    if (cellObj.length > 0) {
      this.returnVal = cellObj[0].ocppId;
    }
    return this.returnVal;
  }

  hasRowSpan(row, col) {
    const cellObj = this.getCellObject(row, col);
    this.returnVal = false;
    if (cellObj !== null) {
      this.returnVal = cellObj.uiRowStart !== cellObj.uiRowEnd ? true : false;
    }
    return this.returnVal;
  }

  hasColSpan(row, col) {
    const cellObj = this.getCellObject(row, col);
    this.returnVal = false;
    if (cellObj !== null) {
      this.returnVal = cellObj.uiColumnStart !== cellObj.uiColumnEnd ? true : false;
    }
    return this.returnVal;
  }

  getAttributeOfGrageObject(row, col, attrName) {
    const cellObj = this.getCellObject(row, col);
    return cellObj != null ? cellObj[attrName] : '';
  }

  focusHeader(row, col): boolean {
    const cellObj = this.getCellObject(row, col);
    return cellObj == null ? false : cellObj.trackName === GARAGE_FOCUSED_HEADER && !cellObj.positionText;
  }

  isHeaderCell(row, col) {
    const cellObj = this.getCellObject(row, col);
    this.returnVal = false;
    if (cellObj !== null) {
      if (cellObj.uiTrackType  !== null && cellObj.uiTrackType.indexOf(HEADER) > -1) {
        this.returnVal = true;
      } else {
        this.returnVal = false;
      }
    }
    return this.returnVal;
  }

  isChargerInstalled(row, col) {
    const cellObj = this.getCellObject(row, col);
    this.blnChargerStatusDrop = false;
    if (cellObj !== null) {
      const dataObject = cellObj;
      if (dataObject.isChargerInstalled === true) {
        const chargerStatus = dataObject.chargerStatus;
        if (dataObject.chargerStatus === null || dataObject.chargerStatus === undefined) {
          this.chargerImagePath = CHARGER_IMAGE_PATH.inactive;
        } else if (chargerStatus.toLowerCase() === CHARGER_STATUS.active) { // active charger
          this.chargerImagePath = CHARGER_IMAGE_PATH.active;
          this.blnChargerStatusDrop = false;
        } else if (chargerStatus.toLowerCase() === CHARGER_STATUS.inactive) {
          this.chargerImagePath = CHARGER_IMAGE_PATH.inactive;
          this.blnChargerStatusDrop = false;
        } else if (chargerStatus.toLowerCase() === CHARGER_STATUS.charging) {
          this.chargerImagePath = CHARGER_IMAGE_PATH.charging;
          this.blnChargerStatusDrop = true;
        } else if (chargerStatus.toLowerCase() === CHARGER_STATUS.maintenance) {
          this.chargerImagePath = CHARGER_IMAGE_PATH.maintenance;
          this.blnChargerStatusDrop = false;
        } else if (chargerStatus.toLowerCase() === CHARGER_STATUS.disabled) {
          this.chargerImagePath = CHARGER_IMAGE_PATH.disable;
          this.blnChargerStatusDrop = false;
        }
        return true;
      }
    } else {
      this.chargerImagePath = '';
      return false;
    }
  }

  isEmptyObject(checkObj) {
    if (checkObj && checkObj.tracks) {
      return true;
    } else {
      return false;
    }
  }

  updateLogicalChargeQueue() {
    this.ccssService.getLogicalChargeQueue()
      .subscribe(res => {
        if (res.length > 0) {
          this.logicalChargeQueue = res;
        }
      });
  }

  checkBusInLogicalQueue(bus, tracktype) {
    let bcolor = BUS_COLOR_DEFAULT;
    if (tracktype === 'h') {
      this.busImagePath = BUS_IMAGE_PATH.horizontal(BUS_COLOR_DEFAULT);
      if (bus.isMaintenanceRequired) {
        this.busImagePath = BUS_IMAGE_PATH.horizontal(BUS_COLOR_MAINT);
      } else if (bus.isBookOutDelayed) {
        this.busImagePath = BUS_IMAGE_PATH.horizontal(BUS_COLOR_RED);
      } else if (this.logicalChargeQueue) {
        bcolor = this.findObjectByKey(this.logicalChargeQueue, 'busVIN', bus.busVIN, 'chargeClassification');
        if (bcolor && bcolor.toLowerCase() !== null) {
          if (bcolor.toLowerCase() === BUS_COLOR_RED) { bcolor = BUS_COLOR_DEFAULT; }
          if (bcolor.toLowerCase() === BUS_COLOR_YELLOW) { bcolor = BUS_COLOR_ORANGE; }
          this.busImagePath = BUS_IMAGE_PATH.horizontal(bcolor.toLowerCase());
          return true;
        }
      }
    } else {
      this.busImagePath = BUS_IMAGE_PATH.vertical(BUS_COLOR_DEFAULT);
      if (bus.isMaintenanceRequired) {
        this.busImagePath = BUS_IMAGE_PATH.vertical(BUS_COLOR_MAINT);
      } else if (bus.isBookOutDelayed) {
        this.busImagePath = BUS_IMAGE_PATH.vertical(BUS_COLOR_RED);
      } else if (this.logicalChargeQueue) {
        bcolor = this.findObjectByKey(this.logicalChargeQueue, 'busVIN', bus.busVIN, 'chargeClassification');
        if (bcolor && bcolor.toLowerCase() !== null) {
          if (bcolor.toLowerCase() === BUS_COLOR_RED) { bcolor = BUS_COLOR_DEFAULT; }
          if (bcolor.toLowerCase() === BUS_COLOR_YELLOW) { bcolor = BUS_COLOR_ORANGE; }
          this.busImagePath = BUS_IMAGE_PATH.vertical(bcolor.toLowerCase());
          return true;
        }
      }
    }
    return true;
  }

  findObjectByKey(array, key, value, targetKey) {
    this.returnVal = 'blue';
    for (const objItem of array) {
      if (objItem[key] === value) {
        this.returnVal = objItem[targetKey];
      }
    }
    return this.returnVal;
  }

  drop(event: CdkDragDrop<string[]>, currentCharger) {
    this.timerService.blnStopRefresh = false;
    this.blnBusDragging = false;
    const prevRowNum = event.previousContainer.element.nativeElement.getAttribute('data-row');
    const prevColNum = event.previousContainer.element.nativeElement.getAttribute('data-col');
    this.previousCharger = this.getCellObject(prevRowNum, prevColNum);
    let prevPosition; let currPosition;
    this.currentCharger = currentCharger;
    if (currentCharger && currentCharger.busVIN == null && currentCharger.bus == null) {
      prevPosition = this.previousCharger.positionInTrack + 1;
      currPosition = this.currentCharger.positionInTrack + 1;
      this.dragDropBus = {
        busID: this.previousCharger.bus,
        prevRoute: (this.previousCharger.trackName) + '-' + prevPosition,
        currRoute: (this.currentCharger.trackName) + '-' + currPosition
      };
      localStorage.setItem('dragdropbus', JSON.stringify(this.dragDropBus));
      localStorage.setItem('previousCharger', JSON.stringify(this.previousCharger));
      localStorage.setItem('currentCharger', JSON.stringify(this.currentCharger));
      this.onChangeConfirmTrack();
    } else {
      this.currentCharger = {};
      const MESSAGEOBJ: IToastMessage = { type: TOASTMESSAGE_TYPE.ERROR, message: TOAST_ERROR_BUS_EXISTS };
      this.snackBar.openFromComponent(ToastAlertComponent, TOAST_CONFIG_PAYLOAD.get(MESSAGEOBJ));
    }
    this.blnProtectingDragAction = false;
  }

  drag(event) {
    this.timerService.blnStopRefresh = true;
    this.blnProtectingDragAction = true;
    this.blnBusDragging = true;
  }

  isDragAndDropDisabled() {
    this.hasPermissionForDragAndDrop = this.sharedService.roleHasPermissionForAction(this.router.url,
      DRAG_AND_DROP_ACTION,
      ENABLE);
    if (!this.hasPermissionForDragAndDrop) {
      this.blnDragAndDropDisabled = !this.hasPermissionForDragAndDrop;
    }
    return this.blnDragAndDropDisabled;
  }

  onChangeGetAuthentication(event): void {
    const dialogRef = this.dialog.open(LoginDialogComponent, {
      width: '33.33%',
      data: {
        title: 'supervisor',
        component_name: 'garage'
      }
    });
  }

  onChangeConfirmTrack(): void {
    this.dragDropBus = localStorage.getItem('dragdropbus') && JSON.parse(localStorage.getItem('dragdropbus'));
    this.previousCharger = localStorage.getItem('previousCharger') && JSON.parse(localStorage.getItem('previousCharger'));
    this.currentCharger = localStorage.getItem('currentCharger') && JSON.parse(localStorage.getItem('currentCharger'));
    if (this.dragDropBus === undefined || this.dragDropBus === null) { return; }
    this.dialogRef = this.dialog.open(this.trackDialog);
    this.dialogRef.afterClosed().subscribe(result => {
      return result;
    });
  }

  onSubmit(): void {
    const CURRCHARGER = this.currentCharger ? JSON.parse(JSON.stringify(this.currentCharger)) : '';
    const PREVCHARGER = this.previousCharger ? JSON.parse(JSON.stringify(this.previousCharger)) : '';
    if (CURRCHARGER !== '' && PREVCHARGER !== '') {
      // save API change the track
      if (CURRCHARGER && CURRCHARGER.chargerPositionId !== null) {
        this.trackService.changeParkingPosition(PREVCHARGER, CURRCHARGER);
      }
      this.trackService.getDropSaveFlag()
        .subscribe((res: boolean) => {
          if (res === true) {
            _.forEach(this.garageObject.cells, (charger) => {
              if (charger.busVIN !== null && charger.chargerPositionId === PREVCHARGER.chargerPositionId) {
                charger.busVIN = null;
                charger.bus = null;
              } else if (charger.busVIN == null && charger.chargerPositionId === CURRCHARGER.chargerPositionId) {
                charger.busVIN = PREVCHARGER.busVIN;
                charger.bus = PREVCHARGER.bus;
              }
            });
            this.currentCharger = {};
          }
        });
    }
    this.trackService.disableBtns = false;
  }

  getChargingPriority(row, col) {
    this.returnVal = '';
    const busObj = this.getBusObject(row, col);
    if (busObj) {
      this.returnVal = this.getAttributeFromLogicalchargeQueue(busObj.busVIN, CHARGER_CLASSIFICATION);
    }
    return this.returnVal;
  }

  getMaxChargerRate(row, col) {
    this.returnVal = '';
    const cellObj = this.getCellObject(row, col);
    if (cellObj) {
      this.returnVal = this.getChargerInfo(cellObj.ocppId, CHARGER_POWERLIMIT);
    }
    this.returnVal = this.returnVal ? this.returnVal + ' ' + CHARGERRATE_UNITS : NOT_APPLICABLE;
    return this.returnVal;
  }

  getChargerRateFromChargerShadow(row, col) {
    const cellObj = this.getCellObject(row, col);
    this.returnVal = '';
    const chargerObjects = JSON.parse(localStorage.getItem('chargerStates'));
    if (cellObj && chargerObjects) {
      const currChargerObj: any = Object.values(chargerObjects).filter((obj: any) => obj.chargerName === cellObj.ocppId);
      if (currChargerObj && currChargerObj.length > 0) {
        this.returnVal = Math.round(currChargerObj[0].ocppTransactionPowerActiveImport);
      }
    }
    this.returnVal = this.returnVal ? this.returnVal + ' ' + CHARGERRATE_UNITS : NOT_APPLICABLE;
    return this.returnVal;
  }


  getChargerStationType(row, col) {
    this.returnVal = '';
    const cellObj = this.getCellObject(row, col);
    if (cellObj) {
      this.returnVal = this.getChargerInfo(cellObj.ocppId, CHARGER_MODEL);
    }
    return this.returnVal;
  }

  getChargerStatus(row, col) {
    const cellObj = this.getCellObject(row, col);
    this.returnVal = '';
    if (cellObj !== null) {
      const dataObject = cellObj;
      if (dataObject.isChargerInstalled === true) {
        const chargerStatus = dataObject.chargerStatus;
        if (dataObject.chargerStatus === null || dataObject.chargerStatus === undefined) {
          this.returnVal = CHARGER_LEGEND_STATUS.inactive;
        } else if (chargerStatus.toLowerCase() === CHARGER_STATUS.active) { // active charger
          this.returnVal = CHARGER_LEGEND_STATUS.active;
        } else if (chargerStatus.toLowerCase() === CHARGER_STATUS.inactive) {
          this.returnVal = CHARGER_LEGEND_STATUS.inactive;
        } else if (chargerStatus.toLowerCase() === CHARGER_STATUS.charging) {
          this.returnVal = CHARGER_LEGEND_STATUS.charging;
        } else if (chargerStatus.toLowerCase() === CHARGER_STATUS.maintenance) {
          this.returnVal = CHARGER_LEGEND_STATUS.maintenance;
        } else if (chargerStatus.toLowerCase() === CHARGER_STATUS.disabled) {
          this.returnVal = CHARGER_LEGEND_STATUS.disable;
        }
      }
    }
    return this.returnVal;
  }

  getRequiredEnergy(row, col) {
    let returnVal = 'N/A';
    const BUSOBJ = this.getBusObject(row, col);
    if (BUSOBJ && BUSOBJ.energyRequired !== null && BUSOBJ.energyRequired !== '') {
      returnVal = this.roundNumber.transform(BUSOBJ.energyRequired, 0) + ' kwh';
    }
    return returnVal;
  }

  getBusStatus(row, col) {
    this.returnVal = '';
    let bcolor;
    const busObj = this.getBusObject(row, col);
    if (busObj) {
      if (busObj.isMaintenanceRequired) {
        this.returnVal = this.garageLegend[0].white;
      } else if (busObj.isBookOutDelayed) {
        this.returnVal = this.garageLegend[0].red;
      } else {
        bcolor = this.getAttributeFromLogicalchargeQueue(busObj.busVIN, 'chargeClassification');
        if (bcolor.toLowerCase() === BUS_COLOR_RED) { bcolor = BUS_COLOR_DEFAULT; }
        this.returnVal = this.garageLegend[0][bcolor];
      }
    }
    return this.returnVal;
  }
  closeContextMenu() {
    this.timerService.blnStopRefresh = false;
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  stopRefresh() {
    this.timerService.blnStopRefresh = true;
  }

  startRefresh() {
    if (!this.blnBusDragging) {
      this.timerService.blnStopRefresh = false;
    }
  }



  openChargerContextMenu({ x, y }: MouseEvent, charger, row, col) {
    this.hasPermissionForChargerActions = this.sharedService.roleHasPermissionForAction(this.router.url,
      CHARGER_ACTIONS_ACTION,
      ENABLE);
    if (!this.hasPermissionForChargerActions) {
      return;
    }
    if (!this.blnRightClickContextMenu) {
      event.preventDefault();
      return;
    }
    this.currChargerImgObj = event.target;
    document.getElementsByClassName('tooltip')[0].classList.remove('show');
    this.closeContextMenu();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        }
      ]);
    this.timerService.blnStopRefresh = true;
    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRef.attach(new TemplatePortal(this.chargerMenu, this.viewContainerRef, {
      $implicit: charger
    }));
    this.currentChargerActionsObject = this.getCellObject(row, col);
    this.currChargerStatus = this.currentChargerActionsObject.chargerStatus ?
              this.currentChargerActionsObject.chargerStatus.toLowerCase() : '';
    this.sub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.closeContextMenu());

  }

  getCurrentEnergy(row, col) {
    let returnVal = 'N/A';
    const BUSOBJ = this.getBusObject(row, col);
    if (BUSOBJ && BUSOBJ.currentEnergy !== null && BUSOBJ.currentEnergy !== '') {
      returnVal = this.roundNumber.transform(BUSOBJ.currentEnergy, 0) + ' kwh';
    }
    return returnVal;
  }

  getEstimatedChargeTime(row, col) {
    let returnVal = 'N/A';
    const BUSOBJ = this.getBusObject(row, col);
    if (BUSOBJ && BUSOBJ.estimatedChargeTimeInS !== null && BUSOBJ.estimatedChargeTimeInS !== '') {
      returnVal = BUSOBJ.estimatedChargeTimeInS;
    }
    return returnVal;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  // "chargerName": null,
  // "chargerPower": null,
  getBusInformation(busName, busVIN, charger): string {
    return 'BusName:' + busName + ' \n VINNumber : ' + busVIN + '\n Charger Name : ' +
      charger.chargerName + '\n Charger Power : ' + charger.chargerPower;
  }

  constructor(
    private ccssService: CcssDisplayCommonService,
    private trackService: TrackService,
    private busService: BusService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private timerService: TimerService,
    private eventEmitterService: EventEmitterService,
    private chargerService: ChargerService,
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    private roundNumber: RoundNumberPipe,
    private sharedService: SharedService,
    private router: Router
  ) { }

  ngOnInit() {
    // if (this.eventEmitterService.subsVar === undefined) {
    //   this.eventEmitterService.subsVar = this.eventEmitterService.
    //     invokeGarageChangeTrackbusFunction.subscribe((res) => {
    //       this.onChangeConfirmTrack();
    //     });
    // }
    // this.ccssService.loadLogicalChargeQueue();
    // this.loadLogicalChargeQueue();

    if (this.router.url !== RUN_TRACK_ASSIGNMENT_ROUTE) {
      this.timerService.blnRecommendRunAndTrackInProcess = false;
    }
    this.ccssService.loadChargingStates();
    this.garageLegend.push({
      '': '', white: 'Maintenance', green: 'Ready/Topped Up',
      yellow: 'Ready', blue: 'Not Ready', red: 'Delay'
    });

    this.chargerService.changeChargerIcon()
      .subscribe(res => {
        // this.changeChargerImage(res);
      });

    this.chargerStatesSub = this.ccssService.getChargingStates()
      .subscribe(res => {
        localStorage.setItem('chargerStates', JSON.stringify(res));
      });

    this.chargerService.getChargerResponseTypeHandler()
      .subscribe(res => {
        const MESSAGEOBJ: IToastMessage =  { type : (res.type as TOASTMESSAGE_TYPE), message: res.message };
        this.snackBar.openFromComponent(ToastAlertComponent, TOAST_CONFIG_PAYLOAD.get(MESSAGEOBJ));
      });

    this.trackService.getTrackResponseTypeHandler()
      .subscribe(res => {
        const MESSAGEOBJ: IToastMessage = { type: TOASTMESSAGE_TYPE.SUCCESS, message: res.message };
        this.snackBar.openFromComponent(ToastAlertComponent, TOAST_CONFIG_PAYLOAD.get(MESSAGEOBJ));
      });

    this.trackService.getGarageBusTrackList()
      .subscribe((res: any) => {
        if (res && res.errorCode) {
          this.snackBar.open(res.errorMsg, '', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'right',
            panelClass: ['snack-bar-error']
          });
        } else {
          if (res.successMsg) {
            this.snackBar.open(res.successMsg, '', {
              duration: 2000,
              verticalPosition: 'top',
              horizontalPosition: 'right',
              panelClass: ['snack-bar-information']
            });
          } else {
            this.tracksList = res.tracks;
            /* istanbul ignore else */
            if (res.successMessage) {
              this.snackBar.open(res.successMessage, '', {
                duration: 2000,
                verticalPosition: 'top',
                horizontalPosition: 'right',
                panelClass: ['snack-bar-information']
              });
            }
            this.busDetailsJson = res;
            // this.ccssService.loadLogicalChargeQueue();
            this.updateLogicalChargeQueue();
            if (this.busDetailsJson.numberOfRows === null) { this.busDetailsJson.numberOfRows = 20; }
            if (this.busDetailsJson.numberOfColumns === null) { this.busDetailsJson.numberOfColumns = 40; }
            this.garageObject.rows = Array(this.busDetailsJson.numberOfRows).fill('').map((x, i) => i);
            this.garageObject.cols = Array(this.busDetailsJson.numberOfColumns).fill('').map((x, i) => i);
            this.garageObject.cells = [];
            let trackName = '';
            if (this.busDetailsJson.tracks) {
              for (const track of this.busDetailsJson.tracks) {
                trackName = track.trackName;
                for (const charger of track.chargerPositions) {
                  charger.trackName = trackName;
                  charger.uiTrackType = track.uiTrackType;
                  charger.orientation = track.orientation;
                  this.garageObject.cells.push(charger);
                }
              }
              localStorage.setItem('garageAllCels', JSON.stringify(this.garageObject.cells));
            }
            setTimeout(() => {
              const foucsItem = document.getElementsByClassName('verticalTrackHeader')[0];
              if (!foucsItem) {
                return;
              }
              const offsetTop = (foucsItem.parentNode as HTMLElement).offsetTop;
              if (this.router.url === GARAGE_OVERVIEW_ROUTE) {
                (document.querySelector('.garage-overview') as HTMLElement).scrollTop = offsetTop;
              } else {
                (document.querySelector('.garage') as HTMLElement).scrollTop = offsetTop;
              }
            }, 1000);
          }
        }
      });
    this.trackService.getTrackListStatus()
      .subscribe(res => {
        const MESSAGEOBJ: IToastMessage = { type: TOASTMESSAGE_TYPE.ERROR, message: res.message };
        this.snackBar.openFromComponent(ToastAlertComponent, TOAST_CONFIG_PAYLOAD.get(MESSAGEOBJ));
      });
    this.timerService.refreshEveryTwoMinute(this.trackService.loadTrackList, this.trackService);
  }

  chargerMenuClick(strAction) {
    this.strAction = strAction;
    const filteredLogicalData = [];
    const logicalData = localStorage.getItem('LogicalQueueData') ? JSON.parse(localStorage.getItem('LogicalQueueData')) : [];
    const GARAGEALLCELLS = localStorage.getItem('garageAllCels') ? JSON.parse(localStorage.getItem('garageAllCels')) : [];
    if (GARAGEALLCELLS.length === 0) {
      console.log(LOG_ERROR_NO_GARGAE_CELLS);
      return;
    }
    // Filtering positionInChargeQueue val from logicalChargeQueue Data
    const selectedChargerInLCQ = logicalData.filter(ele => {
      return (this.currentChargerActionsObject.ocppId === ele.ocppId);
    });

    if (selectedChargerInLCQ && selectedChargerInLCQ.length === 0 && strAction === 'START') {
      let message = (this.currentChargerActionsObject.bus && this.currentChargerActionsObject.bus.isMaintenanceRequired) ? CHARGER_ACTION_MAINTENANCE_BUS_DIALOG_TEXT : CHARGER_ACTION_NOBUS_CHARGER_DIALOG_TEXT;
      this.strChargerConfirmationText = message;
      this.dialogRef = this.dialog.open(this.chargeDialog);
        this.dialogRef.afterClosed().subscribe(result => {
          return result;
        });
    } else if (selectedChargerInLCQ.length !== 0 && selectedChargerInLCQ[0].positionInChargeQueue !== 0 && strAction === 'START') {
      logicalData.filter(ele => {
        if (selectedChargerInLCQ[0].positionInChargeQueue > ele.positionInChargeQueue && ele.ocppId !== null) {
          filteredLogicalData.push(ele);
        }
      });
      let blnPriorityConfirmation = false;
      // Filtering chagrStatus with TrackData
      GARAGEALLCELLS.forEach(charger => {
        filteredLogicalData.forEach(ele => {
          if (charger.chargerStatus !== null &&
            charger.chargerStatus.toLowerCase() !== CHARGER_LEGEND_STATUS.charging.toLowerCase() && ele.ocppId === charger.ocppId) {
            blnPriorityConfirmation = true;
            return;
          }
        });
      });
      if (blnPriorityConfirmation) {
        this.strChargerConfirmationText = CHARGER_ACTION_LOW_PRIORITY_DIALOG_TEXT;
        this.dialogRef = this.dialog.open(this.chargeDialog);
        this.dialogRef.afterClosed().subscribe(result => {
          return result;
        });
      } else {
        this.onSubmitOpenCharger();
      }
    } else {
      this.onSubmitOpenCharger();
    }
  }

  onSubmitOpenCharger() {
    const cellObj = this.currentChargerActionsObject;
    const chargerObjects = localStorage.getItem('chargerStates') ? JSON.parse(localStorage.getItem('chargerStates')) : [];
    if (cellObj !== null && chargerObjects !== null) {
      const currChargerObj: any = Object.values(chargerObjects).filter((obj: any) => obj.chargerName === cellObj.ocppId);
      if (currChargerObj && currChargerObj.length > 0) {
        this.chargerService.doChargerAction(this.strAction, cellObj.ocppId, cellObj.chargerPositionId, null, currChargerObj[0])
          .subscribe(res => {
            if (res && this.currentChargerActionsObject !== null) {
              this.closeContextMenu();
            }
          });
      }
    }
  }

  ngOnDestroy() {
    this.chargerStatesSub.unsubscribe();
  }
}
