<div class='row track-list-top'>
  <div class='col-3'></div>
  <div class='col-7'></div>
  <div class='col-2 text-right'>
    <button mat-stroked-button class='button-cancel' (click)='onCancel()'>Cancel</button>
    <button mat-raised-button class='button-yellow button-confirm' (click)='onChangeConfirmTrack()'>Confirm</button>
    <div class='clear-both'></div>
  </div>
</div>

<div class="garage-table">
  <ng-container *ngIf="garageObject">
    <table class="table table-bordered garageTable">
      <tbody cdkDropListGroup>
        <tr *ngFor="let row of garageObject.rows; let i = index">
          <ng-container *ngFor="let col of garageObject.cols; let j = index;">
            <ng-container *ngIf="hasRowSpan(i,j); else elseRowSpanTemplate">
                          <ng-container *ngIf="hasColSpan(i,j); else elseRowspanColSpanTemplate">
                            <td [attr.data-row]="i" [attr.data-col]="j" class="horizontalCell" [attr.rowSpan]="getRowSpan(i,j)"  [attr.colSpan]="getColSpan(i,j)">
                              <ng-container *ngIf="isHeaderCell(i,j); else elseRowSpanColSpanHeaderCellTemplate1">
                                <div class="cell-content">
                                  {{getAttributeOfGrageObject(i,j,"positionText")}}
                                </div>
                              </ng-container>
                              <ng-template #elseRowSpanColSpanHeaderCellTemplate1>
                                <div class="cell-content" (click)="onTrackSelection(getCellObject(i,j),$event)">
                                  <div [attr.data-row]="i" [attr.data-col]="j" (click)="onTrackSelection(getCellObject(i,j),$event)" class="d-flex cellHeight position-relative">
                                    <div class="" style="cursor: move;">
                                      <ng-container *ngIf="getBusObject(i,j)">
              
                                        <div class="media busInCell" container="body" placement="right" class="my-custom-class">
                                          <img *ngIf="checkBusInLogicalQueue(getBusObject(i,j),'h')" src={{busImagePath}}
                                            class="mr-2">
                                          <div class="media-body"><span class="busNameInHCell">{{currBusObject['busName']}}
                                            </span> </div>
                                        </div>
                                      </ng-container>
                                    </div>
                                    <img *cdkDragPreview src="../../assets/images/bus-large.jpg">
              
                                  </div>
                                </div>
                              </ng-template>
                            </td>
                          </ng-container>
                          <ng-template #elseRowspanColSpanTemplate>
                            <td *ngIf="getCellObject(i,j);" [attr.rowSpan]="getRowSpan(i,j)" class="horizontalCell">
                              <ng-container *ngIf="isHeaderCell(i,j); else elseColSpanHeaderCellTemplate">
                                <div class="cell-content">
                                  {{getAttributeOfGrageObject(i,j,"positionText")}}
                                </div>
                              </ng-container>
                              <ng-template #elseColSpanHeaderCellTemplate>
              
                                <div class="cell-content" (click)="onTrackSelection(getCellObject(i,j),$event)">
                                  <div [attr.data-row]="i" [attr.data-col]="j" class="d-flex cellHeight position-relative">
                                    <img *ngIf="isChargerInstalled(i,j)" src={{chargerImagePath}} class="charger" />
              
                                    <div class="" style="cursor: move;">
                                      <ng-container *ngIf="getBusObject(i,j)">
              
                                        <div class="media busInCell" container="body" class="my-custom-class">
                                          <div class="media-body"><span class="busNameInCell">{{currBusObject['busName']}}
                                            </span> </div>
              
                                          <img *ngIf="checkBusInLogicalQueue(getBusObject(i,j),'v')" style="width:0.7rem"
                                            src={{busImagePath}}>
                                        </div>
                                      </ng-container>
                                    </div>
                                    <img *cdkDragPreview src="../../assets/images/bus-large.jpg">
              
                                  </div>
                                </div>
                              </ng-template>
                            </td>
                          </ng-template>
            </ng-container>
            
            <ng-template #elseRowSpanTemplate>
                          <ng-container *ngIf="hasColSpan(i,j); else elseColSpanTemplate">
                            <td [attr.data-row]="i" [attr.data-col]="j" class="horizontalCell" [attr.colSpan]="getColSpan(i,j)">
                              <ng-container *ngIf="isHeaderCell(i,j); else elseColSpanHeaderCellTemplate1">
                                <div class="cell-content">
                                  {{getAttributeOfGrageObject(i,j,"positionText")}}
                                </div>
                              </ng-container>
                              <ng-template #elseColSpanHeaderCellTemplate1>
                                <div class="cell-content" (click)="onTrackSelection(getCellObject(i,j),$event)">
                                  <div [attr.data-row]="i" [attr.data-col]="j" class="d-flex cellHeight position-relative">
                                    <div class="" style="cursor: move;">
                                      <ng-container *ngIf="getBusObject(i,j)">
              
                                        <div class="media busInCell" container="body" placement="right" class="my-custom-class">
                                          <img *ngIf="checkBusInLogicalQueue(getBusObject(i,j),'h')" src={{busImagePath}}
                                            class="mr-2">
                                          <div class="media-body"><span class="busNameInHCell">{{currBusObject['busName']}}
                                            </span> </div>
                                        </div>
                                      </ng-container>
                                    </div>
                                    <img *cdkDragPreview src="../../assets/images/bus-large.jpg">
              
                                  </div>
                                </div>
                              </ng-template>
                            </td>
                          </ng-container>
                          <ng-template #elseColSpanTemplate>
                            <td *ngIf="getCellObject(i,j);" class="horizontalCell">
                              <ng-container *ngIf="isHeaderCell(i,j); else elseColSpanHeaderCellTemplate">
                                <div class="cell-content">
                                  {{getAttributeOfGrageObject(i,j,"positionText")}}
                                </div>
                              </ng-container>
                              <ng-template #elseColSpanHeaderCellTemplate>
              
                                <div class="cell-content" (click)="onTrackSelection(getCellObject(i,j),$event)">
                                  <div [attr.data-row]="i" [attr.data-col]="j" class="d-flex cellHeight position-relative">
                                    <img *ngIf="isChargerInstalled(i,j)" src={{chargerImagePath}} class="charger" />
              
                                    <div class="" style="cursor: move;">
                                      <ng-container *ngIf="getBusObject(i,j)">
              
                                        <div class="media busInCell" container="body" class="my-custom-class">
                                          <div class="media-body"><span class="busNameInCell">{{currBusObject['busName']}}
                                            </span> </div>
              
                                          <img *ngIf="checkBusInLogicalQueue(getBusObject(i,j),'v')" style="width:0.7rem"
                                            src={{busImagePath}}>
                                        </div>
                                      </ng-container>
                                    </div>
                                    <img *cdkDragPreview src="../../assets/images/bus-large.jpg">
              
                                  </div>
                                </div>
                              </ng-template>
                            </td>
                          </ng-template>
            </ng-template>
          </ng-container>
        </tr>
      </tbody>
    </table>
  </ng-container>
</div>
