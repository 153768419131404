import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewContainerRef,
  OnDestroy
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { TrackService } from 'src/shared-services/track.service';
import { BusService } from 'src/shared-services/bus.service';
import { RunService } from 'src/shared-services/run.service';
import { TimerService } from 'src/shared-services/timer.service';
import { CcssDisplayCommonService } from 'src/shared-services/ccss-display-common.service';
import { ToastAlertComponent } from 'src/app/toast-alert/toast-alert.component';

import {
  TRACK,
  TOAST_RUN_ID_REQUIRED_TEXT,
  TOAST_TRACK_ID_REQUIRED_TEXT,
  TOAST_ERROR,
  TOAST_CONFIG_PAYLOAD,
  IToastMessage,
  TOASTMESSAGE_TYPE,
  CHARGING,
  NOT_CHARGING,
  LOG_ERROR_REASSIGN_BUS_CHARGING,
  OVERRIDE_RUN_TRACK_ACTION,
  ENABLE,
  TOAST_INVALID_TRACK_POSITION,
  TOAST_BUS_EXISTS_IN_POSITION
} from 'src/app-constants/app-constants';
import { Router } from '@angular/router';
import { SharedService } from 'src/shared-services/shared.service';

@Component({
  selector: 'app-recommended-tracks',
  templateUrl: './recommended-tracks.component.html',
  styleUrls: ['./recommended-tracks.component.scss']
})

export class RecommendedTracksComponent implements OnInit, OnDestroy {
  @ViewChild('trackChangeConformationDialog', { static: false }) trackDialog: TemplateRef<any>;
  recommendedTrackDetails: any = {
    trackNo: '',
    trackType: '',
    position: ''
  };
  inputTrackDetails: any = {
    trackNo: '',
    position: ''
  };
  isRecommendedRun = true;
  hideOverride = true;
  hideDetails = false;
  runType = 'Recommended';
  runId = '';
  trackId = '';
  hasPermissionForOverrideTrack = false;
  private restSubscription;
  private hideOverrideSubscription;
  private maitenanceSubscription;
  dialogRef: MatDialogRef<any, any>;
  dialogDisplayText: string = '';
  reAssignBus: any = [];
  get disableBtns(): boolean {
    this.hasPermissionForOverrideTrack = this.sharedService.roleHasPermissionForAction(this.router.url,
      OVERRIDE_RUN_TRACK_ACTION,
      ENABLE);
    if (this.hasPermissionForOverrideTrack === false) {
      return true;
    }
    if (this.busService.getIsMaintenaneRequired()) {
      return this.trackService.disableBtns;
    } else {
      if (this.inputTrackDetails.trackNo === "") {
         return true;
      } else if (this.inputTrackDetails.position === "") {
        return true;
      }
    }
    return this.trackService.disableBtns;
  }

  get disableOverrideBtns(): boolean {
    this.hasPermissionForOverrideTrack = this.sharedService.roleHasPermissionForAction(this.router.url,
      OVERRIDE_RUN_TRACK_ACTION,
      ENABLE);
    if (this.hasPermissionForOverrideTrack === false) {
      return true;
    }
    return this.trackService.disableOverrideBtns;
  }

  constructor(
    public dialog: MatDialog,
    private trackService: TrackService,
    private busService: BusService,
    private runService: RunService,
    private ccssDisplayCommonService: CcssDisplayCommonService,
    private snackBar: MatSnackBar,
    private router: Router,
    private sharedService: SharedService,
    private timerService: TimerService
  ) { }

  ngOnInit() {
    this.restSubscription = this.trackService.getRecommendedTracks()
      .subscribe(res => {
        if (res) {
          this.runId = res.runNumber ? res.runNumber : 0;
          this.trackId = res.trackId ? res.trackId : '';
          this.recommendedTrackDetails = {
            trackNo: res.trackName,
            trackType: res.isChargerInstalled && res.isChargerInstalled === true ? CHARGING :
                                    (res.isChargerInstalled === false || res.isChargerInstalled === null) && res.overrideTrack
                                    ? NOT_CHARGING :
                                    (res.isChargerInstalled === false || res.isChargerInstalled === null) && res.recommended
                                    ? NOT_CHARGING :
                                    '',
            position: parseFloat(res.positionInTrack) >= 0 ? parseFloat(res.positionInTrack) + 1 : ''
          };
          this.inputTrackDetails = JSON.parse(JSON.stringify(this.recommendedTrackDetails));
          this.isRecommendedRun = res.recommended;
          this.runType = 'Recommended';
        }
      });

    this.ccssDisplayCommonService.getLogicalChargeQueueSearchBusData()
      .subscribe(res => {
        const trackData = localStorage.getItem('filterdBusData') && JSON.parse(localStorage.getItem('filterdBusData'));
        let positionInTrack = null;
        if (trackData) {
          if (trackData.hasOwnProperty('positionInTrack')) {
            if (!trackData.positionInTrack && trackData.positionInTrack !== 0) {
              positionInTrack = '';
            } else if (trackData.positionInTrack || trackData.positionInTrack === 0) {
              positionInTrack = trackData.positionInTrack + 1;
            }
          }
          this.recommendedTrackDetails = {
            trackNo: trackData.trackName ? trackData.trackName : '',
            trackType: trackData.isChargerInstalled && trackData.isChargerInstalled === true ? CHARGING :
                        trackData.isChargerInstalled === false || trackData.isChargerInstalled === null ? NOT_CHARGING : '',
            position: positionInTrack
          };
          this.hideOverride = true;
          this.runType = 'Assigned';
          this.inputTrackDetails = JSON.parse(JSON.stringify(this.recommendedTrackDetails));
        }
      });

    this.hideOverrideSubscription = this.trackService.hideTrackOverride()
      .subscribe(res => {
        this.hideOverride = res;
      });

    this.maitenanceSubscription = this.busService.getMaintenaceValue()
      .subscribe(res => {
        this.hideDetails = res;
        if (!res) {
          this.hideOverride = true;
        }
      });

    this.runService.getRunAssignStatus().subscribe(res => {
      const MESSAGEOBJ: IToastMessage = { type: TOASTMESSAGE_TYPE.SUCCESS, message: res.message};
      this.snackBar.openFromComponent(ToastAlertComponent, TOAST_CONFIG_PAYLOAD.get(MESSAGEOBJ));
    });
  }

  assignTrack() {
    let MESSAGEOBJ: IToastMessage;
    if (this.runId === '' && this.hideDetails === false) {
      MESSAGEOBJ = { type: TOASTMESSAGE_TYPE.ERROR, message: TOAST_RUN_ID_REQUIRED_TEXT};
      this.snackBar.openFromComponent(ToastAlertComponent, TOAST_CONFIG_PAYLOAD.get(MESSAGEOBJ));
    } else if (this.trackId === '' && this.hideDetails === false) {
      if(!this.isRecommendedRun) {
        this.timerService.blnRecommendRunAndTrackInProcess = false;
        this.checkForTrackAndPositionAndAssign();
      } else {
        MESSAGEOBJ = { type: TOASTMESSAGE_TYPE.ERROR, message: TOAST_TRACK_ID_REQUIRED_TEXT};
        this.snackBar.openFromComponent(ToastAlertComponent, TOAST_CONFIG_PAYLOAD.get(MESSAGEOBJ));
      }
    } else if (this.trackId !== '' && this.runId !== '') {
      this.timerService.blnRecommendRunAndTrackInProcess = false;
      this.checkForTrackAndPositionAndAssign();
    } else {
      this.timerService.blnRecommendRunAndTrackInProcess = false;
      const busCellObj = this.runService.checkBusInGarage();
      this.checkForTrackAndPositionAndAssign();
    }
  }

  checkForTrackAndPositionAndAssign() {
    let MESSAGEOBJ: IToastMessage;
    let trackNo = this.inputTrackDetails.trackNo ? this.inputTrackDetails.trackNo.toString() : '';
      let position = this.inputTrackDetails.position;
      if((trackNo === '' && position !== '') || (position === '' && trackNo !== '')) { //check for anyone field is empty and clicked assign run
        MESSAGEOBJ = { type: TOASTMESSAGE_TYPE.ERROR, message: TOAST_INVALID_TRACK_POSITION};
        this.snackBar.openFromComponent(ToastAlertComponent, TOAST_CONFIG_PAYLOAD.get(MESSAGEOBJ));
        return;
      }
      if(trackNo === '' && position === '') {
        this.runService.assignRun(this.hideDetails);
        return;
      }
      position = (!isNaN(position)) ? (parseInt(position + '') - 1) : position; // NaN is handled in validation
      let tracksData = localStorage.getItem('tracksData') ? JSON.parse(localStorage.getItem('tracksData')) : [];
      let selTrack = tracksData.tracks.map(track => track.trackName);
      let selTrackPos = selTrack.indexOf(trackNo);
      let numOfChargers = (selTrackPos !== -1) ? tracksData.tracks[selTrackPos].chargerPositions.length : 0;
      if (selTrackPos === -1) {
        MESSAGEOBJ = { type: TOASTMESSAGE_TYPE.ERROR, message: TOAST_INVALID_TRACK_POSITION};
        this.snackBar.openFromComponent(ToastAlertComponent, TOAST_CONFIG_PAYLOAD.get(MESSAGEOBJ));
      } else if (isNaN(position) || position < 0 || position >= numOfChargers) {
        MESSAGEOBJ = { type: TOASTMESSAGE_TYPE.ERROR, message: TOAST_INVALID_TRACK_POSITION};
        this.snackBar.openFromComponent(ToastAlertComponent, TOAST_CONFIG_PAYLOAD.get(MESSAGEOBJ));
      } else {
        let selChargerPosition = tracksData.tracks[selTrackPos].chargerPositions[position];
        let selectedTrack = tracksData.tracks[selTrackPos];
        let selectedTrackData = {
          trackId: selectedTrack.trackId,
          trackName: selectedTrack.trackName,
          trackPosition: selectedTrack.trackPosition,
          trackType: selChargerPosition.isChargerInstalled === true ? 'Charging' : 'Not Charging',
          chargerPositionId: selChargerPosition.chargerPositionId,
          customerPositionId: selChargerPosition.customerPositionId,
          chargerName: selChargerPosition.chargerName,
          isChargerInstalled: selChargerPosition.isChargerInstalled,
          positionInTrack: selChargerPosition.positionInTrack,
          rowStart: selChargerPosition.rowStart,
          rowEnd: selChargerPosition.rowEnd,
          columnStart: selChargerPosition.columnStart,
          columnEnd: selChargerPosition.columnEnd,
          overrideTrack: true
        };
        if (selChargerPosition.busVIN !== null) {
          selChargerPosition.trackName = tracksData.tracks[selTrackPos].trackName;
          if (this.runService.checkReAssignBusCharging(selChargerPosition)) {
            MESSAGEOBJ = { type: TOASTMESSAGE_TYPE.ERROR, message: LOG_ERROR_REASSIGN_BUS_CHARGING};
            this.snackBar.openFromComponent(ToastAlertComponent, TOAST_CONFIG_PAYLOAD.get(MESSAGEOBJ));
          } else {
            this.dialogDisplayText = this.runService.getBusReAssignDialogDisplayText(selChargerPosition);
            if (this.dialogDisplayText !== '') {
              this.dialogRef = this.dialog.open(this.trackDialog);
              this.dialogRef.afterClosed().subscribe(result => {
                return result;
              });
            } else {
              this.runService.overrideRecomendedTrack(selectedTrackData);
              this.runService.assignRun(this.hideDetails);
            }
          }
        } else {
          this.runService.overrideRecomendedTrack(selectedTrackData);
          this.runService.assignRun(this.hideDetails);
        }
      }
  }

  onSubmit(): void {
    this.runService.assignRun(this.hideDetails);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  clearAssignment() {
    this.trackService.clearAssignments();
  }

  updateBookInTime() { }

  openLoginDialog(event): void {
    this.trackService.setShowTrackList(TRACK);
    this.trackService.showTrackListData();
    this.trackService.disableBtns = true;
    this.trackService.disableOverrideBtns = true;
  }

  ngOnDestroy() {
    this.restSubscription.unsubscribe();
    this.hideOverrideSubscription.unsubscribe();
    this.maitenanceSubscription.unsubscribe();
  }
}
