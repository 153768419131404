import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { TimerService } from 'src/shared-services/timer.service';
import { HeaderService } from 'src/shared-services/header.service';
import { CognitoService } from 'src/app/auth/cognito.service';
import { TrackService } from 'src/shared-services/track.service';
import { BusService } from 'src/shared-services/bus.service';
import { LoginServiceService } from 'src/app/pre-login/login/login.service';
import { AuthService } from 'src/app/auth/auth.service';
import { RunService } from 'src/shared-services/run.service';
import {
  LOG_ERROR_NO_GARAGES,
  RUN_TRACK_ASSIGNMENT_ROUTE,
  IToastMessage,
  TOASTMESSAGE_TYPE,
  TOAST_CONFIG_PAYLOAD,
  TOAST_NO_GARAGE
} from 'src/app-constants/app-constants';
import { ToastAlertComponent } from 'src/app/toast-alert/toast-alert.component';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit, OnDestroy {
  systemTime;
  garages: any[] = [];
  resetGarage = true;
  userSelectedGarage = null;
  private headerServiceSubscription: Subscription;
  private headerAccessServiceSubscription: Subscription;
  constructor(
    private timerService: TimerService,
    private headerService: HeaderService,
    public cognitoService: CognitoService,
    private trackService: TrackService,
    private busService: BusService,
    private loginService: LoginServiceService,
    private authService: AuthService,
    public router: Router,
    private runService: RunService,
    private snackBar: MatSnackBar
  ) { }
  title = '';
  activeGarage = '';
  hideMenu = true;

  ngOnInit() {
    this.timerService.getTime()
      .subscribe(res => {
        this.systemTime = res;
      });

    this.headerService.title.subscribe(res => {
      this.title = res.title;
      this.hideMenu = res.hideMenu;
    });

    this.headerService.getSelectedGarageName()
      .subscribe(res => {
        this.activeGarage = res;
      });

    if (this.loginService.getLoggedInUserDetails()) {
      this.loginService.getUITabs();
      this.headerService.loadGarages();
    }

    this.headerService.garageChangeNotification()
      .subscribe(res => {
        this.trackService.loadTrackList();
      });
    this.timerService.refreshEveryHour(this.cognitoService.getIdTokenByRefreshToken, this.cognitoService);

    let activeGarage = '';
    this.headerService.getSelectedGarageName().subscribe(res => { activeGarage = res; });
    this.headerServiceSubscription = this.headerService.getGarages()
      .subscribe(res => {
        this.headerService.getAccessedGarages();
        this.busService.loadCustomerAssests();
        this.garages = res;
        this.resetGarage = res.resetGarage;
        this.headerAccessServiceSubscription = this.headerService.getAccessedGaragesObs().subscribe(accssedGarageresp => {
          const updatedGarages = [];
          let defaultGarageObject = {};
          if (accssedGarageresp && accssedGarageresp.accessedGarageIds.length > 0) {
            this.garages.forEach(element => {
              const accessedGarageIds: any = accssedGarageresp.accessedGarageIds;
              for (const i in accessedGarageIds) {
                if (element.trackSetupId === accessedGarageIds[i]) {
                  updatedGarages.push(element);
                }
              }
              if (element.trackSetupId === accssedGarageresp.defaultGarageId) {
                defaultGarageObject = element;
              }
            });
            this.garages = updatedGarages;
            this.userSelectedGarage = localStorage.getItem('userSelectedGarage')
              ? JSON.parse(localStorage.getItem('userSelectedGarage')) : null;
            if(this.resetGarage === true) {
              if (Object.keys(defaultGarageObject).length !== 0) {
                this.switchGarage(defaultGarageObject);
              } else {
                this.switchGarage(this.garages[0]);
              }
            } else {
              if (this.userSelectedGarage && this.garages.filter(item => item.trackSetupId === this.userSelectedGarage.trackSetupId).length !== 0) {
                this.switchGarage(this.userSelectedGarage);
              } else if (Object.keys(defaultGarageObject).length !== 0) {
                this.switchGarage(defaultGarageObject);
              } else {
                this.switchGarage(this.garages[0]);
              }
            }
          } else {
            this.garages = updatedGarages;
          }
          if (!this.garages || this.garages.length === 0) {
            localStorage.removeItem('selectedGarage');
            localStorage.removeItem('tracksData');
            localStorage.removeItem('garageAllCels');
            localStorage.removeItem('userSelectedGarage');
            localStorage.removeItem('LogicalQueueData');
            localStorage.removeItem('chargerStates');
            if (this.resetGarage === false) {
              const MESSAGEOBJ: IToastMessage = { type: TOASTMESSAGE_TYPE.ERROR, message: TOAST_NO_GARAGE };
              this.snackBar.openFromComponent(ToastAlertComponent, TOAST_CONFIG_PAYLOAD.get(MESSAGEOBJ));
              this.logout();
            }
            console.log(LOG_ERROR_NO_GARAGES);
          }
        }, err => {
          console.log(LOG_ERROR_NO_GARAGES + err);
        });
      });
      
  }

  ngOnDestroy() {
    if (this.headerServiceSubscription) {
      this.headerServiceSubscription.unsubscribe();
    }
    if(this.headerAccessServiceSubscription) {
      this.headerAccessServiceSubscription.unsubscribe();
    }
  }

  switchGarage(selectedGarage, index?) {
    this.headerService.setSelectedGarage(selectedGarage);
    localStorage.setItem('userSelectedGarage', JSON.stringify(selectedGarage));
    if (this.router.url === RUN_TRACK_ASSIGNMENT_ROUTE) {
      this.runService.clearRecomendedRunsView();
      this.trackService.clearRecomendedTrackView();
      this.trackService.setShowTrackList('garage');
      this.trackService.disableBtns = false;
    }
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['login']);
    this.headerService.setTitle('Login', true);
    this.garages = [];
  }
}