import { Component, OnInit, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';

import { TrackService } from 'src/shared-services/track.service';
import { RunService } from 'src/shared-services/run.service';
import { CcssDisplayCommonService } from 'src/shared-services/ccss-display-common.service';
import { ToastAlertComponent } from 'src/app/toast-alert/toast-alert.component';
import {
  CHARGER_STATUS,
  IToastMessage,
  TOASTMESSAGE_TYPE,
  CHARGER_IMAGE_PATH,
  BUS_IMAGE_PATH,
  BUS_COLOR_YELLOW,
  BUS_COLOR_RED,
  BUS_COLOR_MAINT,
  BUS_COLOR_ORANGE,
  BUS_COLOR_DEFAULT,
  TOAST_CONFIG_PAYLOAD,
  TOAST_ERROR_NO_PARKING_POSITION
} from '../../app-constants/app-constants';
import * as _ from 'lodash';

@Component({
  selector: 'app-bus-track-list',
  templateUrl: './bus-track-list.component.html',
  styleUrls: ['./bus-track-list.component.scss']
})

export class BusTrackListComponent implements OnInit, OnDestroy {
  @ViewChild('trackChangeDialog', { static: false }) trackDialog: TemplateRef<any>;

  private trackServiceSubscription;
  private previousChargePostion: any = null;
  selectedTrack: any = {};
  tracksList: any;
  comments = '';
  dialogRef: MatDialogRef<any, any>;
  logicalChargeQueue: any;
  busImagePath: any = '../../assets/images/bus-icon/blue.svg';
  selectedChargePostionId: any;
  selectedTrackData: any;
  maxChargePostionCount = 7;
  chargerImagePath: any = '';
  busDetailsJson: any;
  currBusObject: any;
  garageObject: any = { rows: [], cols: [], cells: [] };
  chargePositionRows = Array(this.maxChargePostionCount).fill('').map((x, i) => 'R' + (i + 1));
  showEmptyError = false;

  constructor(
    public dialog: MatDialog,
    private trackService: TrackService,
    private ccssService: CcssDisplayCommonService,
    private runService: RunService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    // this.ccssService.loadLogicalChargeQueue();

    this.ccssService.getLogicalChargeQueue()
      .subscribe(res => {
        if (res.length > 0) {
          this.logicalChargeQueue = res;
        }
      });

    this.trackServiceSubscription = this.trackService.getGarageBusTrackList()
      .subscribe(res => {
        this.busDetailsJson = res;
        this.garageObject.rows = Array(this.busDetailsJson.numberOfRows).fill('').map((x, i) => i);
        this.garageObject.cols = Array(this.busDetailsJson.numberOfColumns).fill('').map((x, i) => i);
        this.garageObject.cells = [];
        let trackName = '';
        for (const track of this.busDetailsJson.tracks) {
          trackName = track.trackName;
          for (const charger of track.chargerPositions) {
            charger.trackName = trackName;
            charger.trackId = track.trackId;
            charger.trackPosition = track.trackPosition;
            charger.trackType = track.trackType;
            this.garageObject.cells.push(charger);
          }
        }
      });
  }

  ngOnDestroy() {
    this.trackServiceSubscription.unsubscribe();
  }

  onChangeConfirmTrack() {
    this.selectedTrackData = {
      ...this.selectedTrackData,
      overrideTrack: true
    };
    this.runService.overrideRecomendedTrack(this.selectedTrackData);
    this.trackService.setShowTrackList('garage');
    this.trackService.disableBtns = false;
    this.trackService.disableOverrideBtns = false;
  }

  getRowSpan(row, col) {
    const cellObj = this.getCellObject(row, col);
    let returnVal = 0;
    if (cellObj !== null) {
      returnVal = (cellObj.uiRowEnd - cellObj.uiRowStart) + 1;
    }
    return returnVal;
  }

  hasRowSpan(row, col) {
    const cellObj = this.getCellObject(row, col);
    let returnVal = false;
    if (cellObj !== null) {
      returnVal = cellObj.uiRowStart !== cellObj.uiRowEnd ? true : false;
    }
    return returnVal;
  }

  isChargerInstalled(row, col) {
    const cellObj = this.garageObject.cells.filter(obj => obj.uiRowStart === row && obj.uiColumnStart === col);
    if (cellObj.length > 0) {
      const dataObject = cellObj[0];
      if (dataObject.isChargerInstalled === true) {
        const chargerStatus = dataObject.chargerStatus;
        if (dataObject.chargerStatus === null || dataObject.chargerStatus === undefined) {
          this.chargerImagePath = CHARGER_IMAGE_PATH.inactive;
        } else if (chargerStatus.toLowerCase() === CHARGER_STATUS.active) { // active charger
          this.chargerImagePath = CHARGER_IMAGE_PATH.active;
        } else if (chargerStatus.toLowerCase() === CHARGER_STATUS.inactive) {
          this.chargerImagePath = CHARGER_IMAGE_PATH.inactive;
        } else if (chargerStatus.toLowerCase() === CHARGER_STATUS.charging) {
          this.chargerImagePath = CHARGER_IMAGE_PATH.charging;
        } else if (chargerStatus.toLowerCase() === CHARGER_STATUS.maintenance) {
          this.chargerImagePath = CHARGER_IMAGE_PATH.maintenance;
        } else if (chargerStatus.toLowerCase() === CHARGER_STATUS.disabled) {
          this.chargerImagePath = CHARGER_IMAGE_PATH.disable;
        }
        return true;
      }
    } else {
      this.chargerImagePath = '';
      return false;
    }
  }



  checkBusInLogicalQueue(bus, tracktype) {
    let bcolor = BUS_COLOR_DEFAULT;
    if (tracktype === 'h') {
      this.busImagePath = BUS_IMAGE_PATH.horizontal(BUS_COLOR_DEFAULT);
      if (bus.isMaintenanceRequired) {
        this.busImagePath = BUS_IMAGE_PATH.horizontal(BUS_COLOR_MAINT);
      } else if (bus.isBookOutDelayed) {
        this.busImagePath = BUS_IMAGE_PATH.horizontal(BUS_COLOR_RED);
      } else if (this.logicalChargeQueue) {
        bcolor = this.findObjectByKey(this.logicalChargeQueue, 'busVIN', bus.busVIN, 'chargeClassification');
        if (bcolor && bcolor.toLowerCase() !== null) {
          if (bcolor.toLowerCase() === BUS_COLOR_RED) {
            bcolor = BUS_COLOR_DEFAULT;
          }
          if (bcolor.toLowerCase() === BUS_COLOR_YELLOW) {
            bcolor = BUS_COLOR_ORANGE;
          }
          this.busImagePath = BUS_IMAGE_PATH.horizontal(bcolor.toLowerCase());
          return true;
        }
      }
    } else {
      this.busImagePath = BUS_IMAGE_PATH.vertical(BUS_COLOR_DEFAULT);
      if (bus.isMaintenanceRequired) {
        this.busImagePath = BUS_IMAGE_PATH.vertical(BUS_COLOR_MAINT);
      } else if (bus.isBookOutDelayed) {
        this.busImagePath = BUS_IMAGE_PATH.vertical(BUS_COLOR_RED);
      } else if (this.logicalChargeQueue) {
        bcolor = this.findObjectByKey(this.logicalChargeQueue, 'busVIN', bus.busVIN, 'chargeClassification');
        if (bcolor && bcolor.toLowerCase() !== null) {
          if (bcolor.toLowerCase() === BUS_COLOR_RED) {
            bcolor = BUS_COLOR_DEFAULT;
          }
          if (bcolor.toLowerCase() === BUS_COLOR_YELLOW) {
            bcolor = BUS_COLOR_ORANGE;
          }
          this.busImagePath = BUS_IMAGE_PATH.vertical(bcolor.toLowerCase());
          return true;
        }
      }
    }
    return true;
  }

  findObjectByKey(array, key, value, targetKey) {
    for (var i = 0; i < array.length; i++) {
      if (array[i][key] === value) {
        return array[i][targetKey];
      }
    }
    return 'blue';
  }

  onCancel() {
    this.trackService.setShowTrackList('garage');
    this.trackService.disableBtns = false;
    this.trackService.disableOverrideBtns = false;
  }

  getColSpan(row, col) {
    const cellObj = this.garageObject.cells.filter(obj => obj.uiRowStart === row && obj.uiColumnStart === col);
    if (cellObj.length > 0) {
      return (cellObj[0].uiColumnEnd - cellObj[0].uiColumnStart) + 1;
    }
    return 0;
  }

  getBusObject(row, col) {
    const cellObj = this.garageObject.cells.filter(obj => obj.uiRowStart === row && obj.uiColumnStart === col);
    if (cellObj.length > 0) {
      if (cellObj[0].bus !== undefined && cellObj[0].bus !== null) {
        this.currBusObject = cellObj[0].bus;
        return this.currBusObject;
      } else {
        return null;
      }
    }
    return null;
  }

  getCellObject(row, col) {
    const cellObj = this.garageObject.cells.filter(obj => obj.uiRowStart === row && obj.uiColumnStart === col);
    if (cellObj.length > 0) {
      return cellObj[0];
    }
    return null;
  }

  getAttributeOfGrageObject(row, col, attrName) {
    const cellObj = this.garageObject.cells.filter(obj => obj.uiRowStart === row && obj.uiColumnStart === col);
    return cellObj.length > 0 ? cellObj[0][attrName] : '';
  }

  isHeaderCell(row, col) {
    const cellObj = this.garageObject.cells.filter(obj => obj.uiRowStart === row && obj.uiColumnStart === col);
    if (cellObj.length > 0) {
      if (cellObj[0].positionText === null) {
        return false;
      } else { return true; }
    } else {
      return false;
    }
  }

  hasColSpan(row, col) {
    const cellObj = this.garageObject.cells.filter(obj => obj.uiRowStart === row && obj.uiColumnStart === col);
    if (cellObj.length > 0) {
      return cellObj[0].uiColumnStart !== cellObj[0].columnEnd ? true : false;
    }
    return false;
  }

  onTrackSelection(chargePostion, event) {
    /* istanbul ignore else */
    if (!chargePostion.busVIN) {
      /* istanbul ignore else */
      if (this.previousChargePostion) {
        this.previousChargePostion.classList.remove('selected-cell-content');
        this.previousChargePostion.parentElement.parentElement.classList.remove('selected-cell-content');
        this.previousChargePostion.parentElement.classList.remove('selected-cell-content');
      }
      if (event.target.tagName.toLowerCase() === "img") {
        event.target.parentElement.parentElement.classList.add('selected-cell-content');
      } else if (event.target.className.toString().toLocaleLowerCase().indexOf('cellheight') >= 0) {
        event.target.parentElement.classList.add('selected-cell-content');
      } else {
        event.target.classList.add('selected-cell-content');
      }
      this.selectedTrackData = {
        trackId: chargePostion.trackId,
        trackName: chargePostion.trackName,
        trackPosition: chargePostion.trackPosition,
        trackType: chargePostion.isChargerInstalled === true ? 'Charging' : 'Not Charging',
        chargerPositionId: chargePostion.chargerPositionId,
        customerPositionId: chargePostion.customerPositionId,
        chargerName: chargePostion.chargerName,
        isChargerInstalled: chargePostion.isChargerInstalled,
        positionInTrack: chargePostion.positionInTrack,
        rowStart: chargePostion.rowStart,
        rowEnd: chargePostion.rowEnd,
        columnStart: chargePostion.columnStart,
        columnEnd: chargePostion.columnEnd,
      };
      this.previousChargePostion = event.target;
    }
  }
}
