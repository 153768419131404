import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CURRENT_BUILD_DATE } from 'src/app-constants/app-constants';
@Component({
  selector: 'app-navbar-left',
  templateUrl: './navbar-left.component.html',
  styleUrls: ['./navbar-left.component.scss']
})

export class NavbarLeftComponent implements OnInit, OnDestroy {
  currentBuildDate = CURRENT_BUILD_DATE;
  constructor(
    private router: Router
  ) { }

  ngOnInit() { }

  ngOnDestroy() { }
}
