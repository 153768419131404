import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarLeftComponent } from './navbar-left.component';
import { AppMaterialModule } from '../../app/material-module';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    NavbarLeftComponent
  ],
  imports: [
    AppMaterialModule,
    CommonModule,
    HttpClientModule,
    RouterModule.forRoot([])
  ],
  providers: [ ],
  exports: [
    NavbarLeftComponent,
    CommonModule
  ]
})

export class AppNavBarQueueModule { }
