import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { MatDialog } from '@angular/material';
import { RunService } from 'src/shared-services/run.service';
import { TrackService } from 'src/shared-services/track.service';
import { ToastAlertComponent } from 'src/app/toast-alert/toast-alert.component';
import {
  IToastMessage,
  TOASTMESSAGE_TYPE,
  TOAST_CONFIG_PAYLOAD,
  TOAST_ERROR_NO_RUN,
  NOT_APPLICABLE
} from 'src/app-constants/app-constants';
import * as _ from 'lodash';

export interface PeriodicElement {
  runNumber: number;
  distance: string;
  reqEnergy: string;
  estimatedChargeTimeInS: number;
  bookOutTime: string;
  bookInTime: string;
  vehicleAttributes: string;
  dates: string;
  selection: boolean;
}
/**
 * @title Table with selection
 */
@Component({
  selector: 'app-bus-run-list',
  templateUrl: './bus-run-list.component.html',
  styleUrls: ['./bus-run-list.component.scss']
})

export class BusRunListComponent implements OnInit {
  @ViewChild('runOverRideDialog', { static: false }) runDialog: TemplateRef<any>;
  @ViewChild('runOverrideConformationDialog', { static: false }) overrideConformationDialog: TemplateRef<any>;
 
   dialogRef: any;
  conformationDialogRef: any;
  conformationDialogData: any;
  recommendedRunsList: PeriodicElement[];
  filteredRunsList: PeriodicElement[];
  displayedColumns: string[] = ['runNumber','recommended', 'distance', 'reqEnergy', 'estChargeTime', 'bookOutTime', 'bookInTime', 'selection'];
  dataSource = new MatTableDataSource<PeriodicElement>();
  selection = new SelectionModel<PeriodicElement>(true, []);
  comments = '';
  loadData: any;
  selectedData = {};
  showEmptyError = false;
  pagedItems: any[];
  pager: any = {};
  hasFilterText = false;
  private restSubscription;
  constructor(
    private runService: RunService,
    public dialog: MatDialog,
    private trackService: TrackService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.restSubscription = this.runService.getRecommendedRuns()
      .subscribe(res => {
        if (res) {
          let result = [];
          this.loadData = res ? res : [];
          result  = this.loadData.map(element => {
            const obj = {
              runNumber: element.runNumber,
              distance: element.distanceInMiles,
              reqEnergy: element.energyRequired ? Math.round(element.energyRequired) + ' kWh' : '',
              estimatedChargeTimeInS: element.recommended === false ? (element.estimatedChargeTimeInS ?
                            element.estimatedChargeTimeInS : NOT_APPLICABLE) : element.estimatedChargeTimeInS,
              bookOutTime: element.bookOutTime,
              bookInTime: element.bookInTime,
              vehicleAttributes: '',
              selection: false,
              recommended : element.recommended,
              runId: element.runId
            };
            return obj;
          });
          if (result.length > 0) {
            this.recommendedRunsList = result.sort(function( a , b ) {
              return new Date(a.bookOutTime).getTime() - new Date(b.bookOutTime).getTime(); });
            this.setPage(1, false);          
          }
        }
      });
    this.runService.loadAllRuns();
  }

  applyFilter(filterValue: string) {
    this.dataSource = new MatTableDataSource<PeriodicElement>(this.recommendedRunsList);
    filterValue = filterValue.trim().toLowerCase();
    this.filteredRunsList = this.filterTableData(filterValue);
    this.hasFilterText = filterValue !== '' ? true : false;
    this.setPage(1, this.hasFilterText);
  }

  filterTableData(filterData : any) {
    let data = this.recommendedRunsList.filter(res => {
      return res.runNumber.toString().includes(filterData);
    })
    return data;
  }

  setPage(page: number, filterApplied) {
    let filterData = filterApplied ? this.filteredRunsList : this.recommendedRunsList;
    if (page < 1 || page > this.pager.totalPages) {
        return;
    }
    // get pager object from service
    if (filterData.length) {
      this.pager = this.runService.getPager(filterData.length, page);
    }

    // get current page of items
    
    this.pagedItems = filterData.slice(this.pager.startIndex, this.pager.endIndex + 1);
    this.dataSource = new MatTableDataSource<PeriodicElement>(this.pagedItems);
}

  checkChange(event, data) {
    this.conformationDialogData = [];
    if (!event.value) {
      this.conformationDialogData = data ? data : [];
    }
  }

  onChangeConfirmTrack() {
    if (this.conformationDialogData) {
      this.selectedData = this.loadData.filter(element => {
        if (element.runNumber === this.conformationDialogData.runNumber && element.runId === this.conformationDialogData.runId && element.bookOutTime === this.conformationDialogData.bookOutTime) {
          return element;
        }
      });
      this.selectedData[0] = { ...this.selectedData[0], overrideRun: true };
      this.onDoneSubmit();
    } else {
      const MESSAGEOBJ: IToastMessage = { type: TOASTMESSAGE_TYPE.ERROR, message: TOAST_ERROR_NO_RUN };
      this.snackBar.openFromComponent(ToastAlertComponent, TOAST_CONFIG_PAYLOAD.get(MESSAGEOBJ));
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  onDoneSubmit() {
    this.runService.changeRecomendedRuns(this.selectedData ? this.selectedData[0] : {});
    this.trackService.setSelectedBusTrack(this.selectedData[0]);
    this.trackService.setShowTrackList('garage');
    this.trackService.disableBtns = false;
  }

  closeRunData() {
    this.trackService.setShowTrackList('garage');
    this.trackService.disableBtns = false;
  }

  OnDestroy() {
    this.restSubscription.unsubscribe();
  }
}
