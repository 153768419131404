import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { LOGIN_AUTHENTICATION_REQUIRED, LOGIN_APPROVAL_REQUIRED } from 'src/app-constants/app-constants';
import { TrackService } from 'src/shared-services/track.service';
import { RunService } from 'src/shared-services/run.service';
import { SharedService } from 'src/shared-services/shared.service';
import { EventEmitterService } from 'src/shared-services/event-emitter.service';
import { LoginServiceService } from 'src/app/pre-login/login/login.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})

export class LoginDialogComponent implements OnInit {
  username = '';
  password = '';
  isUserLoggedIn = true;
  inValidUser = false;
  errorMessage = '';
  logindialogTittle = LOGIN_AUTHENTICATION_REQUIRED;

  constructor(
    public dialogRef: MatDialogRef<LoginDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private loginService: LoginServiceService,
    private trackService: TrackService,
    private runService: RunService,
    private authService: AuthService,
    private sharedService: SharedService,
    private eventEmitterService: EventEmitterService
  ) { }

  ngOnInit() {
    this.username = '';
    this.logindialogTittle = LOGIN_APPROVAL_REQUIRED;
  }

  onSubmit() {
    this.authService.authenticateSupervisor(this.username, this.password, this);
  }

  cognitoCallback(message, result) {
    if (!message && !!result) {
      const showValue = this.data.component_name;
      this.dialogRef.close();
      this.trackService.setShowTrackList(showValue);
      const adminUserName = result.idToken.payload['custom:userName'];
      localStorage.setItem('OverRideAdminUserName', adminUserName);
      if (showValue === 'track') {
        this.trackService.showTrackListData();
      } else if (showValue === 'run') {
        this.runService.loadAllRuns();
      } else {
        this.eventEmitterService.onGarageChangeTrackBusClick();
      }
      this.trackService.setShowTrackList(this.data.component_name);
      this.dialogRef.close();
    } else if (!result && !!message) {
      this.inValidUser = true;
      this.errorMessage = message;
      this.trackService.setShowTrackList('garage');
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  changeUser(event) {
    event.preventDefault();
    this.isUserLoggedIn = !this.isUserLoggedIn;
    this.username = '';
  }
}
