import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundNumber'
})

export class RoundNumberPipe implements PipeTransform {

  transform(value: any, noOfDigits: number): any {
    const VAL = parseFloat(value);
    return isNaN(VAL) ? null : VAL.toFixed(noOfDigits);
  }

}
