<div class="garage-table">
  <ng-container *ngIf="garageObject">
  <table class="table table-bordered garageTable">
    <tbody cdkDropListGroup>
      <tr *ngFor="let row of garageObject.rows; let i = index">
        <ng-container *ngFor="let col of garageObject.cols; let j = index;">
          <ng-container *ngIf="hasRowSpan(i,j); else elseRowSpanTemplate">
                  <ng-container *ngIf="hasColSpan(i,j); else elseRowSpanColSpanTemplate">
                    <td [attr.data-row]="i" [attr.data-col]="j" class="horizontalCell hCellHeight" [attr.rowSpan] = "getRowSpan(i,j)" [attr.colSpan]= "getColSpan(i,j)">
                      <ng-container *ngIf="isHeaderCell(i,j); else elseRowspanColSpanHeaderCellTemplate1">
                        <div class="cell-content d-flex align-items-center justify-content-center">
                          {{getAttributeOfGrageObject(i,j,"positionText")}}
                          </div>
                      </ng-container>
                      <ng-template #elseRowspanColSpanHeaderCellTemplate1>
                        <div class="cell-content ">
                          <div [attr.data-row]="i" [attr.data-col]="j"  class="d-flex cellHeight position-relative" cdkDropList
                            cdkDropConnectedTo="all"
                            (cdkDropListDropped)="drop($event ,getCellObject(i,j))">
                          
                              <div class="" cdkDrag (cdkDragStarted) = "drag($event)" (dragstart)="drag($event)" [cdkDragDisabled]="isDragAndDropDisabled()" style="cursor: move;">
                              <ng-container *ngIf="getBusObject(i,j)">
                                <div (mouseenter) = "closeContextMenu(); stopRefresh();"  (mouseleave) = "startRefresh();" [ngbTooltip]="blnProtectingDragAction ? '' : tipContentRowSpanColSpan" [placement]="['left', 'right']" container="body"  class="busInCell">
                                  <img *ngIf="checkBusInLogicalQueue(getBusObject(i,j),'h')"  src={{busImagePath}} class="mr-2" >
                                  <span class="busNameInHCell">{{currBusObject['busName']}}
                                  </span>
                                  <!-- <img src="../../assets/images/bus-icon/Untitled-3.png" class="mr-2"
                                    *ngIf="busDetailsJson.tracks[j]['chargerPositions'][i]['bus']['energyThreshold'] <  busDetailsJson.tracks[j]['chargerPositions'][i]['bus']['energyToCharge']"> -->
              
                                  
                                </div>
                              </ng-container>
                            </div>
                            <img *cdkDragPreview src="../../assets/images/bus-large.jpg">
                            <ng-template #tipContentRowSpanColSpan class="text-left">
                              <!-- Charger Details -->
                              <div *ngIf="isChargerInstalled(i,j)">
                                <div class="busData">
                                  <div class="header-text text-left">Charger Details:</div>
                                  
                                  <div class="row critical-box"
                                    *ngIf="getCellObject(i,j)['isActive']==false">
                                    <div class="col-7 text text-left">
                                      Fault details goes here in two lines</div>
                                    <div class="col-5" style="margin: auto;">
                                      <div class="btn-div">
                                        <div class="btn-text">Critical</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                                
                              
                              <div *ngIf="getBusObject(i,j)">
                                <div class="busData">
                                  <div class="header-text text-left">Bus Details:</div>
                                  <div class="row">
                                    <div class="col-7 label-info text-left">Paired Bus ID:</div>
                                    <div class="col-5 data-info text-left">
                                      {{getBusObject(i,j)['busName']}} </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-7 label-info text-left">Current Energy:</div>
                                    <div class="col-5 data-info text-left">
                                      {{getCurrentEnergy(i,j)}}
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-7 label-info text-left">Required Energy:</div>
                                    <div class="col-5 data-info text-left">
                                      {{getRequiredEnergy(i,j)}}
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-7 label-info text-left">Estimated Charge Time:</div>
                                    <div class="col-5 data-info text-left">
                                      {{getEstimatedChargeTime(i,j) | timeInHrs:'HH:mm'}}
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-7 label-info text-left">Available Battery Capacity:</div>
                                    <div class="col-5 data-info text-left">
                                      {{(getBusObject(i,j)['availableEnergy'] | roundNumber : 0 )+ ' kWh'}}
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-7 label-info text-left">SOC:</div>
                                    <div class="col-5 data-info text-left">{{getAttributeFromBusDetailsService(getBusObject(i,j)['busVIN'], "SOC")}}</div>
                                  </div>
                                  <div class="row">
                                    <div class="col-7 label-info text-left">Run Number:</div>
                                    <div class="col-5 data-info text-left">
                                      {{getBusObject(i,j)['assignedRunNumber']}}</div>
                                  </div>
                                  <div class="row">
                                    <div class="col-7 label-info text-left">Book Out Date, Time:</div>
                                    <div class="col-5 data-info text-left">
                                      {{getBusObject(i,j)['bookOutTime'] | date:'MMM dd, HH:mm'}}
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-7 label-info text-left">Charging Priority:</div>
                                    <div class="col-5 data-info text-left">{{ getChargingPriority(i,j) }}</div>
                                  </div>
                                  <!-- <div class="row">
                                    <div class="col-7 label-info text-left">Estimated Charge Start Time:</div>
                                    <div class="col-5 data-info text-left">
                                      {{getCellObject(i,j)['chargingStartTime'] | date:'HH:mm'}}
                                    </div>
                                  </div> -->
                                  <div class="row">
                                    <div class="col-7 label-info text-left">Bus Status:</div>
                                    <div class="col-5 data-info text-left">
                                      {{getBusStatus(i,j)}}
                                    </div>
                                  </div>
                                  <div class="row critical-box"
                                    *ngIf="getBusObject(i,j)['isFaulted']==false">
                                    <div class="col-7 text text-left">
                                      Fault details goes here in two lines</div>
                                    <div class="col-5" style="margin: auto;">
                                      <div class="btn-div">
                                        <div class="btn-text">Critical</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ng-template>
                          </div>
                        </div>
                      </ng-template>
                    </td>
                  </ng-container>
                  <ng-template #elseRowSpanColSpanTemplate>
                    <td *ngIf="getCellObject(i,j);" class="horizontalCell vCellHeight" [attr.rowSpan] = "getRowSpan(i,j)">
                    <ng-container *ngIf="isHeaderCell(i,j); else elseRowspanColSpanHeaderCellTemplate">
                      <div class="cell-content d-flex align-items-center justify-content-center">
                        {{getAttributeOfGrageObject(i,j,"positionText")}}
                        </div>
                    </ng-container>
                    <ng-template #elseRowspanColSpanHeaderCellTemplate>
                      <div class="cell-content">
                        <div [attr.data-row]="i" [attr.data-col]="j"  class="d-flex cellHeight position-relative" cdkDropList
                          cdkDropConnectedTo="all"
                          (cdkDropListDropped)="drop($event ,getCellObject(i,j))">
                          <img #ttImage="ngbTooltip" id={{getOCPPId(i,j)}} (mouseenter) = "closeContextMenu(); stopRefresh();" container="body"  (mouseleave) = "startRefresh();" [ngbTooltip]="blnProtectingDragAction ? '' : tipContentRowSpanColSpan1"   [placement]="['left', 'right']" (contextmenu)="openChargerContextMenu($event, charger, i , j); $event.preventDefault();" *ngIf="isChargerInstalled(i,j)"  src={{chargerImagePath}} class="charger" >
                            <div class="" cdkDrag (cdkDragStarted) = "drag($event)" (dragstart)="drag($event)" [cdkDragDisabled]="isDragAndDropDisabled() ? isDragAndDropDisabled() : blnChargerStatusDrop" style="cursor: move;">
                            <ng-container *ngIf="getBusObject(i,j)">
                              
                              <div  (mouseenter) = "closeContextMenu(); stopRefresh();" container="body"  (mouseleave) = "startRefresh();" [ngbTooltip]="blnProtectingDragAction ? '' : tipContentRowSpanColSpan1" [placement]="['left', 'right']" class="busInCell">
                                <div  class="media-body"><span class="busNameInCell">{{currBusObject['busName']}}
                                </span> </div>
            
                                <img *ngIf="checkBusInLogicalQueue(getBusObject(i,j),'v')" style="width:0.7rem" src={{busImagePath}}  >
                                <!-- <img src="../../assets/images/bus-icon/Untitled-3.png" class="mr-2"
                                  *ngIf="busDetailsJson.tracks[j]['chargerPositions'][i]['bus']['energyThreshold'] <  busDetailsJson.tracks[j]['chargerPositions'][i]['bus']['energyToCharge']"> -->
            
                                
                              </div>
                            </ng-container>
                          </div>
                          <img *cdkDragPreview src="../../assets/images/bus-large.jpg">
                          <ng-template #tipContentRowSpanColSpan1 class="text-left" >
                            <!-- Charger Details -->
                            <div *ngIf="isChargerInstalled(i,j)">
                              <div class="busData">
                                <div class="header-text text-left">Charger Details:</div>
                                <div class="row">
                                  <div class="col-7 label-info text-left">Charger Station Type:</div>
                                  <div class="col-5 data-info text-left">{{getChargerStationType(i,j)}}</div>
                                </div>
                                <div class="row">
                                  <div class="col-7 label-info text-left">Charger Rate:</div>
                                  <div class="col-5 data-info text-left">{{getChargerRateFromChargerShadow(i,j)}}</div>
                                </div>
                                <div class="row">
                                  <div class="col-7 label-info text-left">Maximum Charger Rate:</div>
                                  <div class="col-5 data-info text-left">{{getMaxChargerRate(i,j)}}</div>
                                </div>
                                <div class="row">
                                  <div class="col-7 label-info text-left">Charger Status:</div>
                                  <div class="col-5 data-info text-left">
                                    {{getChargerStatus(i,j)}}
                                  </div>
                                </div>
                                <!-- <div class="row critical-box"
                                  *ngIf="getCellObject(i,j)['isActive']==false">
                                  <div class="col-7 text text-left">
                                    Fault details goes here in two lines</div>
                                  <div class="col-5" style="margin: auto;">
                                    <div class="btn-div">
                                      <div class="btn-text">Critical</div>
                                    </div>
                                  </div>
                                </div> -->
                              </div>
                            </div>
                            <div *ngIf="getBusObject(i,j)">
                              <div class="busData">
                                <div class="header-text text-left">Bus Details:</div>
                                <div class="row">
                                  <div class="col-7 label-info text-left">Paired Bus ID:</div>
                                  <div class="col-5 data-info text-left">
                                    {{getBusObject(i,j)['busName']}} </div>
                                </div>
                                <div class="row">
                                  <div class="col-7 label-info text-left">Current Energy:</div>
                                  <div class="col-5 data-info text-left">
                                    {{getCurrentEnergy(i,j)}}
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-7 label-info text-left">Required Energy:</div>
                                  <div class="col-5 data-info text-left">
                                    {{getRequiredEnergy(i,j)}}
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-7 label-info text-left">Estimated Charge Time:</div>
                                  <div class="col-5 data-info text-left">
                                    {{getEstimatedChargeTime(i,j) | timeInHrs:'HH:mm'}}
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-7 label-info text-left">Available Battery Capacity:</div>
                                  <div class="col-5 data-info text-left">
                                    {{(getBusObject(i,j)['availableEnergy'] | roundNumber : 0 )+ ' kWh'}}
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-7 label-info text-left">SOC:</div>
                                  <div class="col-5 data-info text-left">{{getAttributeFromBusDetailsService(getBusObject(i,j)['busVIN'], "SOC")}}</div>
                                </div>
                                <div class="row">
                                  <div class="col-7 label-info text-left">Run Number:</div>
                                  <div class="col-5 data-info text-left">
                                    {{getBusObject(i,j)['assignedRunNumber']}}</div>
                                </div>
                                <div class="row">
                                  <div class="col-7 label-info text-left">Book Out Date, Time:</div>
                                  <div class="col-5 data-info text-left">
                                    {{getBusObject(i,j)['bookOutTime'] | date:'MMM dd, HH:mm'}}
                                  </div>
                                </div>
                                
                                <!-- <div class="row">
                                  <div class="col-7 label-info text-left">Estimated Charge Start Time:</div>
                                  <div class="col-5 data-info text-left">
                                    {{getCellObject(i,j)['chargingStartTime'] | date:'HH:mm'}}
                                  </div>
                                </div> -->
                                <div class="row">
                                  <div class="col-7 label-info text-left">Charging Priority:</div>
                                  <div class="col-5 data-info text-left">{{ getChargingPriority(i,j) }}</div>
                                </div>
                                <div class="row">
                                  <div class="col-7 label-info text-left">Bus Status:</div>
                                  <div class="col-5 data-info text-left">
                                    {{getBusStatus(i,j)}}
                                  </div>
                                </div>
                                <div class="row critical-box"
                                  *ngIf="getBusObject(i,j)['isFaulted']==false">
                                  <div class="col-7 text text-left">
                                    Fault details goes here in two lines</div>
                                  <div class="col-5" style="margin: auto;">
                                    <div class="btn-div">
                                      <div class="btn-text">Critical</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </div>
                      </div>
                    </ng-template>
                    </td>
                  </ng-template>
          </ng-container>
          <ng-template #elseRowSpanTemplate>
              <ng-container *ngIf="hasColSpan(i,j); else elseColSpanTemplate1">
                  <ng-container *ngIf="isHeaderCell(i,j); else elseColSpanHeaderCellTemplate1">
                    <td [attr.data-row]="i" [attr.data-col]="j" class="horizontalCell hCellHeight" [attr.colSpan]= "getColSpan(i,j)">
                      <div class="cell-content d-flex align-items-center justify-content-center">
                        {{getAttributeOfGrageObject(i,j,"positionText")}}
                        </div>
                    </td>
                  </ng-container>
                  <ng-template #elseColSpanHeaderCellTemplate1>
                    <td [attr.data-row]="i" [attr.data-col]="j" class="horizontalCell hCellHeight" [attr.colSpan]= "getColSpan(i,j)">
                      <div class="cell-content ">
                        <div [attr.data-row]="i" [attr.data-col]="j"  class="d-flex cellHeight position-relative" cdkDropList
                          cdkDropConnectedTo="all"
                          (cdkDropListDropped)="drop($event ,getCellObject(i,j))">
                        
                            <div class="" cdkDrag (cdkDragStarted) = "drag($event)" (dragstart)="drag($event)" [cdkDragDisabled]="isDragAndDropDisabled()" style="cursor: move;">
                            <ng-container *ngIf="getBusObject(i,j)">
                              <div  (mouseenter) = "closeContextMenu(); stopRefresh();"  (mouseleave) = "startRefresh();" [ngbTooltip]="blnProtectingDragAction ? '' : tipContentColSpan" [placement]="['left', 'right']" container="body"  class="busInCell">
                                <img *ngIf="checkBusInLogicalQueue(getBusObject(i,j),'h')"  src={{busImagePath}} class="mr-2" >
                                <span class="busNameInHCell">{{currBusObject['busName']}}
                                </span>
                                <!-- <img src="../../assets/images/bus-icon/Untitled-3.png" class="mr-2"
                                  *ngIf="busDetailsJson.tracks[j]['chargerPositions'][i]['bus']['energyThreshold'] <  busDetailsJson.tracks[j]['chargerPositions'][i]['bus']['energyToCharge']"> -->
            
                                
                              </div>
                            </ng-container>
                          </div>
                          <img *cdkDragPreview src="../../assets/images/bus-large.jpg">
                          <ng-template #tipContentColSpan class="text-left">
                            <!-- Charger Details -->
                            <div *ngIf="isChargerInstalled(i,j)">
                              <div class="busData">
                                <div class="header-text text-left">Charger Details:</div>
                                
                                <div class="row critical-box"
                                  *ngIf="getCellObject(i,j)['isActive']==false">
                                  <div class="col-7 text text-left">
                                    Fault details goes here in two lines</div>
                                  <div class="col-5" style="margin: auto;">
                                    <div class="btn-div">
                                      <div class="btn-text">Critical</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                              
                            
                            <div *ngIf="getBusObject(i,j)">
                              <div class="busData">
                                <div class="header-text text-left">Bus Details:</div>
                                <div class="row">
                                  <div class="col-7 label-info text-left">Paired Bus ID:</div>
                                  <div class="col-5 data-info text-left">
                                    {{getBusObject(i,j)['busName']}} </div>
                                </div>
                                <div class="row">
                                  <div class="col-7 label-info text-left">Current Energy:</div>
                                  <div class="col-5 data-info text-left">
                                    {{getCurrentEnergy(i,j)}}
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-7 label-info text-left">Required Energy:</div>
                                  <div class="col-5 data-info text-left">
                                    {{getRequiredEnergy(i,j)}}
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-7 label-info text-left">Estimated Charge Time:</div>
                                  <div class="col-5 data-info text-left">
                                    {{getEstimatedChargeTime(i,j) | timeInHrs:'HH:mm'}}
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-7 label-info text-left">Available Battery Capacity:</div>
                                  <div class="col-5 data-info text-left">
                                    {{(getBusObject(i,j)['availableEnergy'] | roundNumber : 0 )+ ' kWh'}}
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-7 label-info text-left">SOC:</div>
                                  <div class="col-5 data-info text-left">{{getAttributeFromBusDetailsService(getBusObject(i,j)['busVIN'], "SOC")}}</div>
                                </div>
                                <div class="row">
                                  <div class="col-7 label-info text-left">Run Number:</div>
                                  <div class="col-5 data-info text-left">
                                    {{getBusObject(i,j)['assignedRunNumber']}}</div>
                                </div>
                                <div class="row">
                                  <div class="col-7 label-info text-left">Book Out Date, Time:</div>
                                  <div class="col-5 data-info text-left">
                                    {{getBusObject(i,j)['bookOutTime'] | date:'MMM dd, HH:mm'}}
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-7 label-info text-left">Charging Priority:</div>
                                  <div class="col-5 data-info text-left">{{ getChargingPriority(i,j) }}</div>
                                </div>
                                <!-- <div class="row">
                                  <div class="col-7 label-info text-left">Estimated Charge Start Time:</div>
                                  <div class="col-5 data-info text-left">
                                    {{getCellObject(i,j)['chargingStartTime'] | date:'HH:mm'}}
                                  </div>
                                </div> -->
                                <div class="row">
                                  <div class="col-7 label-info text-left">Bus Status:</div>
                                  <div class="col-5 data-info text-left">
                                    {{getBusStatus(i,j)}}
                                  </div>
                                </div>
                                <div class="row critical-box"
                                  *ngIf="getBusObject(i,j)['isFaulted']==false">
                                  <div class="col-7 text text-left">
                                    Fault details goes here in two lines</div>
                                  <div class="col-5" style="margin: auto;">
                                    <div class="btn-div">
                                      <div class="btn-text">Critical</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </div>
                      </div>
                    </td>
                  </ng-template>
                
              </ng-container>
              <ng-template #elseColSpanTemplate1>
                <ng-container *ngIf="isHeaderCell(i,j); else elseColSpanHeaderCellTemplate">
                  <td *ngIf="getCellObject(i,j);" id="{{'cell' + i+j}}" [ngClass]="{'verticalTrackHeader': focusHeader(i, j)}" class='hCellHeight'>
                    <div class="cell-content d-flex align-items-center justify-content-center">
                      {{getAttributeOfGrageObject(i,j,"positionText")}}
                      </div>
                    </td>
                </ng-container>
                <ng-template #elseColSpanHeaderCellTemplate>
                  <td *ngIf="getCellObject(i,j);" id="{{'cell' + i+j}}" class='vCellHeight'>
                    <div class="cell-content">
                      <div [attr.data-row]="i" [attr.data-col]="j"  class="d-flex cellHeight position-relative" cdkDropList
                        cdkDropConnectedTo="all"
                        (cdkDropListDropped)="drop($event ,getCellObject(i,j))" >
                        <div  style="height:20%"><img #ttImage="ngbTooltip" id={{getOCPPId(i,j)}} (mouseenter) = "closeContextMenu();stopRefresh();" (mouseleave) = "startRefresh();" container="body" [ngbTooltip]="blnProtectingDragAction ? '' : tipContentColSpan1"   [placement]="['left', 'right']" (contextmenu)="openChargerContextMenu($event, charger, i , j); $event.preventDefault();" *ngIf="isChargerInstalled(i,j)"  src={{chargerImagePath}} class="charger align-items-center justify-content-center" ></div>
                          <div class=""  cdkDrag  (cdkDragStarted) = "drag($event)" (dragstart)="drag($event)" [cdkDragDisabled]="isDragAndDropDisabled() ? isDragAndDropDisabled() : blnChargerStatusDrop" style="cursor: move;">
                          <ng-container *ngIf="getBusObject(i,j)">
                            
                            <div  (mouseenter) = "closeContextMenu(); stopRefresh();"  (mouseleave) = "startRefresh();" container="body" [ngbTooltip]="blnProtectingDragAction ? '' : tipContentColSpan1" [placement]="['left', 'right']" class="busInCell">
                              <span class="busNameInCell">{{currBusObject['busName']}}
                              </span>
          
                              <img class="busImageInCell" *ngIf="checkBusInLogicalQueue(getBusObject(i,j),'v')" src={{busImagePath}}  >
                              <!-- <img src="../../assets/images/bus-icon/Untitled-3.png" class="mr-2"
                                *ngIf="busDetailsJson.tracks[j]['chargerPositions'][i]['bus']['energyThreshold'] <  busDetailsJson.tracks[j]['chargerPositions'][i]['bus']['energyToCharge']"> -->
          
                              
                            </div>
                          </ng-container>
                        </div>
                        <img *cdkDragPreview src="../../assets/images/bus-large.jpg">
                        <ng-template #tipContentColSpan1 class="text-left" >
                          <!-- Charger Details -->
                          <div *ngIf="isChargerInstalled(i,j)">
                            <div class="busData">
                              <div class="header-text text-left">Charger Details:</div>
                              <div class="row">
                                <div class="col-7 label-info text-left">Charger Station Type:</div>
                                <div class="col-5 data-info text-left">{{getChargerStationType(i,j)}}</div>
                              </div>
                              <div class="row">
                                <div class="col-7 label-info text-left">Charger Rate:</div>
                                <div class="col-5 data-info text-left">{{getChargerRateFromChargerShadow(i,j)}}</div>
                              </div>
                              <div class="row">
                                <div class="col-7 label-info text-left">Maximum Charger Rate:</div>
                                <div class="col-5 data-info text-left">{{getMaxChargerRate(i,j)}}</div>
                              </div>
                              <div class="row">
                                <div class="col-7 label-info text-left">Charger Status:</div>
                                <div class="col-5 data-info text-left">
                                  {{getChargerStatus(i,j)}}
                                </div>
                              </div>
                              <!-- <div class="row critical-box"
                                *ngIf="getCellObject(i,j)['isActive']==false">
                                <div class="col-7 text text-left">
                                  Fault details goes here in two lines</div>
                                <div class="col-5" style="margin: auto;">
                                  <div class="btn-div">
                                    <div class="btn-text">Critical</div>
                                  </div>
                                </div>
                              </div> -->
                            </div>
                          </div>
                          <div *ngIf="getBusObject(i,j)">
                            <div class="busData">
                              <div class="header-text text-left">Bus Details:</div>
                              <div class="row">
                                <div class="col-7 label-info text-left">Paired Bus ID:</div>
                                <div class="col-5 data-info text-left">
                                  {{getBusObject(i,j)['busName']}} </div>
                              </div>
                              <div class="row">
                                <div class="col-7 label-info text-left">Current Energy:</div>
                                <div class="col-5 data-info text-left">
                                  {{getCurrentEnergy(i,j)}}
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-7 label-info text-left">Required Energy:</div>
                                <div class="col-5 data-info text-left">
                                  {{getRequiredEnergy(i,j)}}
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-7 label-info text-left">Estimated Charge Time:</div>
                                <div class="col-5 data-info text-left">
                                  {{getEstimatedChargeTime(i,j) | timeInHrs:'HH:mm'}}
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-7 label-info text-left">Available Battery Capacity:</div>
                                <div class="col-5 data-info text-left">
                                  {{(getBusObject(i,j)['availableEnergy'] | roundNumber : 0 )+ ' kWh'}}
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-7 label-info text-left">SOC:</div>
                                <div class="col-5 data-info text-left">{{getAttributeFromBusDetailsService(getBusObject(i,j)['busVIN'], "SOC")}}</div>
                              </div>
                              <div class="row">
                                <div class="col-7 label-info text-left">Run Number:</div>
                                <div class="col-5 data-info text-left">
                                  {{getBusObject(i,j)['assignedRunNumber']}}</div>
                              </div>
                              <div class="row">
                                <div class="col-7 label-info text-left">Book Out Date, Time:</div>
                                <div class="col-5 data-info text-left">
                                  {{getBusObject(i,j)['bookOutTime'] | date:'MMM dd, HH:mm'}}
                                </div>
                              </div>
                              
                              <!-- <div class="row">
                                <div class="col-7 label-info text-left">Estimated Charge Start Time:</div>
                                <div class="col-5 data-info text-left">
                                  {{getCellObject(i,j)['chargingStartTime'] | date:'HH:mm'}}
                                </div>
                              </div> -->
                              <div class="row">
                                <div class="col-7 label-info text-left">Charging Priority:</div>
                                <div class="col-5 data-info text-left">{{ getChargingPriority(i,j) }}</div>
                              </div>
                              <div class="row">
                                <div class="col-7 label-info text-left">Bus Status:</div>
                                <div class="col-5 data-info text-left">
                                  {{getBusStatus(i,j)}}
                                </div>
                              </div>
                              <div class="row critical-box"
                                *ngIf="getBusObject(i,j)['isFaulted']==false">
                                <div class="col-7 text text-left">
                                  Fault details goes here in two lines</div>
                                <div class="col-5" style="margin: auto;">
                                  <div class="btn-div">
                                    <div class="btn-text">Critical</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </td>
                </ng-template>
                
              </ng-template>
          </ng-template>
        </ng-container>
      </tr>
      
    </tbody>
  </table>
</ng-container>
</div>

<ng-template #trackChangeConformationDialog>
  <div mat-dialog-content class="dialogBox">
    <h6 style="line-height: 30px; font-size: 20px;">Are you sure you want to move Bus {{dragDropBus.busID.busName}} from position {{dragDropBus.prevRoute}} to
      {{dragDropBus.currRoute}}?</h6>
  </div>
  <div mat-dialog-actions class="dialogBoxActions float-right">
    <button mat-stroked-button class='button-cancel' (click)='onCancel()'>Cancel</button>
    <button mat-raised-button class='button-yellow' (click)='onSubmit()' [mat-dialog-close]>Yes</button>
  </div>
</ng-template>



<ng-template class='text-left' #chargerMenu>
  <div class='arrow'></div>
  <div class='contentmenu-inner contextData'>
    <button mat-raised-button (click)='chargerMenuClick("START")' [disabled]="currChargerStatus !== 'active'"  class='label-info'>Initiate Charging</button>
    <div class='vertical-line'></div>
    <button mat-raised-button (click)='chargerMenuClick("STOP")' [disabled]="currChargerStatus !== 'charging'" class='label-info'>Terminate Charging</button>
    <div class='vertical-line'></div>
    <button mat-raised-button (click)='chargerMenuClick("SOFT_RESET")' [disabled]="currChargerStatus === 'in-active'" class='label-info'>Reset</button>
  </div>
</ng-template>

<ng-template #chargingConfirmationDialog>
  <div mat-dialog-content class="dialogBox">
    <h6 style="line-height: 30px; font-size: 20px;">{{strChargerConfirmationText}}
    </h6>
  </div>
  <div mat-dialog-actions class="dialogBoxActions float-right">
    <button mat-stroked-button class='button-cancel' (click)='onCancel()'>Cancel</button>
    <button mat-raised-button class='button-yellow' (click)='onSubmitOpenCharger()' [mat-dialog-close]>Yes</button>
  </div>
</ng-template>
