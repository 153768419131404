import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Subject } from 'rxjs';
import { RestService } from './rest.service';
import { SharedService } from './shared.service';
import {
  PE_API_GARAGE_LIST,
  LOG_ERROR_GARAGES,
  GET_LOAD_GARAGES_REQUEST_ID,
  IToastMessage,
  TOASTMESSAGE_TYPE,
  TOAST_CONFIG_PAYLOAD,
  PE_API_CurrentUser,
  PE_API_CurrentAdmin,
  TENANTADMIN,
  SUPERADMIN
} from 'src/app-constants/app-constants';
import { ExceptionService } from './exception.service';
import { ToastAlertComponent } from 'src/app/toast-alert/toast-alert.component';
import { MatSnackBar } from '@angular/material';

@Injectable()

export class HeaderService {
  public title = new BehaviorSubject({ title: '', hideMenu: false });
  private garages$ = new Subject<any>();
  private accessedGarages$ = new Subject<any>();
  private garages: any;
  public selectedGarage: any;
  private selectedGarageName$ = new Subject<string>();
  private garageChange$: any = new Subject<any>();

  constructor(
    private restService: RestService,
    private sharedService: SharedService,
    private expectionService: ExceptionService,
    private snackBar: MatSnackBar
  ) { }

  setTitle(titleVal, hideMenuVal = false) {
    this.title.next({ title: titleVal, hideMenu: hideMenuVal });
  }

  setSelectedGarageName(name) {
    this.selectedGarageName$.next(name);
  }

  loadGarages(resetGarage: boolean = true) {
    const TenantId = this.getTenantId();
    if (TenantId === null || TenantId === '') { return; }
    const reqObj = {
      url: PE_API_GARAGE_LIST.get(TenantId),
      options: {
        params: {
          requestId: this.sharedService.create_UUID(GET_LOAD_GARAGES_REQUEST_ID),
          tenantId: TenantId
        }
      }
    };

    this.restService.get(reqObj)
      .subscribe(res => {
        this.garages = res;
        if (this.garages instanceof Array) {
          if (this.garages && this.garages.length > 0) {
            // if (resetGarage) {
            //   this.setInitialGarage(this.garages[0]);
            // } else if (!resetGarage && this.selectedGarage) {
            //   const garageData = _.filter(this.garages, garage => {
            //     return garage.customerGarageId === this.selectedGarage.customerGarageId;
            //   });
            //   this.setInitialGarage(garageData[0]);
            // }
          } else {
            const MESSAGEOBJ: IToastMessage = { type: TOASTMESSAGE_TYPE.ERROR, message: LOG_ERROR_GARAGES };
            this.snackBar.openFromComponent(ToastAlertComponent, TOAST_CONFIG_PAYLOAD.get(MESSAGEOBJ));
          }
        }
        res['resetGarage'] = resetGarage;
        this.garages$.next(Object.assign({}, { data: res }).data);
      }, err => {
        this.expectionService.log(LOG_ERROR_GARAGES, err);
      });
  }

  getGarages() {
    return this.garages$.asObservable();
  }

  setSelectedGarage(garageObj) {
    this.setInitialGarage(garageObj);
    this.garageChange$.next({});
  }

  setInitialGarage(garageObj) {
    this.selectedGarage = garageObj;
    localStorage.setItem('selectedGarage', JSON.stringify(garageObj));
    this.setSelectedGarageName(garageObj.trackSetupName);
  }

  getSelectedGarage() {
    const selectedGarage = localStorage.getItem('selectedGarage') ? localStorage.getItem('selectedGarage') : '';
    return selectedGarage ? JSON.parse(selectedGarage) : {};
  }

  getSelectedGarageId() {
    const selectedGarage = localStorage.getItem('selectedGarage') ? localStorage.getItem('selectedGarage') : '';
    return selectedGarage ? JSON.parse(selectedGarage).trackSetupId : '';
  }

  getSelectedGarageName() {
    return this.selectedGarageName$.asObservable();
  }

  garageChangeNotification() {
    return this.garageChange$.asObservable();
  }

  getTenantId() {
    const userDetails = localStorage.getItem('loggedInUserDetails') && JSON.parse(localStorage.getItem('loggedInUserDetails'));
    return (userDetails && userDetails['custom:tenantId']) ? userDetails['custom:tenantId'] : '';
  }
 
  getUserRole() {
    let loggedInUserRole = '';
    const userDetails = localStorage.getItem('loggedInUserDetails') && JSON.parse(localStorage.getItem('loggedInUserDetails'));
    if (userDetails && userDetails['custom:userRole']) {
      loggedInUserRole = userDetails['custom:userRole'];
    } else if (userDetails && userDetails['custom:fedUserRole']) {
      loggedInUserRole = userDetails['custom:fedUserRole'];
    } else if (userDetails && userDetails['custom:roleInformation']) {
      loggedInUserRole = userDetails['custom:roleInformation'];
    } else if (userDetails['roleInformation'] ) {
      loggedInUserRole = userDetails['roleInformation']
    } else if (userDetails['userRole'] ) {
      loggedInUserRole = userDetails['userRole']
    } else {
      loggedInUserRole = '';
    }
    return loggedInUserRole;
  }

  getUserId() {
    let loggedInUserId = '';
    const userDetails = localStorage.getItem('loggedInUserDetails') && JSON.parse(localStorage.getItem('loggedInUserDetails'));
    if (userDetails && userDetails['custom:userId']) {
      loggedInUserId = userDetails['custom:userId'];
    } else if (userDetails['userId'] ) {
      loggedInUserId = userDetails['userId']
    } else {
      loggedInUserId = '';
    }
    return loggedInUserId;
  }

  getAccessedGarages() {
    const tenantId = this.getTenantId();
    const tenantUserId = this.getUserId();
    if (_.isEmpty(tenantId) || _.isEmpty(tenantUserId)) { return; }
    let reqObj = {};
    const loggedInUser = (localStorage.getItem('loggedInUserDetails') && JSON.parse(localStorage.getItem('loggedInUserDetails')));

    if (this.getUserRole().toUpperCase() === TENANTADMIN) {
      reqObj = {
        url: PE_API_CurrentAdmin.get(tenantId, tenantUserId),
      };
    } else {
      reqObj = {
        url: PE_API_CurrentUser.get(tenantId, tenantUserId),
      };
    }

    this.restService.get(reqObj)
      .subscribe(res => {
        this.accessedGarages$.next(Object.assign({}, { data: res }).data);

      }, err => {
        this.expectionService.log(LOG_ERROR_GARAGES, err);
      });
  }
  getAccessedGaragesObs() {
    this.accessedGarages$.observers = [];
    return this.accessedGarages$.asObservable();
  }



}
