import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatIconRegistry, MatIconModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppLayoutComponent } from '../views/layout/layout.component';
import { AppHeaderModule } from '../views/header/header.module';
import { CommonModule, LocationStrategy, HashLocationStrategy, DecimalPipe, DatePipe } from '@angular/common';
import { AppIncomingQueueModule } from '../views/incoming-queue/incoming-queue.module';
import { AppGarageViewModule } from '../views/garage-view/garage-view.module';
import { AppRecommendedRunsModule } from '../views/recommended-runs/recommended-runs.module';
import { AppRecommendedTracksModule } from '../views/recommended-tracks/recommended-tracks.module';
import { AppMaterialModule } from './material-module';
import { AppBusDetailsModule } from 'src/views/bus-details/bus-details.module';
import { BusIdPipe } from 'src/shared-pipes/bus-id.pipe';
import { LoginDialogComponent } from 'src/views/login-dialog/login-dialog.component';
import { FormsModule } from '@angular/forms';
import { TimerService } from 'src/shared-services/timer.service';
import { AppBusTrackModule } from 'src/views/bus-track-list/bus-track.module';
import { AppBusRunModule } from 'src/views/bus-run-list/bus-run.module';
import { LoaderComponent } from './loader/loader.component';
import { SharedService } from 'src/shared-services/shared.service';
import { RestService } from 'src/shared-services/rest.service';
import { TrackService } from 'src/shared-services/track.service';
import { AuthGuardService } from '../app/auth/auth-guard.service';;
import { LoginComponent } from './pre-login/login/login.component';
import { AuthenticateInterceptorService } from './auth/authenticate-interceptor.service';
import { ErrorInterceptorService } from './auth/error-interceptor.service';
import { AuthService } from './auth/auth.service';
import { CognitoService } from './auth/cognito.service';
import { AWSService } from './auth/aws.service';
import { LoginServiceService } from './pre-login/login/login.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { AppNavBarQueueModule } from '../views/navbar-left/navbar-left.module';
import { EcmsDisplayComponent } from '../views/ecms-display/ecms-display.component';
import { CcssOperationComponent } from '../views/ccss-operation/ccss-operation.component';
import { HeaderService } from '../shared-services/header.service';
import { RoundNumberPipe } from 'src/shared-pipes/round-number.pipe';
import { GaragelegendComponent } from '../views/garagelegend/garagelegend.component';
import { EventEmitterService } from 'src/shared-services/event-emitter.service';
import { AlertsComponent } from '../views/alerts/alerts.component';
import { NotificationsComponent } from 'src/views/notifications/notifications.component';
import { NotificationsService } from 'src/shared-services/notifications.service';
import { LimitToPipe } from 'src/shared-pipes/limit-to.pipe';
import { ToastAlertComponent } from './toast-alert/toast-alert.component';
import { EditableContentDirective } from 'src/shared-directives/editable-content.directive';

@NgModule({
  declarations: [
    AppComponent,
    AppLayoutComponent,
    BusIdPipe,
    LoginComponent,
    LoginDialogComponent,
    LoaderComponent,
    AlertsComponent,
    LimitToPipe,
    NotificationsComponent,
    AppNavBarQueueModule,
    AppRoutingModule,
    EcmsDisplayComponent,
    CcssOperationComponent,
    MatIconModule,
    RoundNumberPipe,
    GaragelegendComponent,
    ToastAlertComponent,
    EditableContentDirective
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    AppRoutingModule,
    AppHeaderModule,
    AppIncomingQueueModule,
    AppBusDetailsModule,
    AppGarageViewModule,
    AppRecommendedTracksModule,
    AppRecommendedRunsModule,
    AppBusTrackModule,
    FormsModule,
    HttpClientModule,
    ShowHidePasswordModule,
    AppNavBarQueueModule,
    AppBusRunModule,
    FormsModule,
    HttpClientModule,
  ],
  providers: [
    LoginServiceService,
    AuthService,
    CognitoService,
    AWSService,
    TimerService,
    SharedService,
    RestService,
    TrackService,
    AuthGuardService,
    EventEmitterService,
    NotificationsService,
    DecimalPipe,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticateInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true }, HeaderService,
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  exports: [
    AppHeaderModule,
    CommonModule,
    AppMaterialModule,
    AppIncomingQueueModule,
    AppBusDetailsModule,
    AppGarageViewModule,
    AppRecommendedTracksModule,
    AppRecommendedRunsModule,
    AppBusTrackModule,
    FormsModule,
    AlertsComponent,
    NotificationsComponent,
    AppBusTrackModule,
    FormsModule,
    AppNavBarQueueModule,
    AppRoutingModule],
  bootstrap: [ AppComponent ]
})

export class AppModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg')); 
    // Or whatever path you placed mdi.svg at
  }
}
