import { Directive, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';

@Directive({
  selector: '[appEditableContent]'
})
export class EditableContentDirective {
  @Input('appEditableContent') editableType: 'decimal' | 'integer' | 'any' = 'any';
  @Input() rangeStart: number | string;
  @Input() rangeEnd: number | string;

  @Output() doneEditing = new EventEmitter<string>();

  private decimalRegEx = /^(\d|\.|(arrow.*)|(backspace))*$/i;
  private integerRegEx = /^(\d|(arrow.*)|(backspace))*$/i;
  private currentValue: string;

  constructor(
    private element: ElementRef
  ) { }

  @HostListener('blur', ['$event'])
  private onBlur(event: KeyboardEvent) {
    this.done(event);
  }

  @HostListener('click', ['$event'])
  private onClick(event) {
    if (!this.element.nativeElement.hasAttribute('contentEditable')) {
      if (this.editableType === 'decimal' || this.editableType === 'integer') {
        this.currentValue = this.element.nativeElement.textContent.replace(/,/g, '');
        this.element.nativeElement.textContent = this.currentValue;
      } else {
        this.currentValue = this.element.nativeElement.textContent;
      }

      this.element.nativeElement.toggleAttribute('contentEditable');
      this.focusAtEnd(this.element.nativeElement);
    }
  }

  focusAtEnd(element): void {
    element.focus();
    if (typeof window.getSelection !== 'undefined'
            && typeof document.createRange !== 'undefined') {
        const createdRange = document.createRange();
        createdRange.selectNodeContents(element);
        createdRange.collapse(false);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(createdRange);
    }
  }

  @HostListener('keydown', ['$event'])
  private onKeydown(event: KeyboardEvent) {
    if ((['Tab', 'Escape', 'Enter'].indexOf(event.key) > -1)) {
      this.done(event);
    } else if (
      !(
        (this.editableType === 'decimal' && this.decimalRegEx.test(event.key)) ||
        (this.editableType === 'integer' && this.integerRegEx.test(event.key))
      )
    ) {
      event.preventDefault();
    }
  }

  private done(event: KeyboardEvent): void {
    event.preventDefault();
    this.element.nativeElement.removeAttribute('contentEditable');
    const newValue = this.element.nativeElement.textContent;
    if (this.editableType === 'integer' || this.editableType === 'decimal') {
      if (
        typeof(this.rangeStart) !== 'undefined' && (newValue === '' || ((newValue * 1) < this.rangeStart)) ||
        typeof(this.rangeEnd) !== 'undefined' && (newValue === '' || ((newValue * 1) > this.rangeEnd))
      ) {
        this.element.nativeElement.textContent = this.currentValue;
        this.doneEditing.emit(this.currentValue);
        return;
      }
    }
    this.element.nativeElement.textContent = newValue;
    this.doneEditing.emit(newValue);
  }
}
