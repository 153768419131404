import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { BusService } from 'src/shared-services/bus.service';
import { RunService } from 'src/shared-services/run.service';
import { TrackService } from 'src/shared-services/track.service';
import { TimerService } from 'src/shared-services/timer.service';
import { SharedService } from 'src/shared-services/shared.service';
import { exists } from 'fs';
import {
  IQ,
  LCQ,
  GARAGE
} from '../../app-constants/app-constants';
import { bool } from 'aws-sdk/clients/signer';


@Component({
  selector: 'app-incoming-queue',
  templateUrl: './incoming-queue.component.html',
  styleUrls: ['./incoming-queue.component.scss']
})
export class IncomingQueueComponent implements OnInit, OnDestroy {
  incomingQueueBuses = [];
  busList: any = [];
  filteredBusList: Observable<any[]>;
  selectedBusIndex = 0;
  activeBusName = '';
  emptyIncomingQ = true;
  assetsData: any;
  incomingFilteredBuses: any;
  assetsFilteredBuses: any;
  incomingTabGroupDisabled: bool = false;
  searchCtrl = new FormControl();
  private activeBusId = '';
  private busServiceSubscription;
  private allBusDataServiceSubscription;
  interval: NodeJS.Timer;
  constructor(
    private busService: BusService,
    private runService: RunService,
    private timerService: TimerService,
    private trackService: TrackService,
    private sharedService: SharedService
  ) {
  }

  ngOnInit() {
    this.RefreshBuses();
    this.trackService.getGarageBusTrackList()
      .subscribe(res => {
        const garageBusData = JSON.parse(localStorage.getItem('tracksData')).buses;
        if (garageBusData !== undefined) {
          this.assetsData = garageBusData.map(bus => {
            const obj = {
              vin: bus.busVIN,
              vehicleModelID: bus.vehicleModel ? bus.vehicleModel : '',
              notes: bus.notes ? bus.notes : '',
              busName: bus.busName,
              vehicleName2: bus.busName,
              isMaintenanceRequired: bus.isMaintenanceRequired ? bus.isMaintenanceRequired : false
            };
            return obj;
          });
        }
      });
  }

  private RefreshBuses() {
    this.busServiceSubscription = this.busService.getBusList().subscribe((res) => {
      this.reset();
      this.incomingQueueBuses = res;
      if (this.incomingQueueBuses.length > 0) {
        this.incomingQueueBuses = this.incomingQueueBuses.filter(bus => bus.vin);
        this.separateMoreBuses();
        this.emptyIncomingQ = false;
        this.selectedBusIndex = 1;
      } else {
        this.busList = [];
        this.emptyIncomingQ = true;
        this.busService.clearBusDetails();
      }
    });
    this.filteredBusList = this.searchCtrl.valueChanges.pipe(startWith(''), map(busId => busId ? this._filterBuses(busId) : []));
  }

  private reset() {
    this.searchCtrl.reset();
    this.runService.clearRecomendedRunsView();
    this.trackService.clearRecomendedTrackView();
  }

  private _filterBuses(value: any): any {
    const filterValue = value.toString().toLowerCase();
    this.incomingFilteredBuses = this.incomingQueueBuses.filter(
      bus => bus.busName ? bus.busName.toLowerCase().indexOf(filterValue) > -1 : false);
    return this.assetsFilteredBuses =
      this.assetsData.sort((a , b) => a.busName - b.busName)
        .filter(bus => bus.busName ? (bus.busName.toLowerCase().indexOf(filterValue) > -1) : false);
  }

  onSelectBusId(event): any {
    this.timerService.blnRecommendRunAndTrackInProcess = false;
    const busNameValue = event.option.value;
    const showTrackList = this.trackService.showTrackListValue();
    if (showTrackList !== GARAGE) {
      this.trackService.setShowTrackList(GARAGE);
    }
    this.searchCtrl.reset();
    if (this.incomingFilteredBuses.length > 0) {
      if (this.activeBusName !== busNameValue) {
        this.incomingQueueBuses.forEach((e, i) => {
          if (busNameValue === e.busName) {
            this.selectedBusIndex = i + 1;
          }
        });
      }
    } else {
      const selectedBus = this.assetsFilteredBuses.filter(bus => {
        if (bus.busName === busNameValue) { return bus; }
      });
      this.busService.isMaintenanceFromTrackssetup(selectedBus[0].isMaintenanceRequired);
      this.selectedBusIndex = 0;
      this.runService.clearRecomendedRunsView();
      this.trackService.clearRecomendedTrackView();
      this.getBusDetailsFromTracks(selectedBus[0].vin);
      this.busService.onSelectBusDetails(selectedBus[0].vin, selectedBus[0].busName, LCQ);
    }
  }

  getBusDetailsFromTracks(bus) {
    localStorage.removeItem('filterdBusData');
    const busDetails = this.sharedService.busDetailsFromTracks(bus);
    localStorage.setItem('filterdBusData', JSON.stringify(busDetails.length > 0 ? busDetails[0] : []));
  }

  onBusTabSelection(event) {
    try {
      this.timerService.blnRecommendRunAndTrackInProcess = false;
      this.incomingTabGroupDisabled = true;
      if (event.index > 0) {
        const showTrackList = this.trackService.showTrackListValue();
        if (showTrackList !== GARAGE) {
          this.trackService.setShowTrackList(GARAGE);
        }
        this.activeBusId = this.busList[event.index - 1].vin;
        this.activeBusName = this.busList[event.index - 1].busName;
        this.busService.onSelectBusDetails(this.activeBusId, this.activeBusName, IQ);
        this.runService.clearRecomendedRunsView();
        this.trackService.clearRecomendedTrackView();
        this.trackService.disableBtns = false;
        this.searchCtrl.reset();
        this.selectedBusIndex = event.index;
      }
      this.incomingTabGroupDisabled = false;
    } catch (err) {
      this.incomingTabGroupDisabled = false;
    } finally {
      this.incomingTabGroupDisabled = false;
    }
  }

  private separateMoreBuses() {
    this.busList = this.incomingQueueBuses;
    this.allBusDataServiceSubscription = this.busService.getAllBusDataDetails()
      .subscribe(res => {
        if (this.busList && this.busList.length) {
          this.busService.loadSelectedBusDetails(res, this.busList[0].vin, this.busList[0].busName, IQ);
        }
        this.allBusDataServiceSubscription.unsubscribe();
      });
  }

  ngOnDestroy() {
    this.busServiceSubscription.unsubscribe();
    if (this.allBusDataServiceSubscription) {
      this.allBusDataServiceSubscription.unsubscribe();
    }
  }

}
