<div class="notifications"
    [ngClass]="{'ccss-control-view-notifications': currentPage=='/chargerControl','layout-notifications':currentPage=='/runTrackAssign'}">
    <div class="card bg-transparent">
        <div class="card-header text-white">
            Alerts ({{notificationCount}})
        </div>
        <div class="card-body p-0">
            <div *ngIf='notifications'>
                <div class="tabContent" *ngFor="let item of notifications">
                    <p class="dateTime">{{item.createdTimestamp | date:'dd MMM HH:mm'}}</p>
                    <p class="assignedToLabel" [matTooltip]='item.message'>{{item.message}}</p>
                    <button mat-raised-button class='action-button' *ngIf="item.action" [disabled]="isAlertActionsDisabled(item)"
                        [ngClass]="{'initiate':item.action == 'Initiate Charging','terminate':item.action == 'Terminate Charging'}"
                        (click)='onActionTrigger(item)'>{{item.action}}</button>
                </div>
            </div>
        </div>
    </div>
</div>