import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecommendedTracksComponent } from './recommended-tracks.component';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { AppMaterialModule } from 'src/app/material-module';
import { TrackService } from 'src/shared-services/track.service';

@NgModule({
  declarations: [ RecommendedTracksComponent ],
  imports: [
    CommonModule,
    MatInputModule,
    AppMaterialModule,
    FormsModule
  ],
  providers: [ TrackService ],
  exports: [
    RecommendedTracksComponent,
    CommonModule
  ]
})

export class AppRecommendedTracksModule { }
