import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/shared-services/header.service';

@Component({
  selector: 'app-ecms-display',
  templateUrl: './ecms-display.component.html',
  styleUrls: ['./ecms-display.component.scss']
})
export class EcmsDisplayComponent implements OnInit {

  constructor(private headerService: HeaderService) { }

  ngOnInit() {
    this.headerService.setTitle('ECMS Display');

  }

}
