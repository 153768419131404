import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from '../app/pre-login/login/login.component';
import { AppLayoutComponent } from 'src/views/layout/layout.component';
import { AuthGuardService } from '../app/auth/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'runTrackAssign', component: AppLayoutComponent, canActivate: [AuthGuardService] },
  { path: 'chargerControl',
  loadChildren: () => import('../views/ccss-control-view/ccss-control-view.module').then(m => m.CcssControlViewModule),
  canActivate: [AuthGuardService] },
  { path: 'garageOverview',
  loadChildren: () => import('../views/ccss/ccss.module').then(m => m.CcssModule), canActivate: [AuthGuardService] },
  { path: 'energyManagement',
  loadChildren: () => import('../views/ecms-admin/ecms.module').then(m => m.ECMSModule), canActivate: [AuthGuardService] },
  { path: 'configuration',
  loadChildren: () => import('../views/configuration/configuration.module').then(m => m.ConfigModule), canActivate: [AuthGuardService] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: true })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
