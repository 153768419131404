import { Component, OnInit, HostListener } from '@angular/core';
import { SharedService } from 'src/shared-services/shared.service';
import { CommsService } from '../shared-services/comms.service'
import { TimerService } from 'src/shared-services/timer.service';
import { Router, NavigationEnd } from '@angular/router';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'Proterra-Edmonton';
  showLoader = false;
  constructor(
    private sharedService: SharedService,
    private timerService: TimerService,
    private router: Router,
    private commonService: CommsService
  ) { }

  ngOnInit() {
    // this.timerService.refreshEveryMinuteByRx();
    this.sharedService.canShowLoader()
      .subscribe((res: boolean) => {
        this.showLoader = res ? true : false;
      });
    this.router.events
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          gtag('config', 'UA-166245721-1',
            {
              page_path: event.urlAfterRedirects
            }
          );
        }
      });
  }

  @HostListener('window:online')
  onLine() {
    this.commonService.isOnline = true;
  }

  @HostListener('window:offline')
  offLine() {
    this.commonService.isOnline = false;
  }
}
