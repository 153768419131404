<div class='col-12 heading'><span>{{runType}} Track</span></div>

<div class='row'>
    <div class='item col-4'>
        <div class='title'>Track No</div>
        <div class='data'>{{recommendedTrackDetails.trackNo}}</div>
    </div>
    <div class='item col-4'>
      <div class='title'>Position</div>
      <div class='data'>{{recommendedTrackDetails.position}}</div>
  </div>
    <div class='item col-4'>
        <div class='title'>Track Type</div>
        <div class='data'>{{recommendedTrackDetails.trackType}}</div>
    </div>
</div>
<form #recommendedTrackDetail="ngForm">
<div class='row'>
  <div class='item col-4 recommendedTrackInputDiv'>
    <mat-form-field class='full-width'>
      <label>Track No</label>
      <input matInput type='text' [(ngModel)]="inputTrackDetails.trackNo" id="trackno" name="trackno"
        class="recommendedTrackInput trackNo">
    </mat-form-field>
  </div>
  <div class='item col-4 recommendedTrackInputDiv'>
    <mat-form-field class='full-width'>
      <label>Position</label>
      <input matInput type='text' [(ngModel)]="inputTrackDetails.position" id="position" name="position"
       class="recommendedTrackInput trackNo">
    </mat-form-field>
  </div>
</div>
<div class='bottom-button-panel' [hidden]="hideOverride" >
    <button mat-flat-button routerLink="" [disabled]="disableOverrideBtns" (click)='openLoginDialog($event)' class='override'>Override</button>
    <button  mat-raised-button [disabled]="disableBtns" (click)='assignTrack()' class='assign-button button-yellow'>Assign Run & Track</button>
     <!-- <button mat-raised-button (click)='clearAssignment()' class='assign-button button-yellow'>Clr Asgnmts</button> -->
    <!-- <button mat-raised-button (click)='updateBookInTime()' class='assign-button button-yellow'>Updt BkInTms</button> -->
    <div class='clear-both'></div>
</div>
</form>




<ng-template #trackChangeConformationDialog>
    <div mat-dialog-content class="dialogBox">
      <h6 style="line-height: 30px; font-size: 20px;">{{dialogDisplayText}}</h6>
    </div>
    <div mat-dialog-actions class="dialogBoxActions float-right">
      <button mat-stroked-button class='button-cancel' (click)='onCancel()'>Cancel</button>
      <button mat-raised-button class='button-yellow' (click)='onSubmit()' [mat-dialog-close]>Yes</button>
    </div>
  </ng-template>