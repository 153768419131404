import { Injectable } from '@angular/core';
import { Subject, range } from 'rxjs';
import { RestService } from './rest.service';
import { MockService } from './mock.service';
import { ExceptionService } from './exception.service';
import { TrackService } from './track.service';
import { MOCK_RUN_TRACK_DETAILS } from '../app-constants/app-mock-constants';
import {
  PE_API_RECOMMENDED_RUNS,
  PE_API_ASSIGNRUN,
  LOG_ERROR_FETCH_RUN_TRACK,
  LOG_ERROR_ASSIGN_RUN,
  TOAST_ASSIGN_BUS_SUCCESS,
  TOAST_SUCCESS,
  TOAST_ERROR,
  CHARGER_STATUS,
  GET_RECOMMENDED_RUNS_REQUEST_ID,
  ASSIGN_RUN_REQUEST_ID,
  REASSIGN_DIALOG_TEXT,
  REASSIGN_DIALOG_MAINTENANCE_TEXT,
} from 'src/app-constants/app-constants';
import { SharedService } from './shared.service';
import { BusService } from './bus.service';
import { NotificationsService } from './notifications.service';
import { HeaderService } from './header.service';

@Injectable({
  providedIn: 'root'
})

export class RunService {

  private recommendedRuns$ = new Subject<any>();
  private recommendedRun$ = new Subject<any>();
  private hideRunOverride$ = new Subject<boolean>();
  private isTopRecommended$ = new Subject<boolean>();
  private runAssignStatus$ = new Subject<{ message: string, type: string }>();
  private runResponseHandler$ = new Subject<{ message: string, type: string }>();
  private recommendedRuns: any;
  private recommendedRun: any;
  private selectedBus: any;
  private maintenanceRun: any;
  private currentEnergy: number;
  set currentEnergyVal(val: number) {
    this.currentEnergy = val;
  }

  private recommendedRunID$ = new Subject<string>();

  constructor(
    private restService: RestService,
    private mockService: MockService,
    private trackService: TrackService,
    private exceptionService: ExceptionService,
    private sharedService: SharedService,
    private busService: BusService,
    private headerService: HeaderService,
    private notificationsService: NotificationsService
  ) { }

  getRecommendedRun() {
    return this.recommendedRun$.asObservable();
  }


  setHideOverride(blnShow) {
    this.hideRunOverride$.next(blnShow);
  }

  getRunResponseTypeHandler() {
    return this.runResponseHandler$.asObservable();
  }

  getRecommendedRuns() {
    return this.recommendedRuns$.asObservable();
  }

  loadRecommendedRuns(selectedBus) {
    const Garage = this.headerService.getSelectedGarage();
    const GarageId = Garage.trackSetupId;
    const tenantIdVal = this.headerService.getTenantId();
    if (GarageId === null || GarageId === '') { return; }
    if (tenantIdVal === null || tenantIdVal === '') { return; }
    this.selectedBus = selectedBus;
    const now = new Date();
    const getRecommendedRunsId = this.sharedService.create_UUID(GET_RECOMMENDED_RUNS_REQUEST_ID);
    this.sharedService.setGetRecommendedRunId(getRecommendedRunsId);
    const reqObj = {
      url: PE_API_RECOMMENDED_RUNS.post(GarageId, tenantIdVal) + '/' + selectedBus.vin,
      options: {},
      body: {
        batteryCapacity: selectedBus.batteryCapacity,
        busUnitId: selectedBus.vin,
        customerGarageId: Garage.customerGarageId,
        id: getRecommendedRunsId,
        soc: parseInt(selectedBus.soc.split(' ')[0], 10),
        time: Date.UTC(
          now.getUTCFullYear(),
          now.getUTCMonth(),
          now.getUTCDate(),
          now.getUTCHours(),
          now.getUTCMinutes(),
          now.getUTCSeconds(),
          now.getUTCMilliseconds()
        ),
        maintenanceRequired: selectedBus.maintenanceRequired,
        systemEnergy: this.currentEnergy,
        tenantId: tenantIdVal,
        busName: selectedBus.busName
      }
    };

    this.restService.post(reqObj)
      .subscribe((res: any) => {
        if (!res) { return; }
        this.recommendedRuns = res.runs;
        this.recommendedRuns$.next(Object.assign({}, { data: this.recommendedRuns }).data);
        this.recommendedRun = this.recommendedRuns && this.recommendedRuns.length > 0 ? this.recommendedRuns[0] : {};
        let isCheckTopRecommended: boolean = false;
        for (const run of this.recommendedRuns) {
          if (run.topMostRecommended) {
            isCheckTopRecommended = true;
            this.recommendedRun = run;
            break;
          }
        }
        if (isCheckTopRecommended) {
          this.recommendedRun$.next(Object.assign({}, { data: this.recommendedRun }).data);
          this.trackService.loadRecommendedTracks(Object.assign({}, { data: this.recommendedRun }).data);
        }
        this.isTopRecommended$.next(isCheckTopRecommended);
        if (res.runs.length !== 0) {
          this.hideRunOverride$.next(false);
        }
        this.trackService.hideTrackOverride$.next(false);

      }, err => {
        // const ERROR_MESSAGE = err.error ? err.error.message : err.message;
        // this.runResponseHandler$.next(Object.assign({}, { data: { type: TOAST_ERROR, message: ERROR_MESSAGE } }).data);
        this.exceptionService.log(LOG_ERROR_FETCH_RUN_TRACK, err);

      });
  }

  assignRun(maintenanceFlag) {
    const garage = this.headerService.getSelectedGarage();
    const GarageId = garage ? garage.trackSetupId : '';
    const tenantIdVal = this.headerService.getTenantId();
    if (GarageId === null || GarageId === '') { return; }
    if (tenantIdVal === null || tenantIdVal === '') { return; }
    if (this.selectedBus.maintenanceRequired === true) {
      let recommendedTrackData;
      if (this.recommendedRun) {
          recommendedTrackData = {
            trackId: this.recommendedRun.trackId,
            trackName: this.recommendedRun.trackName,
            trackPosition: this.recommendedRun.positionInTrack + 1,
            trackType: this.recommendedRun.trackType,
            chargerPositionId: this.recommendedRun.chargerPositionId,
            chargerName: this.recommendedRun.chargerName,
            positionInTrack: this.recommendedRun.positionInTrack + 1,
            busVIN: this.selectedBus.vin,
            busName: this.selectedBus.busName,
            isMaintenanceRequired: this.selectedBus.maintenanceRequired,
            overrideRunComment: null,
            overrideTrackComment: this.recommendedRun.overrideTrackComment,
            positionId: this.recommendedRun.customerPositionId,
            overrideRun: false,
            overrideTrack: this.recommendedRun.overrideTrack,
            rowStart: this.recommendedRun.rowStart,
            rowEnd: this.recommendedRun.rowEnd,
            columnStart: this.recommendedRun.columnStart,
            columnEnd: this.recommendedRun.columnEnd,
            busUnitId: this.selectedBus.busName
          };
      } else {
        recommendedTrackData = {
          busVIN: this.selectedBus.vin,
          busName: this.selectedBus.busName,
          isMaintenanceRequired: this.selectedBus.maintenanceRequired
        };
      }
      this.recommendedRun = recommendedTrackData;
    } else {
      if (localStorage.getItem('garageAllCels') !== null) {
        const GARAGEALLCHARGERS = JSON.parse(localStorage.getItem('garageAllCels'));
        const SELECTEDCHARGER = GARAGEALLCHARGERS.filter(item => item.chargerPositionId === this.recommendedRun.chargerPositionId);
        if (SELECTEDCHARGER && SELECTEDCHARGER.length > 0) {
          this.recommendedRun.rowStart = SELECTEDCHARGER[0].rowStart;
          this.recommendedRun.rowEnd = SELECTEDCHARGER[0].rowEnd;
          this.recommendedRun.columnStart = SELECTEDCHARGER[0].columnStart;
          this.recommendedRun.columnEnd = SELECTEDCHARGER[0].columnEnd;
          this.recommendedRun.positionId = SELECTEDCHARGER[0].customerPositionId;
          this.recommendedRun.trackPosition = SELECTEDCHARGER[0].positionInTrack + 1;
          this.recommendedRun = { ...this.recommendedRun, ...{ busUnitId: this.recommendedRun.busName } };
        }
      }
      this.recommendedRun.isMaintenanceRequired = this.selectedBus.maintenanceRequired;
    }
    let getRecommendedRunId = this.sharedService.getGetRecommendedRunId();
    if (getRecommendedRunId === null) {
      getRecommendedRunId = this.sharedService.create_UUID(ASSIGN_RUN_REQUEST_ID);
    }
    const reqObj = {
      url: PE_API_ASSIGNRUN.post(GarageId, tenantIdVal) + '/' + this.selectedBus.vin,
      options: {},
      body: {
        customerGarageId: garage.customerGarageId,
        id: getRecommendedRunId,
        run: this.recommendedRun,
        tenantId: tenantIdVal
      }
    };
    this.restService.post(reqObj)
      .subscribe((res: any) => {
        const assignResult = res;
        const trackPosition = parseFloat(this.recommendedRun.positionInTrack) +
          (this.recommendedRun.isMaintenanceRequired === true ? 0 : 1);
        const RUNASSIGNDATA = {
          message: assignResult.message,
          type: TOAST_SUCCESS
        };
        this.busService.removeAssignedBusFromIQ(this.selectedBus.vin);
        this.trackService.clearRecomendedTrackView();
        this.trackService.clearRecommendedRun$.next({});
        this.trackService.setShowTrackList('garage');
        this.busService.toggleMaintenace(false);
        this.runAssignStatus$.next(Object.assign({}, { data: RUNASSIGNDATA }).data);
      }, err => {
        this.exceptionService.log(LOG_ERROR_ASSIGN_RUN, err);
      });
  }

  checkBusInGarage() {
    const GARAGEALLCELLS = localStorage.getItem('garageAllCels') ? JSON.parse(localStorage.getItem('garageAllCels')) : [];
    const CELLOBJ = GARAGEALLCELLS.filter(item => item.busVIN === this.selectedBus.vin);
    let returnVal = null;
    if (CELLOBJ && CELLOBJ.length > 0) {
      returnVal = CELLOBJ[0];
    }
    return returnVal;
  }

  checkReAssignBusCharging(garageCellObject) {
    let returnVal = false;
    if ((garageCellObject.chargerStatus && garageCellObject.chargerStatus.toLowerCase() === CHARGER_STATUS.charging) &&
      (garageCellObject.trackName !== this.recommendedRun.trackName ||
      garageCellObject.positionInTrack !== this.recommendedRun.positionInTrack)) {
      returnVal = true;
    }
    return returnVal;
  }

  getBusReAssignDialogDisplayText(garageCellObject) {
    let returnVal = '';
    if (garageCellObject.bus.isMaintenanceRequired === false && this.selectedBus.maintenanceRequired === false) {
      returnVal = REASSIGN_DIALOG_TEXT;
    } else if (garageCellObject.bus.isMaintenanceRequired === true && this.selectedBus.maintenanceRequired === false) {
      returnVal = REASSIGN_DIALOG_MAINTENANCE_TEXT;
    }
    return returnVal;
  }

  loadAllRuns() {
    this.recommendedRuns$.next(Object.assign({}, { data: this.recommendedRuns }).data);
  }

  clearRecomendedRunsView() {
    this.recommendedRun$.next({});
    this.hideRunOverride$.next(true);
  }

  changeRecomendedRuns(runData) {
    this.recommendedRun = runData ? runData : {};
    this.recommendedRun$.next(Object.assign({}, { data: this.recommendedRun }).data);
    this.trackService.loadRecommendedTracks(this.recommendedRun);
  }

  overrideRecomendedTrack(trackData) {
    this.recommendedRun = { ...this.recommendedRun, ...trackData };
    this.recommendedRun$.next(Object.assign({}, { data: this.recommendedRun }).data);
    this.trackService.loadRecommendedTracks(this.recommendedRun);
  }

  hideRunOverride() {
    return this.hideRunOverride$.asObservable();
  }

  recommendedRunId(value) {
    this.recommendedRunID$.next(value);
  }

  getRecommendedRunId() {
    return this.recommendedRunID$.asObservable();
  }

  getTopRecommended() {
    return this.isTopRecommended$.asObservable();
  }

  getRunAssignStatus() {
    return this.runAssignStatus$.asObservable();
}

  private loadMockRuns() {
    this.mockService.get(MOCK_RUN_TRACK_DETAILS.get)
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        this.recommendedRuns = res.runs;
        this.recommendedRun = this.recommendedRuns ? this.recommendedRuns[0] : {};
        this.recommendedRun$.next(Object.assign({}, { data: this.recommendedRun }).data);
        this.recommendedRuns$.next(Object.assign({}, { data: this.recommendedRuns }).data);
        this.trackService.loadRecommendedTracks(this.recommendedRuns ? this.recommendedRuns[0] : {});
      });
  }

  getPager(totalItems: number, currentPage: number = 1, pageSize: number = 10) {
    // calculate total pages
    if (totalItems !== 0) {
      let totalPages = Math.ceil(totalItems / pageSize);

      let startPage: number, endPage: number;
      
      if (totalPages <= 5) {
        startPage = 1;
        endPage = totalPages;
      } else {
          if (currentPage <= 3) {
              startPage = 1;
              endPage = 5;
          } else if (currentPage + 1 >= totalPages) {
              startPage = totalPages - 4;
              endPage = totalPages;
          } else {
              startPage = currentPage - 2;
              endPage = currentPage+2;
          }
      }
  
      // calculate start and end item indexes
      let startIndex = (currentPage - 1) * pageSize;
      let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
      var range = (start, end) => [...Array(end - start + 1)].map((_, i) => start + i);
      let pages = range(startPage, endPage);
  
  
      return {
          totalItems: totalItems,
          currentPage: currentPage,
          pageSize: pageSize,
          totalPages: totalPages,
          startPage: startPage,
          endPage: endPage,
          startIndex: startIndex,
          endIndex: endIndex,
          pages: pages
      };
    }
}
}
