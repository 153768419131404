import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewContainerRef
} from '@angular/core';
import { CHARGER_CONTROL_ROUTE, EMERGENCY_STOP_ALL_ACTION, ENABLE } from 'src/app-constants/app-constants';
import { GARAGE_OVERVIEW_ROUTE } from 'src/app-constants/app-constants';
import { Router } from '@angular/router';

import { MatDialog, MatDialogRef } from '@angular/material';
import { ChargerService } from '../../shared-services/charger.service';
import { SharedService } from 'src/shared-services/shared.service';

@Component({
  selector: 'app-garagelegend',
  templateUrl: './garagelegend.component.html',
  styleUrls: ['./garagelegend.component.scss']
})

export class GaragelegendComponent implements OnInit {
  infoRow = 'info-row';
  @ViewChild('emergencyStop', { static: false }) emergencyStopDialog: TemplateRef<any>;
  dialogRef: MatDialogRef<any, any>;
  hasPermissionForEmergencyStopButton = false;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private chargerService: ChargerService,
    private sharedService: SharedService
  ) { }

  showDialog() {
    this.dialogRef = this.dialog.open(this.emergencyStopDialog);
    this.dialogRef.afterClosed().subscribe(result => {
      return result;
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    const chargerObjects = JSON.parse(localStorage.getItem('chargerStates'));
    if (chargerObjects) {
      this.chargerService.cancelAllChargers(chargerObjects);
    }
  }
  ngOnInit() {
    this.infoRow = this.router.url === GARAGE_OVERVIEW_ROUTE ? 'info-row' : 'info-row-ccss';
  }

  isEmergencyButtonDisabled() {
    this.hasPermissionForEmergencyStopButton = this.sharedService.roleHasPermissionForAction(this.router.url,
      EMERGENCY_STOP_ALL_ACTION,
      ENABLE);
    return !this.hasPermissionForEmergencyStopButton;
  }
}
