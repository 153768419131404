<button class="navbar-left" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
  <mat-icon svgIcon="menu" class="menu-btn"></mat-icon>
</button>
<nav>
  <mat-menu #menu="matMenu">

    <div class="mat-menu-content-item">
      <mat-divider></mat-divider>
      <button mat-menu-item disabled>
        <div class="menu-item">Charger Control & Scheduling</div>
      </button>
      <a mat-menu-item [routerLink]="['/runTrackAssign']" [routerLinkActive]="['activated']">
        <div class="submenu-item">Run & Track Assignment</div>
      </a>
      <a mat-menu-item [routerLink]="['/chargerControl']" [routerLinkActive]="['activated']">
        <div class="submenu-item">Charger Control</div>
      </a>
      <a mat-menu-item [routerLink]="['/garageOverview']" [routerLinkActive]="['activated']">
        <div class="submenu-item">Garage Overview</div>
      </a>
      <mat-divider></mat-divider>
      <button mat-menu-item disabled>
        <div class="menu-item">Energy Control & Management</div>
      </button>
      <a mat-menu-item [routerLink]="['/energyManagement']" [routerLinkActive]="['activated']">
        <div class="submenu-item">Energy Management</div>
      </a>
      <mat-divider></mat-divider>
      <button mat-menu-item disabled>
        <div class="menu-item">Configuration</div>
      </button>
      <a mat-menu-item [routerLink]="['/configuration']" [routerLinkActive]="['activated']">
        <div class="submenu-item">CCSS Configuration</div>
      </a>
      <mat-divider></mat-divider>
      <button mat-menu-item disabled>
        <div class="menu-item">{{currentBuildDate}}</div>
      </button>
    </div>
  </mat-menu>
</nav>