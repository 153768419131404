import { Component, OnInit, OnDestroy } from '@angular/core';
import { NotificationsService } from 'src/shared-services/notifications.service';
import { TimerService } from 'src/shared-services/timer.service';
import { Router } from '@angular/router';
import { RUN_TRACK_ASSIGNMENT_ROUTE,
  IToastMessage,
  TOASTMESSAGE_TYPE,
  TOAST_CONFIG_PAYLOAD
 } from 'src/app-constants/app-constants';
import { ToastAlertComponent } from 'src/app/toast-alert/toast-alert.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})

export class NotificationsComponent implements OnInit, OnDestroy {
  private notificationSubscription: any;
  notifications: any = [];
  currentPage = RUN_TRACK_ASSIGNMENT_ROUTE;
  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private notificationsService: NotificationsService,
    private timerService: TimerService
  ) { }

  ngOnInit() {
    this.currentPage = this.router.url;

    this.notificationsService.getNotificationResponseTypeHandler()
      .subscribe(res => {
        const MESSAGEOBJ: IToastMessage = { type: TOASTMESSAGE_TYPE.SUCCESS, message: res.message };
        this.snackBar.openFromComponent(ToastAlertComponent, TOAST_CONFIG_PAYLOAD.get(MESSAGEOBJ));
      });

    this.notificationSubscription = this.notificationsService.getNotifications()
      .subscribe(res => {
        if (res && res.length !== 0) {
          this.notifications = res.filter(result => {
            return result.type && result.type.toUpperCase() !== 'ALERT';
          });
          this.notifications.sort((a, b) => {
            return this.convertPriorityToNumber(b.notificationPriority) - this.convertPriorityToNumber(a.notificationPriority);
          });
          this.notifications.sort((a, b) => {
            return (new Date(b.updatedTimestamp)).getTime() - (new Date(a.updatedTimestamp)).getTime();
          });
        } else {
          this.notifications = [];
        }
      });
  }

  ngOnDestroy() {
    this.notificationSubscription.unsubscribe();
  }

  private convertPriorityToNumber(p: string) {
    switch (p.toLowerCase()) {
      case 'high': return 3;
      case 'medium': return 2;
      case 'low': return 1;
      default: return 0;
    }
  }
}
