import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
  providedIn: 'root'
})

export class EventEmitterService {
  invokeGarageChangeTrackbusFunction = new EventEmitter();
  subsVar: Subscription;

  constructor() { }

  onGarageChangeTrackBusClick() {
    this.invokeGarageChangeTrackbusFunction.emit();
  }
}
