import { Component, OnInit, OnDestroy } from '@angular/core';
import { RunService } from 'src/shared-services/run.service';
import { MatDialog } from '@angular/material';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { TrackService } from 'src/shared-services/track.service';
import { BusService } from 'src/shared-services/bus.service';
import { CcssDisplayCommonService } from 'src/shared-services/ccss-display-common.service';
import {
  RUN,
  ASSIGNED,
  NOT_ASSIGNED,
  RECOMMENDED,
  NOT_APPLICABLE,
  OVERRIDE_RUN_TRACK_ACTION,
  ENABLE
} from '../../app-constants/app-constants';
import { Router } from '@angular/router';
import { SharedService } from 'src/shared-services/shared.service';

@Component({
  selector: 'app-recommended-runs',
  templateUrl: './recommended-runs.component.html',
  styleUrls: ['./recommended-runs.component.scss']
})

export class RecommendedRunsComponent implements OnInit, OnDestroy {
  recommendedRunDetails: any = {};
  runType = RECOMMENDED;
  hideOverride = true;
  logicalChargeQueueData: any;
  hasPermissionForOverrideRun = false;
  private restSubscription;
  private hideOverrideSubscription;
  private clearRecommendedRunSubscription;
  private maintenaceSubscription;

  get disableBtns(): boolean {
    this.hasPermissionForOverrideRun = this.sharedService.roleHasPermissionForAction(this.router.url,
      OVERRIDE_RUN_TRACK_ACTION,
      ENABLE);
    if (this.hasPermissionForOverrideRun === false) {
      return true;
    }
    return this.trackService.disableBtns;
  }

  constructor(
    public dialog: MatDialog,
    private runService: RunService,
    private trackService: TrackService,
    private busService: BusService,
    private ccssDisplayCommonService: CcssDisplayCommonService,
    private sharedService: SharedService,
    private router: Router
  ) { }

  ngOnInit() {
    this.restSubscription = this.runService.getRecommendedRun()
      .subscribe(res => {
        if (res) {
          this.runService.recommendedRunId(res.runNumber ? res.runNumber : '');
          let estimatedChargeTimeInSec = '';
          if ('estimatedChargeTimeInS' in res) {
            if (res.estimatedChargeTimeInS == null) {
              estimatedChargeTimeInSec = NOT_APPLICABLE;
            } else if (res.recommended === false && res.estimatedChargeTimeInS === 0) {
              estimatedChargeTimeInSec = NOT_APPLICABLE;
            } else if ('estimatedChargeTimeInS' in res) {
              estimatedChargeTimeInSec = res.estimatedChargeTimeInS;
            }
          }
          this.recommendedRunDetails = {
            runNumber: res.runNumber,
            requiredEnergy: res.energyRequired,
            estimatedChargeTimeInS: estimatedChargeTimeInSec,
            bookOutTime: res.bookOutTime,
            distance: res.distanceInMiles,
            runStatus: res.assigned === ASSIGNED ? res.assigned : res.runNumber ? NOT_ASSIGNED : ''
          };
        }
        this.runType = RECOMMENDED;
      });

    this.hideOverrideSubscription = this.runService.hideRunOverride()
      .subscribe(res => {
        this.hideOverride = res;
      });

    this.clearRecommendedRunSubscription = this.trackService.clearRecommendedRun()
      .subscribe(res => {
        this.recommendedRunDetails = res;
        this.hideOverride = true;
      });

    this.maintenaceSubscription = this.busService.getMaintenaceValue()
      .subscribe(res => {
        if (!res) {
          this.hideOverride = true;
        }
      });

    this.ccssDisplayCommonService.getLogicalChargeQueueSearchBusData()
      .subscribe(res => {
        if (res.length > 0) {
          const obj = res[0];
          this.recommendedRunDetails = {
            runNumber: obj.assignedRunNumber != null ? obj.assignedRunNumber : '',
            requiredEnergy: obj.energyRequired,
            estimatedChargeTimeInS: obj.estimatedChargeTimeInS != null ? obj.estimatedChargeTimeInS : NOT_APPLICABLE,
            bookOutTime: obj.bookOutTime,
            distance: obj.distanceInMiles,
            runStatus: ASSIGNED
          };
          this.hideOverride = true;
          this.runType = ASSIGNED;
        } else {
          this.recommendedRunDetails = {};
        }
      });
  }

  openLoginDialog(event): void {
    this.trackService.setShowTrackList(RUN);
    this.runService.loadAllRuns();
    this.trackService.disableBtns = true;
  }

  ngOnDestroy() {
    this.restSubscription.unsubscribe();
    this.hideOverrideSubscription.unsubscribe();
    this.clearRecommendedRunSubscription.unsubscribe();
    this.maintenaceSubscription.unsubscribe();
  }
}
