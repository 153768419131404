<div class="">
     <div class="incoming-queue">
        <app-incoming-queue></app-incoming-queue>
    </div>
    <div class="row mx-0 recomnd-sec">
        <div class="col-4 recommended vertical-divider">
            <app-bus-details></app-bus-details>
        </div>
        <div class="col-4 recommended vertical-divider">
            <app-recommended-runs></app-recommended-runs>
        </div>
        <div class="col-4 recommended">
            <app-recommended-tracks></app-recommended-tracks>
        </div>
    </div>
    <div class="border-thick "> 
        <div class="row mx-0">
            <div class="col-3 notif-sec bdr-right recommended" *ngIf='showGarage'>
                    <app-notifications></app-notifications>
            </div>
            <div class="col-9 recommended garage-sec" *ngIf='showGarage'>
                    <app-garage-view [blnRightClickContextMenu]= false></app-garage-view>
            </div>            
            <app-bus-track-list class='col-12' *ngIf='showTrackList'></app-bus-track-list>
        </div>
        
    </div>
    
</div>
<app-loader *ngIf='showLoader'></app-loader>

