<div>
    <div class="min-table_height">
        <div class="run_Header">
            <span class="text_Header">Runs</span>
            <div class="cancel_div">
                <div class="form-group search-run-form">
                    <mat-form-field appearance="standard" id="searchRun" class="search-full-width search-field search-run-field">
                       <input matInput type="number" (keyup)="applyFilter($event.target.value)" placeholder="Search Run Id" #input class="run-input">
                    </mat-form-field>                  
                </div>
                <button mat-stroked-button class='button-cancel' (click)='closeRunData()'>Cancel</button>
                <button mat-stroked-button class='button-submit' (click)='onChangeConfirmTrack()'>Confirm</button>
            </div>
        </div>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 runs-table">
            <ng-container matColumnDef="runNumber">
                <th mat-header-cell *matHeaderCellDef class="runID_width"> Run ID </th>
                <td mat-cell *matCellDef="let element">
                    {{element.runNumber}}</td>
            </ng-container>
            <ng-container matColumnDef="recommended">
                <th mat-header-cell *matHeaderCellDef class="rec-width"> </th>
                <td mat-cell *matCellDef="let element">
                    <img *ngIf="element.recommended === true" src="../../assets/images/Recomended_ic.svg">
            </ng-container>
            <ng-container matColumnDef="distance">
                <th mat-header-cell *matHeaderCellDef> Distance </th>
                <td mat-cell *matCellDef="let element"> {{element.distance | mileKiloMeter:'km'}} </td>
            </ng-container>
            <ng-container matColumnDef="reqEnergy">
                <th mat-header-cell *matHeaderCellDef> Required Energy </th>
                <td mat-cell *matCellDef="let element"> {{element.reqEnergy}} </td>
            </ng-container>
            <ng-container matColumnDef="estChargeTime">
                <th mat-header-cell *matHeaderCellDef> Estimate Charge Time </th>
                <td mat-cell *matCellDef="let element"> {{element.estimatedChargeTimeInS | timeInHrs:'HH:mm'}} </td>
            </ng-container>
            <ng-container matColumnDef="bookOutTime">
                <th mat-header-cell *matHeaderCellDef> Book Out Date, Time </th>
                <td mat-cell *matCellDef="let element"> {{element.bookOutTime | date:'MMM dd, HH:mm'}} </td>
            </ng-container>
            <ng-container matColumnDef="bookInTime">
                <th mat-header-cell *matHeaderCellDef> Book In Date, Time </th>
                <td mat-cell *matCellDef="let element"> {{element.bookInTime | date:'MMM dd, HH:mm'}} </td>
            </ng-container>
            <ng-container matColumnDef="selection">
                <th mat-header-cell *matHeaderCellDef>
                    Selection
                </th>
                <td mat-cell *matCellDef="let element">
                    <mat-radio-button name="opList" class="example-radio-button" (click)="$event.stopPropagation()" [checked]="selection.isSelected(element)"
                        (change)="checkChange($event,element)">

                    </mat-radio-button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(element)">
            </tr>
        </table>
    </div>

    <!-- Pagination For Table -->
    <nav class="mt-1">
        <ul *ngIf="pager.pages && pager.pages.length" class="pagination d-flex  justify-content-end flex-wrap pagination-rounded-flat pagination-success">
            <li class="mt-1" [ngClass]="{disabled:pager.currentPage === 1}">
                <a class="page-link-pre non_background" (click)="setPage(pager.currentPage - 1, hasFilterText)">Previous</a>
            </li>
            <p class="pagination-line">|</p>
            <li class="page-item" *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
                <a class="page-link" (click)="setPage(page, hasFilterText)">{{page}}</a>
            </li>
            <p class="pagination-line">|</p>
            <li class="mt-1" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                <a class="page-link-next non_background" (click)="setPage(pager.currentPage + 1, hasFilterText)">Next</a>
            </li>
        </ul>
    </nav>
    <!-- Pagination For Table -->
</div>

<ng-template #runOverrideConformationDialog>
    <div class="confirm_dialog">
        <div mat-dialog-content class="dialogBox">
            <h5 class="dialog_header">Message</h5>
            <h6 class="dialog_content">Successfully confirmed Run override.</h6>
        </div>
        <div mat-dialog-actions class="dialogBoxActions float-right">
            <button mat-raised-button class='button-yellow' (click)='onDoneSubmit()' [mat-dialog-close]>Done</button>
        </div>
    </div>
</ng-template>