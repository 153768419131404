import { Injectable } from '@angular/core';
import { SharedService } from 'src/shared-services/shared.service';
import { RestService } from 'src/shared-services/rest.service';
import { PE_API_UI_TABS, GET_UI_TABS_REQUEST_ID, TENANT_AGREEMENT_DETAILS } from 'src/app-constants/app-constants';
import { Subject, from, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class LoginServiceService {
  private Permissions$ = new Subject<any>();
  constructor(
    private sharedService: SharedService,
    private restService: RestService,
  ) { }

  public setAccessToken(data) {
    localStorage.setItem('token', data);
  }

  public getAccessToken() {
    return localStorage.getItem('token');
  }

  public setRefreshToken(data) {
    localStorage.setItem('refreshToken', data);
  }

  public getRefreshToken() {
    return localStorage.getItem('refreshToken');
  }

  public setLoggedInUserDetails(data) {
    localStorage.setItem('loggedInUserDetails', JSON.stringify(data));
  }

  public getLoggedInUserDetails() {
    return localStorage.getItem('loggedInUserDetails') && JSON.parse(localStorage.getItem('loggedInUserDetails'));
  }

  public logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('loggedInUserDetails');
  }

  getPermission() {
    return this.Permissions$.asObservable();
  }

  public getUITabs() {
    const reqObj = {
      url: PE_API_UI_TABS.get,
      options: {
        params: {
          requestId: this.sharedService.create_UUID(GET_UI_TABS_REQUEST_ID)
        }
      }
    };

    this.restService.get(reqObj)
      .subscribe((res: any) => {
        const actions = this.sharedService.buildUIActionsMap(res.tabs);
        this.sharedService.setUIActionMap(actions);
        this.Permissions$.next(actions);
      },
        err => {
          console.log(err);
        });
  }

  public checkCustomerAgreementStatus(tenantId): Observable<any> {
    const reqObj = {
      url: TENANT_AGREEMENT_DETAILS.get(tenantId),
      options: {}
    };

   return this.restService.get(reqObj);
  }

  public updateCustomerAgreementStatus(tenantId, agreementPayLoad): Observable<any> {
    const reqObj = {
      url: TENANT_AGREEMENT_DETAILS.post(tenantId),
      options: {},
      body: agreementPayLoad
    };
    return this.restService.post(reqObj);
  }
}
