import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoginServiceService } from '../pre-login/login/login.service';
import { SharedService } from '../../shared-services/shared.service';

@Injectable()

export class AuthenticateInterceptorService implements HttpInterceptor {
  constructor(
    private loginService: LoginServiceService,
    private sharedService: SharedService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with basic auth credentials if available
    const currentToken = this.loginService.getAccessToken();
    if (currentToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `${currentToken}`,
          'Content-Type': 'application/json'
        }
      });
    }

    return next.handle(request).pipe(
    );
  }
}
