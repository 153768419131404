import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { observable, timer } from 'rxjs';
import { TrackService } from 'src/shared-services/track.service';
import { HeaderService } from 'src/shared-services/header.service';
import { BusService } from 'src/shared-services/bus.service';
import { CcssDisplayCommonService } from 'src/shared-services/ccss-display-common.service';
import { fakeAsync } from '@angular/core/testing';

@Injectable({
  providedIn: 'root'
})

export class TimerService {
  private time$ = new BehaviorSubject<Date>(new Date());
  private timerRef;
  private timerOneMinRefreshRef;
  private timerTenMinRefreshRef;
  private blnStopRefreshAction: boolean = false;
  private blnRecommendRunAndTrackInProcessAction: boolean = false;
  obsTimer: Observable<number> = timer(0, 60000);
  constructor(private trackService: TrackService,
              private busService: BusService,
              private headerService: HeaderService,
              private ccssService: CcssDisplayCommonService ) {
    this.startTimer();
  }

  get blnStopRefresh(): boolean {
    return this.blnStopRefreshAction;
  }

  set blnStopRefresh(flag: boolean) {
    if (!this.blnRecommendRunAndTrackInProcessAction) {
      this.blnStopRefreshAction = flag;
      if (flag) {
        this.refreshForTenMinutes();
      } else {
        clearTimeout(this.timerTenMinRefreshRef);
      }
    }
  }

  getTime() {
    return this.time$.asObservable();
  }

  set blnRecommendRunAndTrackInProcess(flag: boolean) {
    this.blnRecommendRunAndTrackInProcessAction = flag;
    this.blnStopRefreshAction = flag;
    if (flag) {
      this.refreshForTenMinutes();
    } else {
      clearTimeout(this.timerTenMinRefreshRef);
    }
  }

  startTimer() {
    this.timerRef = setInterval(() => {
      this.time$.next(new Date());
    }, 1000);
  }

  stopTimer() {
    clearInterval(this.timerRef);
  }

  refreshEveryTwoMinute(callBack, obj) {
    if (!this.timerOneMinRefreshRef) {
      this.timerOneMinRefreshRef =  setInterval(() => {
        if (!this.blnStopRefreshAction)  {
          this.refreshAPIs();
        }
      }, 120000);
    }
  }

  refreshForTenMinutes() {
    this.timerTenMinRefreshRef = setTimeout(() => {
      if (this.blnStopRefreshAction) {
        this.blnStopRefreshAction = false;
        this.blnRecommendRunAndTrackInProcessAction = false;
      }
    }, 600000);
  }

  refreshEveryMinuteByRx() {
    this.obsTimer.subscribe(() => {
      this.refreshAPIs();
    });

  }

  refreshAPIs() {
    this.headerService.loadGarages(false);
    this.busService.loadIncomingBusList();
    this.ccssService.loadChargingStates();
    this.trackService.setShowTrackList('garage');
    // this.trackService.loadTrackList();
  }




  refreshEveryHour(callBack, obj) {
    setInterval(() => {
      callBack.call(obj);
    }, 3540000); // refreshing using 59 mins for token expires to get ID Token
  }
}
