import { Injectable } from '@angular/core';
import { Subject, forkJoin } from 'rxjs';
import { SharedService } from './shared.service';
import { RestService } from './rest.service';
import {
  PE_API_CCSS_DISPLAY_CHARGER_HISTORY,
  PE_API_CCSS_DISPLAY_BUS_FAULTS,
  PE_API_CCSS_DISPLAY_CHARGER_FAULTS,
  PE_API_CCSS_DISPLAY_LOGICAL_CHARGE_QUEUE,
  PE_API_CCSS_DISPLAY_BEB_CHARGING_STATIONS,
  PE_API_CCSS_DISPLAY_CHARGING_STATES,
  LOG_ERROR_INCOMING_BUS_QUEUE,
  LOG_ERROR_BUS_FAULTS,
  LOG_ERROR_CHARGER_FAULTS,
  LOG_ERROR_LCQ,
  LOG_ERROR_CHARGING_STATES,
  LOG_ERROR_CHARGE_HISTORY,
  GET_CHARGER_HISTORY_REQUEST_ID,
  GET_BUS_FAULTS_REQUEST_ID,
  GET_CHARGER_FAULTS_REQUEST_ID,
  GET_LOGICAL_CHARGE_QUEUE_REQUEST_ID,
  GET_CHARGING_STATES_REQUEST_ID
} from '../app-constants/app-constants';
import { ExceptionService } from './exception.service';
import { HeaderService } from './header.service';
import { BusService } from './bus.service';
import { retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class CcssDisplayCommonService {

  public logicalQueueSubject = new Subject<any>();

  private busFaults$ = new Subject<any>();
  private busFaults: any;

  private chargerFaults$ = new Subject<any>();
  private chargerFaults: any;

  private logicalChargeQueue$ = new Subject<any>();
  private logicalChargeQueue: any;

  private logicalChargeQueue$SearchBusData = new Subject<any>();
  private logicalChargeQueueSearchBusData: any;

  private bebChargingStations$ = new Subject<any>();
  private bebChargingStations: any;

  private chargeHistory$ = new Subject<any>();
  private chargeHistory: any;

  private chargingStates$ = new Subject<any>();
  private chargingStates: any;


  private formattedChargeHistory$ = new Subject<any>();

  private assetsData: any;

  constructor(
    private sharedService: SharedService,
    private restService: RestService,
    private exceptionService: ExceptionService,
    private busService: BusService,
    private headerService: HeaderService
  ) { }

  loadFaults() {
    this.assetsData = localStorage.getItem('assets') ? JSON.parse(localStorage.getItem('assets')) : [];
    if (this.assetsData) {
      // Filter Assets Based on Garage ID.
      const GarageId = this.headerService.getSelectedGarageId();
      this.assetsData = this.assetsData.filter(assets => {
        if (assets.garageId === GarageId) {
          return assets;
        }
      });
      this.loadBusFaults(this.assetsData);
      this.loadChargerFaults(this.assetsData);
      this.loadChargeHistory(this.assetsData);
    }
  }

  loadChargeHistory(assetsData) {
    const garageId = this.headerService.getSelectedGarageId();
    const tenantID = this.headerService.getTenantId();
    if (garageId === null || garageId === '') { return; }
    if (tenantID === null || tenantID === '') { return; }
    let chargerList = Array.prototype.map.call(assetsData, s => s.ocppId ? s.ocppId : '');
    chargerList = chargerList.filter(obj => obj !== '');
    let allGarageChargers = '';
    if (chargerList.length === 0) {
      this.chargeHistory$.next([]);
      return;
    } else {
      allGarageChargers = chargerList.toString();
    }
    const now = new Date();
    const oneDayAgo = new Date();
    oneDayAgo.setDate(oneDayAgo.getDate() - 1);
    const nowStr =  this.sharedService.date_ISO(now);
    const oneDayAgoStr = this.sharedService.date_ISO(oneDayAgo);
    const reqObj = {
      url: PE_API_CCSS_DISPLAY_CHARGER_HISTORY.get(garageId, tenantID, allGarageChargers),
      options: {
        params: {
          start: oneDayAgoStr,
          end: nowStr,
          requestId: this.sharedService.create_UUID(GET_CHARGER_HISTORY_REQUEST_ID),
        }
      }
    };
    this.restService.get(reqObj).subscribe(res => {
      if (res) {
        const chargerSessionsData = res;
        const data = [];
        Object.keys(chargerSessionsData).forEach(key => {
          const innerObj = chargerSessionsData[key];
          innerObj.forEach(element => {
            data.push(element);
          });
        });
        this.chargeHistory = data;
        this.chargeHistory$.next(Object.assign({}, { data: this.chargeHistory }).data);
      } else {
        this.chargeHistory$.next(Object.assign({}, { data: [] }).data);
      }
    }, err => {
      this.exceptionService.log(LOG_ERROR_CHARGE_HISTORY, err);
    });
  }

  loadBusFaults(assetsData) {
    let vinList = Array.prototype.map.call(assetsData, s => s.vehicleVIN ? s.vehicleVIN : '');
    vinList = vinList.filter(obj => obj !== '');
    const reqObj = {
      url: PE_API_CCSS_DISPLAY_BUS_FAULTS.post,
      options: {
        params: {
          scroll: '5m',
        }
      },
      body: {
        size: 1000,
        query: {
          bool: {
            filter: [
              {
                terms: {
                  busName: vinList ? vinList : []
                }
              }, {
                range: {
                  faultTimestamp: {
                    gte: 'now-7d'
                  }
                }
              }, {
                query_string: {
                  query: 'resolved:false'
                }
              }
            ]
          }
        }
      }
    };

    this.restService.post(reqObj)
      .subscribe(res => {
        this.busFaults = res;
        this.updateBusFaults(this.busFaults);
      }, err => {
        this.exceptionService.log(LOG_ERROR_BUS_FAULTS, err);
      });
  }

  loadChargerFaults(assetsData) {
    let chargerList = Array.prototype.map.call(assetsData, s => s.ocppId ? s.ocppId : '');
    chargerList = chargerList.filter(obj => obj !== '');
    const reqObj = {
      url: PE_API_CCSS_DISPLAY_CHARGER_FAULTS.post,
      options: {
        params: {
          scroll: '5m',
          requestId: this.sharedService.create_UUID(GET_CHARGER_FAULTS_REQUEST_ID)
        }
      },
      body: {
        size: 1000,
        query: {
          bool: {
            filter: [
              {
                terms: {
                  chargerName: chargerList ? chargerList : []
                }
              }, {
                term: {
                  'request.status': 'Faulted'
                }
              }, {
                range: {
                  '@timestamp': {
                    gte: 'now-7d'
                  }
                }
              }
            ]
          }
        }
      }
    };

    this.restService.post(reqObj)
      .subscribe(res => {
        this.chargerFaults = res;
        this.updateChargerFaults(this.chargerFaults);
      },
        err => {
          this.exceptionService.log(LOG_ERROR_CHARGER_FAULTS, err);
        });
  }

  loadLogicalChargeQueue() {
    const GarageId = this.headerService.getSelectedGarageId();
    const tenantID = this.headerService.getTenantId();
    if (GarageId === null || GarageId === '') { return; }
    if (tenantID === null || tenantID === '') { return; }
    const reqObj = {
      url: PE_API_CCSS_DISPLAY_LOGICAL_CHARGE_QUEUE.get(GarageId, tenantID),
      options: {
        params: {
          requestId: this.sharedService.create_UUID(GET_LOGICAL_CHARGE_QUEUE_REQUEST_ID)
        }
      },
      body: {}
    };

    this.restService.get(reqObj)
      .subscribe(res => {
        this.logicalChargeQueue = res;
        this.updateLogicalChargeQueue(this.logicalChargeQueue);
      }, err => {
        this.exceptionService.log(LOG_ERROR_LCQ, err);
      });
  }

  loadLogicalChargeQueueSearchBus(busVin) {
    const FILTERBUSVIN = busVin.toString().toLowerCase();
    const GarageId = this.headerService.getSelectedGarageId();
    const tenantId = this.headerService.getTenantId();
    if (GarageId === null || GarageId === '') { return; }
    if (tenantId === null || tenantId === '') { return; }
    const reqObj = {
      url: PE_API_CCSS_DISPLAY_LOGICAL_CHARGE_QUEUE.get(GarageId, tenantId),
      options: {
        params: {
          requestId: this.sharedService.create_UUID(GET_LOGICAL_CHARGE_QUEUE_REQUEST_ID)
        }
      },
      body: {}
    };

    this.restService.get(reqObj)
      .subscribe(
        res => {
        const logicalChargeQueueSearchBusData = this.logicalChargeQueue;
        this.logicalChargeQueueSearchBusData = logicalChargeQueueSearchBusData.filter
          (bus => bus.busVIN ? bus.busVIN.toLowerCase().indexOf(FILTERBUSVIN) > -1 : false);
        this.updateLogicalChargeQueueSearchBusData(this.logicalChargeQueueSearchBusData);
      }, err => {
        this.exceptionService.log(LOG_ERROR_LCQ, err);
      });
  }

  loadBebChargingStations() {
    const GarageId = this.headerService.getSelectedGarageId();
    if (GarageId === null || GarageId === '') { return; }
    const reqObj = {
      url: PE_API_CCSS_DISPLAY_BEB_CHARGING_STATIONS.get(GarageId),
      options: {},
      body: {}
    };

    this.restService.get(reqObj)
      .subscribe(res => {
        this.bebChargingStations = res;
        this.updateBebChargingStations(this.bebChargingStations);
      }, err => {
        this.updateBebChargingStations([]);
        this.exceptionService.log(LOG_ERROR_LCQ, err);
      });
  }

  loadChargingStates() {
    const garageId = this.headerService.getSelectedGarageId();
    const tenantId = this.headerService.getTenantId();
    if (garageId === null || garageId === '') { return; }
    if (tenantId === null || tenantId === '') { return; }
    const reqObj = {
      url: PE_API_CCSS_DISPLAY_CHARGING_STATES.get(garageId, tenantId),
      options: {
        params: {
          requestId: this.sharedService.create_UUID(GET_CHARGING_STATES_REQUEST_ID)
        }
      },
      body: {}
    };

    this.restService.get(reqObj)
      .subscribe(res => {
        this.chargingStates = res;
        this.updateChargingStates(this.chargingStates);
      }, err => {
        this.updateChargingStates([]);
        this.exceptionService.log(LOG_ERROR_CHARGING_STATES, err);
      });
  }

  getChargeHistory() {
    return this.chargeHistory$.asObservable();
  }

  getChargeHistoryFormattedData() {
    return this.formattedChargeHistory$.asObservable();
  }

  chargeHistoryFormattedData(data) {
    return this.formattedChargeHistory$.next(data);
  }

  getBusFaults() {
    return this.busFaults$.asObservable();
  }

  getChargerFaults() {
    return this.chargerFaults$.asObservable();
  }

  getLogicalChargeQueue() {
    return this.logicalChargeQueue$.asObservable();
  }

  getBebChargingStations() {
    return this.bebChargingStations$.asObservable();
  }

  getChargingStates() {
    return this.chargingStates$.asObservable();
  }

  getLogicalChargeQueueSearchBusData() {
    return this.logicalChargeQueue$SearchBusData.asObservable();
  }

  public logicalQueueFormattedData(data: any) {
    this.logicalQueueSubject.next(data);
  }

  public getLogicalQueueFormattedData() {
    return this.logicalQueueSubject.asObservable();
  }

  private updateBusFaults(dataVal) {
    this.busFaults$.next(Object.assign({}, { data: dataVal }).data);
  }

  private updateChargerFaults(dataVal) {
    this.chargerFaults$.next(Object.assign({}, { data: dataVal }).data);
  }

  private updateLogicalChargeQueue(dataVal) {
    this.logicalChargeQueue$.next(Object.assign({}, { data: dataVal }).data);
    localStorage.removeItem('LogicalQueueData');
    localStorage.setItem('LogicalQueueData', JSON.stringify(dataVal));
  }

  updateLogicalChargeQueueSearchBusData(dataVal) {
    this.logicalChargeQueue$SearchBusData.next(Object.assign({}, { data: dataVal }).data);
  }

  clearLogicalChargeQueueSearchBusData() {
    this.logicalChargeQueue$SearchBusData.next({});
  }

  private updateBebChargingStations(dataVal) {
    this.bebChargingStations$.next(Object.assign({}, { data: dataVal }).data);
  }

  private updateChargingStates(dataVal) {
    this.chargingStates$.next(Object.assign({}, { data: dataVal }).data);
  }

  clearLoadFaults() {
    this.chargeHistory$.next(Object.assign({}, { data: [] }).data);
    this.updateBusFaults([]);
    this.updateChargerFaults([]);
    this.updateChargingStates([]);
    this.updateLogicalChargeQueue([]);
  }
}
