<div class="row mx-0">
    <div class='col-1 customCol-15'>
        <form>
            <mat-form-field class="search-full-width search-field">
                <mat-icon matPrefix></mat-icon>
                <input matInput placeholder="Search BEB" aria-label="Search BEB" type='text' [matAutocomplete]="auto"
                    [formControl]="searchCtrl">
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)='onSelectBusId($event)'>
                    <mat-option *ngFor="let bus of filteredBusList | async" [value]="bus.busName">
                        <span>{{bus.busName}}</span>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </form>
    </div>
    <div class="incoming-queue-bus col-11 customCol-85">
        <div *ngIf='!emptyIncomingQ'>
            <mat-tab-group [selectedIndex]="selectedBusIndex" (selectedTabChange)='onBusTabSelection($event)'>
                <mat-tab></mat-tab>
                <mat-tab [disabled] = "incomingTabGroupDisabled" *ngFor='let bus of busList'>
                    <ng-template mat-tab-label>
                        <span class='bus-time'>{{bus.bookInTime | date:'HH:mm'}}</span>
                        <span class='bus-id'>{{bus.busName ? bus.busName: bus.vehicleName2}}</span>
                        <span class="maintenance-icon" [ngClass]="{'maintenance-icon-active':false}"></span>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </div>
        <div class='empty-incoming-queue' *ngIf="emptyIncomingQ">No buses are in queue</div>
    </div>
</div>