<div class="">
    <div class="incoming-queue">
        <app-incoming-queue></app-incoming-queue>
    </div>
    <div class="row mx-0 recomnd-sec">
        <div class="col-4 recommended vertical-divider">
            <app-bus-details></app-bus-details>
        </div>
        <div class="col-4 recommended vertical-divider">
            <app-recommended-runs></app-recommended-runs>
        </div>
        <div class="col-4 recommended">
            <app-recommended-tracks></app-recommended-tracks>
        </div>
    </div>
    <div class="border-thick app_Background bottom-sec">
        <div class="row mx-0">
            <div class="col-2 notif-sec bdr-right recommended pr-1" *ngIf='showGarage'>
                <app-alerts></app-alerts>
            </div>
            <div class="col-2 notif-sec bdr-right recommended pl-1" *ngIf='showGarage'>
                <app-notifications></app-notifications>
            </div>
            <div class="col-8 recommended garage-sec" *ngIf='showGarage'>
                <div class="garage">
                    <app-garage-view [blnRightClickContextMenu]= false></app-garage-view>
                </div>
                <div class="legend-block">
                    <app-garagelegend></app-garagelegend>
                </div>
            </div>            
            <app-bus-track-list class='col-12' *ngIf='showTrackList'></app-bus-track-list>
            <app-bus-run-list class='col-12' *ngIf='showRunList'></app-bus-run-list>
        </div>
    </div>
</div>