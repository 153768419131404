import { Injectable } from '@angular/core';
import { LoginServiceService } from '../pre-login/login/login.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AuthGuardService implements CanActivate {

  constructor(
    private loginService: LoginServiceService,
    private router: Router
    ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const userdata = this.loginService.getAccessToken();
    if (userdata) {
      return true;
    } else {
      this.router.navigate(['']);
    }
  }
}
