<div class="login-popup">
  <h1 mat-dialog-title>{{logindialogTittle}}</h1>
  <div mat-dialog-content>
    <mat-form-field class='full-width'>
      <input matInput type='text' placeholder='Username' [(ngModel)]='username' name='username'>
    </mat-form-field>
    <mat-form-field class='full-width'>
          <input matInput class='full-width' type='password' [(ngModel)]='password' placeholder='Password' name='password'>
    </mat-form-field>          
    <div class='login-error' *ngIf="inValidUser">{{errorMessage}}</div>
  </div>
  <div mat-dialog-actions class='float-right'>
      <button mat-stroked-button class='button-cancel' (click) = 'onCancel()' type='reset'>Cancel</button>
    <button mat-raised-button class='button-yellow' type='submit' (click)='onSubmit()'>Proceed</button>
  </div>
</div>