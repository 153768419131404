import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kWhMileTokWhKilometer'
})

export class KWhMileTokWhKilometer implements PipeTransform {

  transform(value: any, metric: string): any {
    const VAL = parseFloat(value);
    const result = (VAL > -1) ? (VAL * 0.621371).toFixed(1) : '';
    return  result ? result + ' ' + metric : '';
  }

}
