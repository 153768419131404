import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { Subject } from 'rxjs';
import { SharedService } from './shared.service';
import {
  PE_API_NOTIFICATIONS,
  PE_API_ALERTACTION,
  LOG_ERROR_FETCH_NOTIFICATIONS,
  LOG_ERROR_TRIGGER_NOTIFICATION,
  TOAST_SUCCESS,
  TOAST_NOTIFICATION_TRIGGER_SUCCESS,
  GET_NOTIFICATIONS_REQUEST_ID,
  NOTIFICATION_ACTION_REQUEST_ID
} from '../app-constants/app-constants';
import { ExceptionService } from './exception.service';
import { HeaderService } from './header.service';
import { LoginServiceService } from 'src/app/pre-login/login/login.service';
import { ChargerService } from './charger.service';
import { CcssDisplayCommonService } from './ccss-display-common.service';

@Injectable({
  providedIn: 'root'
})

export class NotificationsService {
  private notifications$ = new Subject<any>();
  private notificationResponseHandler$ = new Subject<{ message: string, type: string }>();
  constructor(
    private restService: RestService,
    private sharedService: SharedService,
    private expectionService: ExceptionService,
    private headerService: HeaderService,
    private loginService: LoginServiceService,
    private chargerService: ChargerService,
    private ccssDisplayCommonService: CcssDisplayCommonService
  ) { }

  loadNotifications() {
    const USERDATA = this.loginService.getAccessToken();
    if (USERDATA) {
      const TenantId = this.headerService.getTenantId();
      const GarageId = this.headerService.getSelectedGarageId();
      if (GarageId === null || GarageId === '') { this.notifications$.next({}); return; }
      if (TenantId === null || TenantId === '') { return; }
      const reqObj = {
        url: PE_API_NOTIFICATIONS.get(TenantId),
        options: {
          params: {
            trackSetupId: GarageId,
            notificationState: 'TRIGGERED',
            requestId: this.sharedService.create_UUID(GET_NOTIFICATIONS_REQUEST_ID),
          }
        }
      };

      this.restService.get(reqObj)
        .subscribe(res => {
          this.notifications$.next(Object.assign({}, { data: res }).data);
        }, err => {
          this.expectionService.log(LOG_ERROR_FETCH_NOTIFICATIONS, err);
        });
    }
  }

  getNotificationResponseTypeHandler() {
    return this.notificationResponseHandler$.asObservable();
  }

  triggerNotificationAction(notificationObj) {
    // identify associated charger in chargerStates for additional relevant fields
    const chargingStates = JSON.parse(localStorage.getItem('chargerStates'));
    for (const key in chargingStates) {
      if (chargingStates.hasOwnProperty(key)) {
        const chargingState = chargingStates[key];
        if (chargingState.chargerName === notificationObj.ocppId) {
          notificationObj.chargerIP = this.chargerService.getChargerFromShadowOrAssets
                                      (chargingState.chargerName, 'ocppLocalIPV4', chargingState.ocppLocalIPV4);
          notificationObj.chargerType = this.chargerService.getChargerFromShadowOrAssets
                                      (chargingState.chargerName, 'chargerType', chargingState.chargerType);
          notificationObj.chargerVendor = this.chargerService.getChargerFromShadowOrAssets
                                      (chargingState.chargerName, 'chargerVendor', chargingState.chargerVendor);
          notificationObj.transactionId = this.chargerService.getChargerFromShadowOrAssets
                                      (chargingState.chargerName, 'ocppTransactionId', chargingState.ocppTransactionId);
          notificationObj.chargerPositionId = this.chargerService.getChargerPositionIDFromTrackSetup(notificationObj.ocppId);
          break;
        }
      }
    }
    const GarageId = this.headerService.getSelectedGarageId();
    const TenantId = this.headerService.getTenantId();
    if (GarageId === null || GarageId === '') { return; }
    if (TenantId === null || TenantId === '') { return; }
    const reqObj = {
      url: PE_API_ALERTACTION.post(GarageId, TenantId, notificationObj.notificationId),
      options: {},
      body: {
        id: this.sharedService.create_UUID(NOTIFICATION_ACTION_REQUEST_ID),
        notification: notificationObj
      }
    };

    this.restService.post(reqObj)
      .subscribe((res: any) => {
        this.loadNotifications();
        this.chargerService.changeChargerIcon$.next(notificationObj.ocppId);
        this.notificationResponseHandler$.next(Object.assign({},
          {
            data: {
              type: TOAST_SUCCESS,
              message: res.message
            }
          }).data);
      }, err => {
        this.expectionService.log(LOG_ERROR_TRIGGER_NOTIFICATION, err);
      });
  }

  getNotifications() {
    return this.notifications$.asObservable();
  }

  clearNotifications() {
    this.notifications$.next(Object.assign({}, { data: [] }).data);
  }
}
