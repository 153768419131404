import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  PE_API_INCOMING_QUEUE,
  PE_API_BUS_DETAILS,
  PE_API_CUSTOMER_ASSESTS,
  PE_API_NEEDMAINTENANCE,
  LOG_ERROR_INCOMING_BUS_QUEUE,
  LOG_ERROR_CUSTOMER_ASSETS,
  LOG_ERROR_BUS_MAINTENANCE,
  LOG_ERROR_BUS_DETAILS,
  RUN_TRACK_ASSIGNMENT_ROUTE,
  GET_INCOMING_BUS_QUEUE_REQUEST_ID,
  GET_CUSTOMER_ASSETS_REQUEST_ID,
  GET_BUS_DETAILS_REQUEST_ID,
  GET_M5_BUS_STATUS_REQUEST_ID,
  NOT_APPLICABLE
} from '../app-constants/app-constants';
import { SharedService } from './shared.service';
import { RestService } from './rest.service';
import { ExceptionService } from './exception.service';
import { HeaderService } from './header.service';
import { LoginServiceService } from 'src/app/pre-login/login/login.service';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { RoundNumberPipe } from 'src/shared-pipes/round-number.pipe';

@Injectable({
  providedIn: 'root'
})

export class BusService {
  private busList$ = new Subject<any>();
  private busList: any;
  private selectedBusDetails$ = new Subject<any>();
  private selectedBusDetails: any;
  private hoverBusDetails: any;
  public customerAssets: any;
  private allBusDetails: any;
  private maintenaceValue$ = new Subject<boolean>();
  public maintenanceData: any;
  private maintenanceData$ = new Subject<any>();
  private searchBusMaintenanceData: any;
  private searchBusMaintenanceData$ = new Subject<any>();
  private assestsBusList$ = new Subject<any>();
  private incomingBusLength$ = new Subject<number>();
  private incomingBusData = [];
  maintenaceFromTracksetup = false;
  public isMaintenanceRequired = false;

  private allBusDataDetails: any;
  private allBusDataDetails$ = new Subject<any>();

  constructor(
    private sharedService: SharedService,
    private restService: RestService,
    private expectionService: ExceptionService,
    private headerService: HeaderService,
    private loginService: LoginServiceService,
    private router: Router,
    private roundNumber: RoundNumberPipe
  ) { }

  loadIncomingBusList() {
    const GarageId = this.headerService.getSelectedGarageId();
    const TenantId = this.headerService.getTenantId();
    if (GarageId === null || GarageId === '') { this.incomingBusLength$.next(0); return; }
    if (TenantId === null || TenantId === '') { return; }
    const reqObj = {
      url: PE_API_INCOMING_QUEUE.get(TenantId, GarageId),
      options: {
        params: {
          requestId: this.sharedService.create_UUID(GET_INCOMING_BUS_QUEUE_REQUEST_ID),
          size: '11',
          sortBy: 'bookInTime',
          order: 'ASC'
        }
      }
    };

    this.restService.get(reqObj)
      .subscribe((res: any) => {
        this.incomingBusData = [];
        this.busList = res.content;
        this.incomingBusLength$.next(res.totalElements);
        this.appendBUSDETAILSCA_To_IQ();
        this.busList$.next(this.busList);
        const busVins = this.sharedService.getAllBusVinInGarage();
        this.busList.map(icObj => {
          if (icObj.vin) {
            busVins.push(icObj.vin);
          }
          if (icObj.busName) {
            this.incomingBusData.push(icObj.busName);
          }
        });
        if (this.router.url === RUN_TRACK_ASSIGNMENT_ROUTE) {
          this.loadSelectedBusNeedMaintenance(this.incomingBusData);
        }
        this.addAttributeFromBUSDETAILS(busVins, 'PCes_usi_DashSoC_pct');
      },
        err => {
          this.expectionService.log(LOG_ERROR_INCOMING_BUS_QUEUE, err);
        });
  }

  loadCustomerAssests() {
    const USERDATA = this.loginService.getAccessToken();
    if (USERDATA) {
      const TenantId = this.headerService.getTenantId();
      if (_.isEmpty(TenantId)) { return; }
      const reqObj = {
        url: PE_API_CUSTOMER_ASSESTS.get(TenantId),
        options: {
          requestId: this.sharedService.create_UUID(GET_CUSTOMER_ASSETS_REQUEST_ID),
        }
      };

      this.restService.get(reqObj)
        .subscribe((res: any) => {
          this.customerAssets = res.content;
          // this.loadSOCFromBusVIN();
          this.assestsBusList$.next(this.customerAssets);
          localStorage.setItem('assets', JSON.stringify(this.customerAssets));
          this.sharedService.getAllChargersFromAssets(this.customerAssets);
        }, err => {
          this.expectionService.log(LOG_ERROR_CUSTOMER_ASSETS, err);
        });
    }
  }

  addAttributeFromBUSDETAILS(busVINVal, requiredParam) {
    const TenantId = this.headerService.getTenantId();
    const GarageId = this.headerService.getSelectedGarageId();
    if (_.isEmpty(GarageId) || _.isEmpty(TenantId) || _.isEmpty(busVINVal)) { this.allBusDataDetails$.next({}); return; }
    const reqObj = {
      url: PE_API_BUS_DETAILS.post(GarageId, TenantId),
      options: {},
      body: {
        id: this.sharedService.create_UUID(GET_BUS_DETAILS_REQUEST_ID),
        busVins: busVINVal ? busVINVal : [],
        getBusFaults: true
      }
    };
    this.restService.post(reqObj)
      .subscribe((res: any) => {
        if (res) {
          const allSocData = res.buses;
          this.getAllSocData(allSocData, requiredParam);
          this.allBusDataDetails = allSocData;
          this.allBusDataDetails$.next(Object.assign({}, { data: allSocData }).data);
        }
      });
  }

  loadBusDetailsSearchedBus(busVINVal, requiredParam, busNameVal, busFromVal) {
    const TenantId = this.headerService.getTenantId();
    const GarageId = this.headerService.getSelectedGarageId();
    if (_.isEmpty(GarageId) || _.isEmpty(TenantId) || _.isEmpty(busVINVal)) { this.selectedBusDetails$.next({}); return; }
    const reqObj = {
      url: PE_API_BUS_DETAILS.post(GarageId, TenantId),
      options: {},
      body: {
        id: this.sharedService.create_UUID(GET_BUS_DETAILS_REQUEST_ID),
        busVins: [busVINVal],
        getBusFaults: true
      }
    };
    this.restService.post(reqObj)
      .subscribe((res: any) => {
        if (res) {
          const allSocData = res.buses;
          this.selectedBusDetails = { ...allSocData[busVINVal], busName: busNameVal, busFrom: busFromVal };
          this.selectedBusDetails$.next(Object.assign({}, { data: this.selectedBusDetails }).data);
        }
      });
  }

  getSOCValue(value) {
    return _.isEmpty(value) ?
      this.roundNumber.transform(value, 0) + ' %' : NOT_APPLICABLE;
  }


  getAllSocData(data, requiredParam) {
    this.allBusDetails = [];
    const responseBusData = [];
    Object.keys(data).forEach(key => responseBusData.push(data[key]));
    responseBusData.map(obj => {
      if (obj && obj._source) {
        let soc = 'N/A';
        let source = obj._source;
        if (source.Ignition_Discrete) {
          if (parseInt(source.Ignition_Discrete) >= 1) {
            soc = this.getSOCValue(source.PCes_usi_DashSoC_pct);
          } else {
            soc = NOT_APPLICABLE;
          }
        } else {
          if (parseInt(source.PCbo_bo_PTHVReady) >= 1) {
            soc = this.getSOCValue(source.PCes_usi_DashSoC_pct);
          } else {
            soc = NOT_APPLICABLE;
          }
        }

        const foundObj = {
          busVIN: obj._id,
          SOC: soc
        };
        this.allBusDetails.push(foundObj);
      }
    });
  }

  loadSOCFromBusVIN() {
    this.allBusDetails = [];
    const vinsList = [];
    if (this.customerAssets) {
      this.customerAssets.forEach((caBus, i1) => {
        if (caBus.vehicleVIN !== undefined) {
          vinsList.push(caBus.vehicleVIN);
        }
      });
      this.addAttributeFromBUSDETAILS(vinsList, 'PCes_usi_DashSoC_pct');
    }
  }

  // run & track get bus details
  loadSelectedBusDetails(data, busId, busNameVal, busFromVal) {
    let busData = [];
    busData = this.getLoadSelectedBusDetails(data, busId, busNameVal, busFromVal);
    this.selectedBusDetails = { ...busData, busName: busNameVal, busFrom: busFromVal };
    this.selectedBusDetails$.next(Object.assign({}, { data: this.selectedBusDetails }).data);
  }

  onSelectBusDetails(busId, busNameVal, busFromVal) {
    let busData = [];
    if (this.router.url === RUN_TRACK_ASSIGNMENT_ROUTE) {
      busData = this.getLoadSelectedBusDetails(this.allBusDataDetails, busId, busNameVal, busFromVal);
      this.selectedBusDetails = { ...busData, busName: busNameVal, busFrom: busFromVal };
      this.selectedBusDetails$.next(Object.assign({}, { data: this.selectedBusDetails }).data);
    }
  }

  getLoadSelectedBusDetails(data, busId, busNameVal, busFromVal) {
    if (data !== undefined && data[busId] !== undefined) {
      return data[busId];
    } else {
      this.loadBusDetailsSearchedBus(busId, 'PCes_usi_DashSoC_pct', busNameVal, 'assets');
    }
  }

  loadSelectedBusNeedMaintenance(BUSDETAILS) {
    const Garage = this.headerService.getSelectedGarage();
    const TenantId = this.headerService.getTenantId();
    const busUnitId = BUSDETAILS;
    if (_.isEmpty(Garage) || _.isEmpty(TenantId) || _.isEmpty(BUSDETAILS)) { this.maintenanceData$.next({}); return; }
    const reqObj = {
      url: PE_API_NEEDMAINTENANCE.post(Garage.trackSetupId, TenantId),
      options: {},
      body: {
        id: this.sharedService.create_UUID(GET_M5_BUS_STATUS_REQUEST_ID),
        busUnitIds: busUnitId
      }
    };

    this.restService.post(reqObj)
      .subscribe(res => {
        let busResponse: any;
        busResponse = res;
        this.maintenanceData = busResponse.busStatuses;
        this.maintenanceData$.next(Object.assign({}, { data: this.maintenanceData }).data);
      },
        err => {
          this.expectionService.log(LOG_ERROR_BUS_DETAILS, err);
        });
  }

  loadSearchedBusNeedMaintenance(BUSDETAILS) {
    const Garage = this.headerService.getSelectedGarage();
    const TenantId = this.headerService.getTenantId();
    const busUnitId = BUSDETAILS;
    if (_.isEmpty(Garage) || _.isEmpty(TenantId) || _.isEmpty(busUnitId)) { this.searchBusMaintenanceData$.next({}); return; }
    const reqObj = {
      url: PE_API_NEEDMAINTENANCE.post(Garage.trackSetupId, TenantId),
      options: {},
      body: {
        id: this.sharedService.create_UUID(GET_M5_BUS_STATUS_REQUEST_ID),
        busUnitIds: [busUnitId]
      }
    };

    this.restService.post(reqObj)
      .subscribe(res => {
        let busResponse: any;
        busResponse = res;
        this.searchBusMaintenanceData = busResponse.busStatuses ? busResponse.busStatuses[busUnitId] : 'Not Required';
        this.searchBusMaintenanceData$.next(Object.assign({}, { data: this.searchBusMaintenanceData }).data);
      },
        err => {
          this.expectionService.log(LOG_ERROR_BUS_DETAILS, err);
        });
  }

  removeAssignedBusFromIQ(busVin) {
    this.busList.forEach((bus, i) => {
      if (bus.vin === busVin) {
        this.busList.splice(i, 1);
        if (this.busList[i]) {
          this.onSelectBusDetails(this.busList[i].vin, this.busList[i].busName, 'IQ');
        } else {
          this.selectedBusDetails$.next({});
        }
      }
    });
    this.busList$.next(Object.assign({}, { data: this.busList }).data);
  }

  updateBookInTimes() {
    //   const _GarageId = GarageId;
    //   const _url = PE_API_INCOMING_QUEUE.post.beforeGarageId + _GarageId + PE_API_INCOMING_QUEUE.post.afterGarageid;
    //   const _options = {headers:{'authorization':this.token,"Content-type":"application/json"}}

    //   this.busList.forEach(element => {
    //     const second = 1000;
    //     const minute = second * 60;
    //     const hour = minute * 60;
    //     const randomBookInTimeOffset = Math.floor(Math.random() * hour) + minute;
    //     const bookInTime = Date.now() + randomBookInTimeOffset
    //     const _body = {"vin": element.vin, "bookInTime": bookInTime}
    //     this.http.post(_url,_body,_options)
    //     .subscribe(res =>{
    //       const successMessage = {"successMsg": "Updated Book In Time for bus: " + element.busName}
    //       this._GarageBusTrackList.next(Object.assign({},{data:successMessage}).data);
    //     });
    //   })
  }

  nextAssignedBusFromIQ(busVin) {
    if (this.busList.length > 0) {
      this.busList.forEach((bus, i) => {
        if (bus.vin === busVin) {
          this.onSelectBusDetails(this.busList[i + 1].vin, this.busList[i + 1].busName, 'IQ');
        } else {
          this.selectedBusDetails$.next(Object.assign({}, { data: {} }).data);
        }
      });
    }
  }

  clearBusDetails() {
    this.selectedBusDetails$.next(Object.assign({}, { data: {} }).data);
  }

  getIsMaintenaneRequired() {
    return this.isMaintenanceRequired;
  }

  setIsMaintenanceRequired(value:boolean) {
    this.isMaintenanceRequired = value;
  }

  toggleMaintenace(value) {
    this.maintenaceValue$.next(value);
    this.setIsMaintenanceRequired(value);
  }

  isMaintenanceFromTrackssetup(value) {
    this.maintenaceFromTracksetup = value;
    this.maintenaceValue$.next(value);
  }

  getAllBusDataDetails() {
    return this.allBusDataDetails$.asObservable();
  }

  getBusList() {
    return this.busList$.asObservable();
  }

  getAssetsBusList() {
    return this.assestsBusList$.asObservable();
  }

  getSelectedBusDetails() {
    return this.selectedBusDetails$.asObservable();
  }

  getSelectedBusMetaData() {
    return this.selectedBusDetails._source;
  }

  getMaintenaceValue() {
    return this.maintenaceValue$.asObservable();
  }

  getMaintenanceData() {
    return this.maintenanceData$.asObservable();
  }

  getSearchedBusMaintenanceData() {
    return this.searchBusMaintenanceData$.asObservable();
  }

  getIncomingQueueLength() {
    return this.incomingBusLength$.asObservable();
  }

  getSOCByBUSVIN(busVIN, key) {
    const allBusVinsData = this.allBusDetails;
    let returnValue = '';
    if (allBusVinsData && allBusVinsData.length > 0) {
      const BUSDETAILS = allBusVinsData.filter(bus => bus.busVIN === busVIN);
      if (BUSDETAILS && BUSDETAILS.length !== 0) {
        returnValue = (BUSDETAILS[0][key] !== null && BUSDETAILS[0][key] !== undefined) ? BUSDETAILS[0][key] : '';
      }
    }
    return returnValue;
  }

  getAttributeFromBUSDETAILS(busVIN, keyValue) {
    let returnValue = '';
    const TenantId = this.headerService.getTenantId();
    const GarageId = this.headerService.getSelectedGarageId();
    if (_.isEmpty(GarageId) || _.isEmpty(TenantId) || _.isEmpty(busVIN)) { return; }
    const reqObj = {
      url: PE_API_BUS_DETAILS.post(GarageId, TenantId),
      options: {},
      body: {
        id: this.sharedService.create_UUID(GET_BUS_DETAILS_REQUEST_ID),
        busVins: busVIN ? [busVIN] : [],
        getBusFaults: true
      }
    };

    this.restService.post(reqObj)
      .subscribe(res => {
        let responseData: any;
        responseData = res;
        const busData = responseData.buses[busVIN] ? responseData.buses[busVIN] : {};
        this.hoverBusDetails = busData;
      }, err => {
        this.expectionService.log(LOG_ERROR_BUS_MAINTENANCE, err);
      });
    if (this.hoverBusDetails) {
      if (this.hoverBusDetails._source[keyValue] !== '') {
        returnValue = this.hoverBusDetails._source[keyValue] + '%';
      }
    }
    return returnValue;
  }

  private appendBUSDETAILSCA_To_IQ() {
    if (this.busList && this.customerAssets) {
      this.busList.forEach((bus, i) => {
        this.customerAssets.forEach((caBus, i1) => {
          if (caBus.vehicleVIN === bus.vin) {
            bus.busName = caBus.vehicleName2 || caBus.vehicleName1;
            bus.inService = caBus.inService;
            bus.vehicleName2 = caBus.vehicleName2;
            return;
          }
        });
      });
    }
  }

  clearIncomingQueue() {
    this.busList$.next(Object.assign({}, { data: [] }).data);
  }
}
