<div class='col-12 heading' ><span>{{runType}} Run</span></div>
<div class='row' >
    <div class='item col-4'>
        <div class='title'>Run ID</div>
        <div class='data'>{{recommendedRunDetails.runNumber}}</div>
    </div>
    <div class='item col-4'>
        <div class='title'>Required Energy</div>
        <div class='data'>{{(recommendedRunDetails.requiredEnergy) ? (recommendedRunDetails.requiredEnergy | roundNumber: 0) + ' kWh':''}} </div>
    </div>
    <div class='item col-4'>
        <div class='title'>Estimated Charge Time</div>
        <div class='data'>{{recommendedRunDetails.estimatedChargeTimeInS | timeInHrs:'HH:mm'}}</div>
    </div>
</div>
<div class='row' >
    <div class='item col-4'>
        <div class='title'>Book Out Date, Time</div>
        <div class='data'>{{recommendedRunDetails.bookOutTime | date:'MMM dd, HH:mm' }}</div>
    </div>
    <div class='item col-4'>
        <div class='title'>Distance</div>
        <div class='data'>{{recommendedRunDetails.distance | mileKiloMeter:'km'}}</div>
    </div>
    <div class='item col-4'>
        <div class='title'>Run Status</div>
        <div class='data'>{{recommendedRunDetails.runStatus}}</div>
    </div>
</div>
<div class='row'>
    <div class='bottom-button-panel col-12' >
        <button mat-flat-button routerLink="" [hidden]="hideOverride" [disabled]="disableBtns" (click)='openLoginDialog($event)' class='override'>Override</button>
    </div>
</div>