import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from '../../app/material-module';
import { MatInputModule } from '@angular/material/input';
import { RestService } from 'src/shared-services/rest.service';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BusDetailsComponent } from './bus-details.component';
import { BusIdPipe } from 'src/shared-pipes/bus-id.pipe';
import { MileKiloMeterPipe } from 'src/shared-pipes/mile-kilo-meter.pipe';
import { KWhMileTokWhKilometer } from 'src/shared-pipes/kWh-per-mile-to-kWh-per-km';
import { RoundNumberPipe } from 'src/shared-pipes/round-number.pipe';

@NgModule({
  declarations: [
    BusDetailsComponent,
    BusIdPipe,
    MileKiloMeterPipe,
    KWhMileTokWhKilometer,
    RoundNumberPipe
  ],
  imports: [
    AppMaterialModule,
    MatInputModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [RestService],
  exports: [
    BusDetailsComponent,
    CommonModule
  ]
})

export class AppBusDetailsModule { }
