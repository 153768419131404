import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';

import { NotificationsService } from 'src/shared-services/notifications.service';
import { CcssDisplayCommonService } from 'src/shared-services/ccss-display-common.service';
import {
  RUN_TRACK_ASSIGNMENT_ROUTE,
  ALERT_ACTIONS_ACTION,
  ENABLE,
  INITIATE_CHARGING,
  ABB_CHARGER,
  CHARGING
} from 'src/app-constants/app-constants';
import { SharedService } from 'src/shared-services/shared.service';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})

export class AlertsComponent implements OnInit, OnDestroy {
  private notificationSubscription: any;
  private initiateCharging = [];
  private chargers: any = {};
  private chargingBuses: any = [];
  notifications: any = [];
  notificationCount = '0';
  blnEnableAlertAction = true;
  intActionMethodButtonCount = 0;
  currentPage = RUN_TRACK_ASSIGNMENT_ROUTE;
  hasPermissionForAlertAction = false;
  constructor(
    private router: Router,
    private notificationsService: NotificationsService,
    private sharedService: SharedService,
    private ccssDisplayService: CcssDisplayCommonService
  ) { }

  ngOnInit() {
    this.currentPage = this.router.url;
    this.notificationSubscription = this.notificationsService.getNotifications()
      .subscribe(res => {
        this.intActionMethodButtonCount = 0;
        if (res && res.length !== 0) {
          this.notifications = res.filter(result => {
            return result.type && result.type.toUpperCase() === 'ALERT';
          });
          this.notifications.sort((a, b) => {
            return (new Date(a.createdTimestamp)).getTime() - (new Date(b.createdTimestamp)).getTime();
          });
          this.checkBusStatus();
          this.notifications.map(element => {
            if (element.action !== null) {
              if (this.intActionMethodButtonCount === 0) {
                element.action === INITIATE_CHARGING
                  ? element.isConnected ? element.buttonEnable = true : element.buttonEnable = false
                  : element.buttonEnable = true;
              } else {
                element.buttonEnable = false;
              }
              this.intActionMethodButtonCount++;
            }
          });

          this.notificationCount = this.getcount(this.notifications.length);
        } else {
          this.notifications = [];
          this.notificationCount = '0';
        }

      });

    this.ccssDisplayService.getChargingStates()
      .subscribe(chargerInfo => {
        this.chargers = chargerInfo;
        this.checkBusStatus();
      });
  }

  checkBusStatus() {
    if (!_.isEmpty(this.chargers) && this.notifications && this.notifications.length) {
      _.each(this.notifications, notification => {
        if (notification.action === INITIATE_CHARGING) {
          _.each(this.chargers, charger => {
            if (
              notification.ocppId === charger.chargerSN &&
              charger.chargerVendor === ABB_CHARGER &&
              charger.chargerStatus === CHARGING &&
              charger.ocppTransactionPowerOffered === 0
            ) {
              notification.isConnected = true;
            }
          });
        }
      });
    }
  }

  getcount(length) {
    return length >= 1 && length <= 9 ? '0' + length : length;
  }

  ngOnDestroy() {
    this.notificationSubscription.unsubscribe();
  }

  onActionTrigger(notification) {
    this.notificationsService.triggerNotificationAction(notification);
  }

  isAlertActionsDisabled(item) {
    this.blnEnableAlertAction = item.buttonEnable;
    this.hasPermissionForAlertAction = this.sharedService.roleHasPermissionForAction(this.router.url,
      ALERT_ACTIONS_ACTION,
      ENABLE);
    if (!this.hasPermissionForAlertAction) {
      this.blnEnableAlertAction = this.hasPermissionForAlertAction;
    }
    return !this.blnEnableAlertAction;
  }

}
